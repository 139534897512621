



/*
  Template Name: Martex - Software, App, SaaS & Startup Landing Pages Pack
  Theme URL: https://themeforest.net/user/dsathemes
  Description: Martex - Software, App, SaaS & Startup Landing Pages Pack
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.0.0
  Website: www.dsathemes.com
  Tags: Responsive, HTML5, DSAThemes, Landing, Software, Mobile App, SaaS, Startup, Creative, Digital Produc
*/




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1400px) {


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1200px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 115.6%; } 

  /* Button */
  .btn { padding: 0.8rem 1.7rem; }
  .btn.ico-30.ico-left { padding: 0.286rem 0; }
  .btns-group .btn.ico-30.ico-left { padding: 0.286rem 0.5rem; }
  .btn.ico-20 [class*="flaticon-"]:before, .btn.ico-20 [class*="flaticon-"]:after { top: 3px; }

  /* Watch Video Link  */
  .watch-video-link { width: 2.8rem; height: 2.8rem; }
  .watch-video-link span[class^="flaticon-"]:before, 
  .watch-video-link span[class^="flaticon-"]:after { font-size: 1.75rem; line-height: 2.8rem!important; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 0.9375rem; }

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn { font-size: 0.9rem; line-height: 28px; padding: 4px 22px 6px; margin: 14px 5px 0; }
  .wsmenu > .wsmenu-list > li a.btn.reg-btn { padding: 4px 20px 6px; }

  /* SubMenu, HalfMenu, MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { font-size: 0.9rem; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu .title, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .title { font-size: 0.8rem; }

  /* Icons Menu */
  .icons-menu-txt span { font-size: 0.825rem; } 


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 992px) and (max-width: 1199.95px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .py-5 { padding-top: 0; padding-bottom: 0; }
  .py-10 { padding-top: 5px; padding-bottom: 5px; }
  .py-15 { padding-top: 10px; padding-bottom: 10px; }
  .py-20 { padding-top: 15px; padding-bottom: 15px; }
  .py-30 { padding-top: 25px; padding-bottom: 25px; }
  .py-40 { padding-top: 30px; padding-bottom: 30px; }
  .py-50 { padding-top: 40px; padding-bottom: 40px; }
  .py-60 { padding-top: 50px; padding-bottom: 50px; }
  .py-70 { padding-top: 60px; padding-bottom: 60px; }
  .py-80 { padding-top: 60px; padding-bottom: 60px; }
  .py-90 { padding-top: 70px; padding-bottom: 70px; }
  .py-100 { padding-top: 80px; padding-bottom: 80px; }

  .pt-30 { padding-top: 20px; }
  .pt-40 { padding-top: 30px; }
  .pt-50 { padding-top: 40px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 60px; }
  .pt-80 { padding-top: 60px; }
  .pt-90 { padding-top: 70px; }
  .pt-100 { padding-top: 80px; }

  .pb-30 { padding-bottom: 20px; }
  .pb-40 { padding-bottom: 30px; }
  .pb-50 { padding-bottom: 40px; }
  .pb-60 { padding-bottom: 50px; }
  .pb-70 { padding-bottom: 60px; }
  .pb-80 { padding-bottom: 60px; }
  .pb-90 { padding-bottom: 70px; }
  .pb-100 { padding-bottom: 80px; }

  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 45px; }
  .mt-55 { margin-top: 40px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 60px; }
  .mt-75 { margin-top: 65px; }
  .mt-80 { margin-top: 60px; }
  .mt-85 { margin-top: 65px; }
  .mt-90 { margin-top: 70px; }
  .mt-95 { margin-top: 75px; }
  .mt-100 { margin-top: 80px; }

  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 40px; }
  .mb-50 { margin-bottom: 45px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 60px; }
  .mb-75 { margin-bottom: 65px; }
  .mb-80 { margin-bottom: 60px; }
  .mb-85 { margin-bottom: 65px; }
  .mb-90 { margin-bottom: 70px; }
  .mb-95 { margin-bottom: 75px; }
  .mb-100 { margin-bottom: 80px; }

  /* Backgroud Shape */
  .shape--02:after { width: 140%; height: 82%; top: 12%; left: -20%; }
  .shape--03:after { height: 127%; top: -70px; }
  .shape--04:after { height: 84%; top: 8%; }
  .shape--05:after { height: 85%; }

  /* Font Size */
  .s-15 { font-size: 0.9375rem; }  /* 15px */
  .s-16 { font-size: 1rem; }       /* 16px */
  .s-17 { font-size: 1.0625rem; }  /* 17px */
  .s-18 { font-size: 1.125rem; }   /* 18px */
  .s-19 { font-size: 1.125rem; }   /* 18px */
  .s-20 { font-size: 1.18755rem; } /* 19px */
  .s-21, .s-22 { font-size: 1.25rem; }  /* 20px */
  .s-23 { font-size: 1.3125rem; }  /* 21px */
  .s-24 { font-size: 1.375rem; }   /* 22px */  
  .s-26 { font-size: 1.5rem; }     /* 24px */
  .s-28 { font-size: 1.625rem; }   /* 26px */
  .s-30 { font-size: 1.75rem; }    /* 28px */
  .s-32 { font-size: 1.875rem; }   /* 30px */
  .s-34 { font-size: 2rem; }       /* 32px */
  .s-36 { font-size: 2.125rem; }   /* 34px */
  .s-38 { font-size: 2.25rem; }    /* 36px */
  .s-40 { font-size: 2.3125rem; }  /* 37px */
  .s-42 { font-size: 2.4375rem; }  /* 39px */  
  .s-43 { font-size: 2.5rem; }     /* 40px */   
  .s-44 { font-size: 2.5rem; }     /* 40px */
  .s-45 { font-size: 2.5625rem; }  /* 41px */
  .s-46 { font-size: 2.625rem; }   /* 42px */
  .s-47 { font-size: 2.6875rem; }  /* 43px */
  .s-48 { font-size: 2.75rem; }    /* 44px */ 
  .s-50 { font-size: 2.875rem; }   /* 46px */ 
  .s-52 { font-size: 3rem; }       /* 48px */ 
  .s-54 { font-size: 3.125rem; }   /* 50px */ 
  .s-56 { font-size: 3.25rem; }    /* 52px */
  .s-58 { font-size: 3.375rem; }   /* 54px */
  .s-60 { font-size: 3.5rem; }     /* 56px */
  .s-62 { font-size: 3.625rem; }   /* 58px */
  .s-64 { font-size: 3.75rem; }    /* 60px */
  .s-66 { font-size: 3.875rem; }   /* 62px */
  .s-68 { font-size: 4rem; }       /* 64px */

  /* Button */
  .btn.btn-sm { font-size: 0.975rem; padding: 0.65rem 1.4rem; }
  .btn.ico-30.ico-left { padding: 0.3845rem 0; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.3845rem 0.5rem; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-right: 12px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.15rem; line-height: 0.5rem!important;top: 3.5px; }

  .btn.ico-30.ico-left span.ico-30-txt { margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }

  /* Button Text */
  p.btn-txt, p.btns-group-txt { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* OS Button */
  .os-btn { width: 280px; min-width: 270px; padding: 16px 16px 14px; }
  .os-btn-ico { margin-right: 16px; }

  /* Watch Video Link */
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 110px; height: 110px; top: -30px; left: calc(50% - 55px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -35px; left: calc(50% - 60px); }
  .ico-55 .shape-ico svg { width: 115px; height: 115px; top: -30px; left: calc(50% - 60px); }
  .ico-60 .shape-ico svg { width: 120px; height: 120px; top: -25px; left: calc(50% - 55px); }

  /* Vector Rounded Icons */
  .ico-rounded { width: 74px; height: 74px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 90px; height: 90px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after { font-size: 2.35rem; line-height: 74px!important; }

  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 80px!important; }

  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 90px!important; }

  /* Vector Square Icons */
  .ico-square { width: 74px; height: 74px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.35rem; line-height: 74px!important; }

  /* Section ID */
  .section-id { font-size: 0.85rem; margin-bottom: 30px; }
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 35px; }

  /* Section Title */
  .section-title p.p-md, .section-title p.p-lg, 
  .section-title p.p-xl, .section-title p.s-20, .section-title p.s-21 { margin-top: 15px; }
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.18755rem; }

  .section-title .btns-group.mt-25 { margin-top: 20px; }
  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 26px; }

  .section-title .advantages, .section-title .btns-txt, .section-title .btns-group-txt { margin-top: 16px; }

  /*------------------------------------------*/
  /*  PRELOAD ANIMATION    
  /*------------------------------------------*/

  #loading-center { height: 90px; width: 90px; margin-top: -45px; margin-left: -45px; }
  .loader { width: 90px; height: 90px; }
  .loader::after { border: 45px solid; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .desktoplogo img { max-height: 34px; }
  .blog-header .desktoplogo img { max-height: 28px; }

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { margin: 0 5px; padding: 10px 15px; }

  /* Nav Link Info */
  span.sm-info { width: 18px; height: 18px; font-size: 0.725rem; line-height: 17px; margin-left: 5px; }

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn { font-size: 0.9375rem; line-height: 28px; margin-top: 15px; padding: 3px 22px 5px; }
  .wsmenu > .wsmenu-list > li a.btn.reg-btn { padding: 3px 20px 5px; }
  .wsmenu > .wsmenu-list > li a.btn.fst-btn-link { margin-left: 0; }

  .wsmenu > .wsmenu-list > li a.store.header-store { margin-top: 15px; margin-left: 0; }
  .store.header-store img { max-height: 40px; }

  .header-socials a { margin-left: 16px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.175rem; } 

  /* SubMenu, HalfMenu, MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu {min-width: 180px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { width: 86%!important; left: 10%; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 40%; }

  .wsmegamenu .post-summary a, .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a  { font-size: 0.9375rem; }

  /* Icons Menu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu { max-width: 225px; min-width: 225px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu { width: 62%; }

  .icons-menu-ico { margin-right: 12px; }
  .wsmegamenu.ico-30 .icons-menu-ico span { top: 8px!important; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section h2 { margin-bottom: 24px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 35px; }

  .hero-section p.p-md { margin-bottom: 25px; }
  .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 28px; }
  .hero-section p.s-20, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 32px; }
  .hero-section p.s-21 { font-size: 1.25rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 10px; }

  /* HERO LOGO */
  .hero-logo, .hero-app-logo { margin-bottom: 35px; }
  .hero-logo img { max-height: 30px; }
  .hero-square-logo img { max-height: 80px; }
  .hero-app-logo img { max-height: 90px; }
  #hero-25 .hero-app-logo img { max-height: 100px; }

  /* HERO DIGITS */
  .hero-digits .block-txt p.p-sm { margin-top: 2px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 60px; font-size: 1.15rem; }
  .hero-section .quick-form.form-half .form-control { height: 54px; font-size: 1rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 54px; font-size: 0.985rem; padding: 13px 40px; margin-left: 10px; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -5px 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 54px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 54px; padding: 16px; }

  /* HERO-1 */
  #hero-1 { padding-top: 120px; padding-bottom: 80px; }
  .hero-1-img { margin: 0 -30px 0 -20px; }

  /* HERO-2 */
  #hero-2 { padding-top: 150px; padding-bottom: 80px; }
  .hero-2-txt p { padding-right: 0; }
  .hero-2-img { padding-right: 15px; }

  /* HERO-3 */
  #hero-3 { padding-top: 140px; }
  #hero-3:after { height: 6%; }
  .hero-3-txt { margin-top: -60px; }
  .hero-3-img { margin: 0 -75% 0 0; }

  /* HERO-4 */
  #hero-4 { padding-top: 150px; }
  .hero-4-txt h2, .hero-4-txt p { padding: 0; }
  .hero-4-img { margin-top: 50px; }

  /* HERO-5 */
  #hero-5 { padding-top: 130px; padding-bottom: 50px; }
  .hero-5-txt h2 { margin-bottom: 20px; }
  .hero-5-txt p.p-lg { padding-right: 10%; margin-bottom: 25px; }

  /* HERO-6 */
  .hero-6-wrapper { padding: 65px 70px; }
  .hero-6-txt h2 { margin-bottom: 18px; }
  .hero-6-txt p.p-lg { margin-bottom: 22px; }

  /* HERO-7 */
  #hero-7 { padding-top: 140px; padding-bottom: 90px; }
  #hero-7 .quick-form { margin-right: 3%; }
  .hero-7-txt .quick-form.form-half.mt-35 { margin-top: 25px; }
  .hero-7-img { padding-left: 15px; }

  /* HERO-8 */
  #hero-8 { padding-top: 150px; padding-bottom: 20px; }
  .hero-8-txt { margin-top: -50px; }
  #hero-8-form { padding: 38px 25px; }
  #hero-8-form h4 { margin-bottom: 15px; }

  /* HERO-9 */
  #hero-9 { padding-top: 150px; }
  .hero-9-txt h2 img { max-height: 80px; margin: 0 3px; top: -5px; }
  .hero-9-txt p { padding: 0 5%; margin-top: 32px; }

  #hero-9 .quick-form { margin-left: 8%; margin-right: 8%; }
  .hero-9-img { margin: 50px 15px 0; }

  /* HERO-10 */
  #hero-10 { padding-top: 160px; padding-bottom: 80px; }
  .hero-10-txt { padding-right: 10%; }
  .hero-10-txt h4 { padding-right: 0; margin-bottom: 30px; }
  .hero-10-img { margin: 0 -95% 0 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 150px; }
  .hero-12-txt h2, .hero-12-txt p { padding: 0; }
  .hero-12-img { margin-bottom: -80px; }

  /* HERO-13 */
  #hero-13 { padding-top: 120px; padding-bottom: 70px; } 

  /* HERO-14 */
  #hero-14 { padding-top: 150px; }
  .hero-14-txt p.s-21 { margin-bottom: 35px; }
  #hero-14 .quick-form { margin-left: 7%; margin-right: 7%; }
  .hero-14-img { margin: 50px 10px 0; }
  .hero-14-txt .btn-txt { margin-top: 30px; }

  /* HERO-15 */
  #hero-15 { padding-top: 120px; }
  .hero-15-txt h2 { padding-right: 10%; }

  /* HERO-16 */
  #hero-16 { padding-top: 150px; margin-bottom: 60px; }
  .hero-16-txt h2 { padding: 0 4%; }
  .hero-16-img { margin-top: 50px; margin-bottom: -60px; }

  /* HERO-17 */
  #hero-17 { padding-top: 160px; }
  .hero-17-txt p { padding: 0 3%; }
  #hero-17 .quick-form { margin-left: 10%; margin-right: 10%; }
  #hero-17 #brands-1 { padding: 50px 6% 0; } 
  #hero-17 #brands-1 .brands-carousel-5 .brand-logo { padding: 0 14px; } 

  /* HERO-18 */
  #hero-18 { padding-top: 140px; padding-bottom: 70px; }
  #hero-18:after { width: 76%; left: 24%; }
  .hero-18-txt p, .hero-18-img { padding-right: 0; }

  /* HERO-19 */
  #hero-19 { padding-top: 150px; }

  /* HERO-20 */
  #hero-20 { padding-top: 90px; padding-bottom: 90px; }
  #hero-20:after { width: 50%; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 25px; }

  /* HERO-21 */
  #hero-21 { padding-top: 150px; }
  .hero-21-txt { margin-bottom: 50px; }
  .hero-21-txt h2 { margin-bottom: 25px; }
  #hero-21 .hero-overlay { padding: 80px 60px 0; }
  .hero-21-img { margin-bottom: -25%; }

  /* HERO-22 */
  #hero-22 { padding-top: 150px; }
  #hero-22 .hero-logo { margin-bottom: 20px; }
  #hero-22-form h4 { margin-bottom: 15px; }
  #hero-22-form { padding: 38px 25px 28px; }

  /* HERO-23 */
  #hero-23 { padding-top: 80px; margin-bottom: 25px; }
  .hero-23-txt p { padding: 0; }
  .hero-23-img { margin-top: 25px; margin-bottom: -25px; }

  /* HERO-24 */
  #hero-24 { padding-top: 160px; padding-bottom: 110px; }

  /* HERO-25 */
 #hero-25 { padding-top: 120px; padding-bottom: 80px; }
  .hero-25-txt h2 { margin-bottom: 18px; }
  .hero-25-txt p.p-lg { margin-bottom: 24px; }

  /* HERO-26 */
  #hero-26 { padding-top: 160px; }
  .hero-26-txt h2 { padding-right: 3%; } 
  #hero-26 .quick-form { margin-right: 3%; }
  .hero-26-img { margin: 0 -90% 0 -30px; }

  /* HERO-27 */
  #hero-27 .hero-overlay { padding-top: 160px; }
  .hero-27-txt h2 { margin-bottom: 28px!important; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 8px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { padding-right: 5%; margin-bottom: 40px }

  .a2-txt { padding: 15px 20px 30px; }
  .a-2-1 { margin-left: 3%; }

  .a2-txt-quote { position: relative; margin-bottom: -40px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.0625rem; }
  .a2-txt-author span { font-size: 0.95rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 25px; }
  #about-3 .txt-block h5 { margin-bottom: 15px; }
  #about-4 .txt-block p { margin-top: 20px; }

  #a4-1 { padding-right: 20px; margin-top: -30px; }
  #a4-2 { padding-left: 20px; margin-bottom: -30px; }

  /* FEATURES */
  .fbox-14-wrapper { padding: 70px 50px; }
  .fbox-14-wrapper .col { padding: 0 8px; }

  #features-12.py-100.shape--bg:after { width: 50%; height: 68%; top: 16%; left: 50%; }
  #features-12.pt-100.shape--bg:after { width: 50%; height: 76%; top: 18%; left: 50%; }
  #features-13.py-100.shape--bg:after { width: 50%; height: 68%; top: 16%; }
  #features-13.pt-100.shape--bg:after { width: 50%; height: 76%; top: 18%; }

  .fbox-12-wrapper { padding-left: 30px; }
  .fbox-13-wrapper {  padding-right: 30px; }

  .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3 { margin-bottom: 40px; }
  .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3,
  .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, .rows-2 .fbox-9.fb-3 { margin-bottom: 30px; }

  .row-cols-md-3 .fbox-4 { padding: 40px 30px; }
  .row-cols-md-2 .fbox-4 { padding: 35px 20px; } 

  .fbox-7 { padding: 40px 25px 37px; } 
  .row-cols-md-2 .fbox-7 { padding: 28px 15px 25px; }

  .fbox-8, .fbox-8.bg--white-100 { padding: 40px 25px; } 
  .fbox-9 { padding: 30px 30px 27px; } 

  .fbox-12, .fbox-13 { padding: 30px; } 

  .fbox-14 { padding: 35px 25px 30px; } 

  .fbox-img.h-180 img { max-height: 165px; }
  .fbox-img.h-175 img { max-height: 160px; }
  .fbox-img.h-170 img { max-height: 155px; }
  .fbox-img.h-165 img { max-height: 150px; }
  .fbox-img.h-160 img { max-height: 145px; }
  .fbox-img.h-155 img { max-height: 140px; }
  .fbox-img.h-150 img { max-height: 135px; }
  .fbox-img.h-145 img { max-height: 130px; }
  .fbox-img.h-140 img { max-height: 125px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, 
  .fbox-3 .fbox-img.h-135 img, .fbox-4 .fbox-img.h-135 img { max-height: 115px; }

  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 155px; }
  .fbox-4 .fbox-img.h-170 img, .fbox-4 .fbox-img.h-175 img { max-height: 150px; }

  .fbox-1 .fbox-img, .fbox-4 .fbox-img { margin-bottom: 25px; }
  .row-cols-md-2 .fbox-1 .fbox-img, .row-cols-md-2 .fbox-3 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 25px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, .fbox-2 .fbox-img.h-150, 
  .fbox-2 .fbox-img.h-155 { padding-top: 25px; margin-bottom: 50px; }

  .fbox-2 .fbox-img.h-160 { padding-top: 30px; margin: 0 10px 50px; }
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170 { padding-top: 34px; margin: 0 5px 50px; } 
  .fbox-2 .fbox-img.h-175, .fbox-2 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 50px; }  

  .fbox-3 .fbox-img.h-135 { padding-top: 25px; margin: 0 0 25px; }
  .fbox-3 .fbox-img.h-160 { padding-top: 30px; margin: 0 5px 25px; }
  .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, .fbox-3 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 25px; }
  
  .fbox-4 .fbox-img.h-135 { margin-bottom: 25px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 35px 6% 0; }
  .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, .fbox-5.fb-4 .fbox-5-img { margin: 0 6% 30px; }

  .fbox-ico { margin-bottom: 18px; }
  .row-cols-md-2 .fbox-ico { margin-bottom: 15px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }
  .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 22px; }

  .fbox-wrapper .row-cols-lg-3 .fbox-title { margin-bottom: 5px; }

  .rows-2 .fbox-ico-wrap .ico-50 [class*="flaticon-"]:before, 
  .rows-2 .fbox-ico-wrap .ico-50 [class*="flaticon-"]:after { font-size: 2.85rem; }

  .rows-2 .fbox-ico-wrap { margin-right: 15px; }

  .fbox-txt h5, .features-section h6.s-22, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 12px; }
  .fbox-14 h5 { padding-right: 8px; margin-bottom: 12px; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 80px; }
  .cbox-6-wrapper { padding: 45px; }
  .ct-11 .txt-block { padding-right: 40px; }
  .ct-12 .txt-block { padding-left: 40px; }
  .ct-13 .row.mb-60 { margin-bottom: 50px }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay, .bc-6-wrapper .section-overlay { padding: 65px 50px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay, .bc-5-wrapper .section-overlay { padding: 65px 50px 0; }
  .bc-6 { padding: 30px 25px; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 20px; }
  .txt-block h5, .bc-6 h4 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6 h4 { margin-bottom: 12px; }

  .ct-08 .section-title h2, .ct-09 .section-title h2, .ct-10 .section-title h2 { padding: 0 5px; }

  .txt-block .btn { margin-top: 22px; } 
  .bc-6-link.mt-15 { margin-top: 10px; }
  .txt-block-tra-link.mt-25 { margin-top: 18px; }

  /* Text Block */
  .bc-2-txt, .ct-07 .txt-block { margin-top: 20px; }
  .bc-1-wrapper .txt-block.pr-25.left-column, .bc-2-wrapper .txt-block.pr-25.left-column { padding-right: 15px; }
  .bc-1-wrapper .txt-block.pl-25.right-column, .bc-2-wrapper .txt-block.pl-25.right-column { padding-left: 15px; }

  /* Content Box */
  .cbox-5-content { padding: 35px; margin: 0 10%; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 3.15rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.85rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; }

  /* Content Box Icon */
  .cbox-2 .ico-wrap { margin-right: 1.1rem; }
  .cbox-2-ico { width: 2.35rem; height: 2.35rem; font-size: 1.135rem; line-height: 2rem; }
  .ct-03 .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.0625rem; line-height: 1.95rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 .cbox-3-ico { top: 4px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  /* Content Box Text */
  .cbox-2-txt, .cbox-3-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 25px; }
  .cbox-4-txt { margin-bottom: 22px; }

  /* Content Box Typography */
  .ct-03 .cbox-2-txt h5 { margin-bottom: 12px; }
  .cbox-6 h6 { margin-bottom: 12px; }
  .cbox-1-txt p { margin-bottom: 5px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 15px; }

  /* Tabs */
  .tabs-1 { margin: 0 auto 55px; }

  /* Advantages */
  .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 10px; }
  .payment-icons img { max-height: 32px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.1875rem;line-height: 2.1875rem!important; margin-right: 4px; 
  }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Box Shapes */
  .cbox-5-shape-1 { right: -20px; top: -20px; }
  .cbox-5-shape-2 { left: 10px; bottom: -25px; }
  .cbox-5-shape-1 img, .cbox-5-shape-2 img { height: 70%; width: 70%; }

  /* Content Image */
  .ct-01 .img-block { margin-left: 5px; }
  .ct-02 .img-block { margin-right: 5px; }
  .ct-03 .img-block.left-column { margin-right: 15px; }
  .ct-03 .img-block.right-column { margin-left: 15px; }
  .ct-04 .img-block { margin-left: 15px; }
  .ct-04 .img-block img { max-width: 175%; }
  .ct-10 .img-block { margin-bottom: -80px; }

  .img-block-txt, .img-block-btn { margin-top: 55px; }

  .bc-1-wrapper .img-block.left-column, .bc-2-wrapper .img-block.left-column { padding-right: 10px; }
  .bc-1-wrapper .img-block.right-column, .bc-2-wrapper .img-block.right-column { padding-left: 10px; }
  .bc-3-img { margin: 0 -65% -25px 25px; }
  .bc-4-img { margin-left: 25px; margin-right: -65%; }
  .bc-6-img { padding: 40px 30px 0; margin-bottom: 25px; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 50px 35px; }
  .ct-11 .img-block { padding-left: 50px; }
  .ct-12 .img-block { padding-right: 50px; }

  /* STATISTIC */
  .sb-rounded .statistic-block { padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 70px 18px 0; }
  .statistic-1-wrapper .statistic-block-digit { width: 32%; }
  .statistic-1-wrapper .statistic-block-txt { width: 68%; padding-left: 10px; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; } 

  h2.statistic-number, #statistic-5 .statistic-digit h2 { letter-spacing: -0.5px; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; margin-top: 2px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 10px; }

  #statistic-5 .statistic-txt h5 { margin-bottom: 10px; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 1px; }
  .statistic-4-wrapper p { margin-bottom: 12px; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4 { margin-bottom: 40px; }
  .project-details h5 {margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 60px; }
  .project-title h2 { padding-bottom: 35px; margin-bottom: 30px; }

  .project-txt h5.mt-35.mb-35 { margin-top: 30px; margin-bottom: 30px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 50px; margin-bottom: 30px; }

  .project-description .more-projects { margin-top: 50px; }
  .project-description .more-projects span { top: 1px; left: 5px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.275rem; } 

  /* RATING */
  .rating-section .rating-1-wrapper { padding: 0 12%; }
  .rating-section .rating-2-wrapper { padding: 0 3%; }
  .inner-page-hero .rating-1-wrapper.mt-50 { margin-top: 40px; }

  .rbox-1-img img { max-height: 34px; } 

  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  /* TESTIMONIALS */
  .reviews-section .col-md-8 .section-title h2 { padding: 0 5%; }

  .reviews-4-wrapper, .reviews-5-wrapper { padding-left: 35px; }
  #reviews-4 .txt-block.left-column, #reviews-5 .txt-block.left-column { padding-left: 10px; }

  .review-1 { padding: 35px 25px 25px; }
  .review-2 { padding: 40px 35px 30px; }
  .review-3 { padding: 40px 35px 35px; }
  .review-4 { padding: 35px 35px 25px; }
  .review-5 { padding: 40px 35px 35px; }

  #rw-3-2 { margin-bottom: 25px; }
  #rw-3-2, #rw-3-3 { margin-left: 0; }
  #rw-4-1, #rw-5-1 { margin-bottom: 25px; }

  .review-txt { margin-top: -42px; }
  .review-txt h6 { margin-bottom: 10px; }

  .review-1 .author-data { margin-top: 15px; }
  .review-2 .author-data, .review-3 .author-data, .review-5 .author-data { margin-top: 8px; }
  .review-4 .author-data { margin-top: 0; }

  .review-avatar img, .owl-item .review-avatar img { width: 44px; height: 44px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 5px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6 { font-size: 1rem; }

  /* PRESS */
  .press-logo img { max-height: 74px; }
  .press-txt { margin-top: 10px; }

  /* BRANDS */
  .brands-title.mb-40 { margin-bottom: 30px; }
  .brands-title.mb-50 { margin-bottom: 40px; }

  .brands-3-wrapper { padding: 40px 30px; }
  .brands-3-wrapper.brands-3-static { margin-left: 3%; margin-right: 3%; }

  #brands-1 .brands-carousel-5 .brand-logo, #brands-1 .brands-carousel-6 .brand-logo { padding: 0 12px; } 
  #brands-1 .justify-content-center .brand-logo, #brands-3 .brand-logo { padding: 0 12px; }  
  #brands-3 .brands-3-static .brand-logo { padding: 0 8px; }

  .brands-section .brand-logo.mb-50 { margin-bottom: 40px; }

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  .integrations-2-wrapper { padding: 80px 60px; }
  .in_tool_logo { padding: 40px 30px; margin-bottom: 25px; }
  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 50px; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 48px; height: 48px; }
  .integrations-1-wrapper .in_tool { padding: 16px 20px; }
  .integrations-1-wrapper .in_tool.mb-30 { margin-bottom: 20px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 40px; }

  /* PRICING */
  .pricing-1-wrapper .col, .pricing-2-wrapper .col, .pricing-3-wrapper .col { padding: 0 8px; }
  .pricing-1-table { padding: 40px 25px 35px; }
  .text-center .pricing-1-table { padding: 50px 25px 40px; }
  .pricing-2-table {  padding: 40px 25px; }
  .pricing-3-table { padding: 30px 32px 35px; }
  .pricing-4-table { padding: 35px 45px; }
  #pt-4-1 { margin: 0 15px 40px 20px; }
  #pt-4-2 { margin: 0 20px 40px 15px; }

  .pricing-table-header h5 { margin-bottom: 25px; }
  .pricing-table-header h4 { margin-bottom: 20px; }
  .pricing-3-table .pricing-table-header h4 { margin-bottom: 10px; }

  .pricing-1-table .pricing-discount { top: -2px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -40px; right: -5px; }

  /* Price */
  .price span { font-size: 2.85rem; letter-spacing: -1.5px; } 
  .pricing-2-table.highlight .price span { font-size: 2.15rem; } 
  .pricing-3-table .price span { font-size: 4.5rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 3.15rem; } 

  .price sup { font-size: 2rem; top: -4px; right: 2px; }
  .pricing-3-table .price sup { font-size: 2.4rem; top: -22px; }
  .pricing-4-table .price sup { font-size: 2.25rem; top: -8px; }

  .pricing-3-table .price sup.coins { font-size: 2.4rem; top: -27px; letter-spacing: -2px; }

  .price sup.validity { font-size: 1.45rem; }
  .pricing-3-table .price sup.validity { font-size: 1.2rem; top: 0; margin-top: 20px; margin-bottom: 35px; }
  .pricing-4-table .price sup.validity { font-size: 1.65rem; left: 4px; top: -2px; }

  .price p { margin-top: 20px; margin-bottom: 15px; }
  .text-center .price p { padding: 0; }

  .pricing-features.mt-25 { margin-top: 20px; }

  .pricing-1-table hr { margin-top: 30px; }
  .pricing-2-table hr { margin-top: 20px; }

  .pricing-1-table .pt-btn, .pricing-2-table .pt-btn { margin-top: 20px; }

  /* Pricing Notice */
  .col-lg-10 .pricing-notice p { padding: 0 4%; }
  .pricing-notice .btn.mt-25 { margin-top: 20px; }

  /* Pricing Toggle Button */
  #pricing-4 .ext-toggle-btn { margin: 0 20px 25px 15px; }

  .toggle-btn-md .toggler-txt { font-size: 1.0625rem; line-height: 32px; }
  .toggle-btn-lg .toggler-txt { font-size: 1.1rem; line-height: 36px; }

  .switch-wrap { min-width: 50px; height: 30px; }
  .toggle-btn-md .switch-wrap { min-width: 55px; height: 32px; margin: 0 6px; }
  .toggle-btn-lg .switch-wrap { min-width: 60px; height: 36px; margin: 0 12px; }

  .switcher { font-size: 0.9rem; height: 30px; }
  .toggle-btn-md .switcher { height: 32px; padding: 0 10px 0 30px; border-radius: 32px; }
  .toggle-btn-lg .switcher { height: 36px; padding: 0 15px 0 35px; border-radius: 36px; }

  .switcher .show-annual, .switcher .show-monthly { line-height: 30px; }
  .toggle-btn-md .switcher .show-annual, .toggle-btn-md .switcher .show-monthly { line-height: 32px; }
  .toggle-btn-lg .switcher .show-annual, .toggle-btn-lg .switcher .show-monthly { line-height: 36px; }

  .switcher:before { left: 4px; bottom: 4px; width: 22px; height: 22px; }
  .toggle-btn-md .switcher:before { left: 5px; bottom: 5px; width: 22px; height: 22px; }
  .toggle-btn-lg .switcher:before { left: 5px; bottom: 5px; width: 26px; height: 26px; }
  .switcher.bg--grey:before, .switcher.bg--tra-white:before, .switcher.bg--ghost-white:before { left: 4px; bottom: 3px; }

  .toggle-btn-md .switch-wrap input:checked + .switcher { padding: 0 30px 0 10px; }
  .toggle-btn-lg .switch-wrap input:checked + .switcher { padding: 0 35px 0 15px; }

  /* Pricing Compare */
  .comp-table .table-responsive.mb-50 { margin-bottom: 30px; }
  .comp-table .table-responsive thead th { font-size: 1.1rem; }
  .comp-table .table-responsive tbody th { font-size: 1rem; }
  .comp-table .table-responsive tbody td { font-size: 0.975rem; top: 1px; }
  .comp-table .table-responsive tbody td span { top: 2px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.785rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; }

  .comp-table-payment h6 { margin-bottom: 14px; }
  .comp-table-payment p { font-size: 0.965rem; }

  /* TEAM */
  #tm-2-1, #tm-2-4, #tm-2-7, #tm-2-10 { margin-right: 10px; }
  #tm-2-2, #tm-2-5, #tm-2-8, #tm-2-11 { margin-left: 5px; margin-right: 5px; }
  #tm-2-3, #tm-2-6, #tm-2-9, #tm-2-12 { margin-left: 10px; }

  .team-member-photo { margin-bottom: 20px; }
  #team-2 .team-member-data { margin-left: 5px; }

  .team-member-data h6.s-20 { margin-bottom: 6px; }
  ul.tm-social { top: 0; right: 5px; }

  .team-section .more-btn.mt-20 { margin-top: 10px; }

  /* CAREERS */
  #careers-1 { padding-bottom: 56px; }
  .role-box { padding: 22px 30px; margin-bottom: 24px; }
  .role-box-txt span, #careers-1 .role-box span { font-size: 0.825rem; margin-bottom: 14px; }
  .role-box-txt p { margin-bottom: 10px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 35px; }
  #role-page .inner-page-title span { margin-bottom: 24px; }

  .role-info hr { margin: 40px 0; }
  #role-page .cbox-6-wrapper { margin: 35px 0; }
  .role-info h5 { margin-bottom: 18px; }
  .txt-block.role-info .btn { margin-top: 40px; }

  /* BANNER */
  #banner-12 { margin-top: 30px; margin-bottom: 5px; }
  #banner-9 { padding-top: 70px; padding-bottom: 70px; }

  .banner-1-wrapper .banner-overlay { padding: 60px 15% 70px; } 
  .banner-2-wrapper .banner-overlay { padding: 60px 18% 70px; }
  .banner-3-wrapper .banner-overlay { padding: 60px 22% 70px; }
  .banner-11-wrapper .banner-overlay { padding: 10px 65px 0; } 
  .banner-12-wrapper .banner-overlay, .banner-13-wrapper .banner-overlay { padding-left: 65px; } 
  .banner-16-wrapper .banner-overlay { padding: 40px 20%; }

  .banner-9-txt { padding-left: 15px; padding-right: 30%; }
  .banner-10-txt { padding-left: 25px; }
  .banner-12-txt { padding-right: 10%; }

  .banner-section .section-id { margin-bottom: 25px; }
  #banner-15.banner-section .section-id { margin-bottom: 30px; }

  .banner-1-txt h2 { margin-bottom: 16px; }
  .banner-2-txt h2 { padding: 0 2%; margin-bottom: 30px; }
  .banner-4-txt h2 { margin-bottom: 25px; }
  .col-md-8 .banner-5-txt h2 {padding: 0 5%; }
  .banner-7-txt h2 { margin-bottom: 30px; }
  .banner-10-txt h2 { margin-bottom: 22px; }
  .banner-11-txt h2, .banner-12-txt h2 { margin-bottom: 18px; }
  .banner-13-txt h2 { padding-right: 4%; margin-bottom: 18px; }
  .banner-15-txt h2 { margin-bottom: 40px; }
  .banner-16-txt h4 { margin-bottom: 10px; }

  .banner-section p.p-lg, .banner-section p.p-xl { margin-bottom: 25px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl { margin-top: 10px; margin-bottom: 20px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl, .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 15px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 15px;margin-bottom: 21px; }
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 22px!important; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 15px; margin-bottom: 22px!important; }
  .banner-14-txt p.p-lg { margin-top: 28px; margin-bottom: 28px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 30px; }
  .banner-8-buttons.mt-20 { margin-top: 8px; }
  .banner-15-txt .download-links { margin-top: 20px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 10px; }
 
  .banner-9-img { padding-right: 15px; }
  .banner-10-img { padding-left: 25px; }
  .banner-12-img { margin: -30px -20px -20px -40px; }
  .banner-13-img { margin: -60px -120px -70px -25px; }

  /* DOWNLOAD */
  .version-release { margin-bottom: 60px; }
  .release-data { padding-bottom: 25px; margin-bottom: 25px; }
  .release-data h5 { margin-bottom: 30px; }

  span.version-data { font-size: 1.9rem; }
  span.release-date { font-size: 1.1rem; }

  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 55px; }

  .faqs-3-questions .questions-holder { padding: 0; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 30px; } 
  #faqs-2 .inner-page-title { margin-bottom: 38px; }
  #faqs-3 .section-title { margin-bottom: 55px; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 20px 0 22px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 22px 0 28px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 19px; right: 2px; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 23px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 35px 0 20px 0; } 
  #faqs-2 .accordion-panel-item.mb-35 { margin-bottom: 30px; }
  #faqs-3 .question.mb-35 { margin-bottom: 25px; }

  .question h5, .accordion-panel-item h5 { margin-bottom: 15px; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 {  margin: 0 auto 50px; }
  #faqs-4 .tabs-content { padding: 0 15px; }

  .faqs-section .tabs-1 { margin: 0 auto 50px; }
  .faqs-section .tabs-1 li { min-width: 220px; padding: 25px 0; margin: 0 5px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 50px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 20px; }
  .more-questions-txt { padding: 18px 44px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay { padding-top: 45px; padding-bottom: 45px; }  
  #newsletter-2 .newsletter-overlay { padding: 45px; }
  #newsletter-3 { margin-top: 30px; margin-bottom: 30px; }
  #newsletter-4 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 65px; padding-bottom: 55px; }

  #newsletter-1 .newsletter-txt, #newsletter-2 .newsletter-txt { padding-right: 8%; }
  #newsletter-3 .newsletter-txt { padding-right: 12%; }
  #newsletter-3 .col-lg-6 .newsletter-txt { padding-right: 3%; }

  .newsletter-txt h2 { margin-bottom: 25px; }
  #newsletter-4 .newsletter-txt h2 { margin-bottom: 30px; }

  #newsletter-1 .newsletter-form { margin-top: 25px; padding-left: 8%; }
  #newsletter-2 .newsletter-form { margin-top: 25px; padding-left: 8%; }
  #newsletter-5 .newsletter-form { margin-top: 30px; padding: 0 8%; }

  .newsletter-section .form-control { height: 50px; }
  #newsletter-1.newsletter-section .form-control { height: 50px; font-size: 0.975rem; }
  #newsletter-5.newsletter-section .form-control { height: 54px; font-size: 1.0625rem; }

  .newsletter-section .btn { height: 50px; }
  #newsletter-1 .btn { height: 50px; font-size: 0.925rem; padding: 0.7rem 1.3rem; }
  #newsletter-5.newsletter-section .btn { height: 54px; }

  #newsletter-3 .newsletter-img { margin-top: -30px; margin-bottom: -30px; }
  #newsletter-3 .col-lg-6 .newsletter-img { padding-left: 50px; }

  /* BLOG */
  #blog-1.pb-60 { padding-bottom: 40px; }
  #blog-1 .col-lg-4, #blog-page .col-lg-4 { padding-left: 10px; padding-right: 10px; }

  .blog-post-img.mb-35 { margin-bottom: 25px; }
  .post-tag { font-size: 0.85rem; margin-bottom: 15px; }

  .blog-post-txt h6 { margin-bottom: 12px; }
  .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 16px; }

  .blog-post-meta.mt-20 { margin-top: 14px; }
  .blog-post-meta.mt-30 { margin-top: 16px; }
  .meta-list-divider { padding: 0 1px; }

  /* Blog Listing */
  .wide-post { margin-bottom: 80px; }
  .wide-post .blog-post-img { padding-right: 10px; }
  .wide-post .blog-post-txt { padding-left: 10px; }

  .square-post { margin-top: 20px; margin-bottom: 40px; }
  .square-post .blog-post-txt { padding: 0 30px 0 50px; }

  .posts-category { margin-top: 20px; margin-bottom: 40px; }
  ..posts-category span { top: -1.5px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 10%; }
  .single-post-txt h5, .single-post-txt h4 { margin-top: 16px; margin-bottom: 20px; }

  .single-post-txt .blockquote p { font-size: 1.225rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1.05rem; }

  .post-share-list { margin-top: 40px; padding-bottom: 60px; }

  .post-author-avatar { margin-right: 25px; }
  .post-author-avatar img { width: 80px; height: 80px; }
  .post-author-txt h5 { margin-bottom: 15px; }

  .author-follow-btn { padding: 5px 12px; top: 60px; right: 10px; }
  .author-follow-btn a { font-size: 0.875rem; }

  .post-comments { padding-top: 60px; }
  .post-comments img { width: 60px; height: 60px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 1rem; } 

  .post-comments #leave-comment h5 {; margin-top: 60px; }
  .comment-form .form-control { height: 52px; font-size: 1.05rem; }
  .comment-form textarea { min-height: 190px; }

  /* CONTACTS */
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }
  
  #contacts-1 .contact-form .col-md-12 span { margin-bottom: 15px; }
  .contact-form .form-control, .contact-form .form-select { height: 54px; }
  .contact-form textarea { min-height: 190px; }

  /* FOOTER */
  .footer { padding-bottom: 35px; }
  .footer hr { margin-top: 10px; margin-bottom: 35px; }

  #footer-10.pt-50 { padding-top: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 34px; }
  #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 25px; }
  #footer-10 img.footer-logo { max-height: 39px; }

  .footer h6 { margin-bottom: 20px; }

  #footer-1 .footer-info { padding-right: 5%; }
  #footer-3 .footer-info { margin-bottom: 50px; }
  #footer-10 .footer-info { margin-bottom: 30px; padding-bottom: 40px; }

  #footer-2 .footer-links.fl-1 { padding-left: 34%; }
  #footer-2 .footer-links.fl-2 { padding-left: 22%; }
  #footer-2 .footer-links.fl-3 { padding-left: 0%; }
  #footer-2.ft-2-ntr .footer-links.fl-1 { padding-left: 25%; }
  #footer-2.ft-2-ntr .footer-links.fl-2 { padding-left: 0; }
  #footer-3.ft-3-ntr .footer-links.fl-3 { padding-left: 28%; }

  #footer-8 .footer-info, #footer-8 .footer-links { margin-bottom: 20px; }
  #footer-10 .footer-links { margin-bottom: 10px; }

  #footer-1 .footer-info .footer-mail-link { margin-top: 35px; }
  #footer-2 .footer-links .footer-mail-link, #footer-3 .footer-links .footer-mail-link { margin-bottom: 14px; }

  .footer-mail-link { font-size: 1.0625rem; }

  .foo-links li a span.ext-link { left: 3px; top: 1px; }
  .foo-links.ico-15 [class*="flaticon-"]:before, .foo-links.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .footer-socials.ico-20 a, .footer-socials.ico-25 a { margin-right: 14px; } 
  .footer-socials.ico-25 [class*="flaticon-"]:before, .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.1875rem; }

  #footer-2 .footer-form, #footer-3 .footer-form { padding-left: 5%; }
  .footer-form .form-control, .footer-form .btn { height: 28px; }

  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 24px!important; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero { padding-top: 160px; }
  .page-hero-section-overlay { padding-top: 120px; }
  .inner-page-title { margin-bottom: 60px; }
  .inner-page-title p { font-size: 1.15rem; margin-top: 18px; }
  .page-hero-section h2 { line-height: 1.25;  padding-right: 3%; margin-bottom: 20px; }

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin-top: 25px; margin-bottom: 25px; }
  .txt-block.legal-info h4 { margin-top: 60px; margin-bottom: 25px; }
  .txt-block.legal-info h4 span, .txt-block.legal-info h5 span { margin-right: 2px; }

  /* SIGN IN / SIGN UP */
  #reset-password { padding-bottom: 80px; }

  .register-page-txt img { max-height: 30px; margin-bottom: 45px; }
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 40px; }

  .register-form-title { margin-bottom: 30px; }

  #login .register-page-txt, #signup .register-page-txt { padding: 60px 45px; }

  #signup .register-page-txt h2 { line-height: 1; }

  #login .register-page-copyright { left: 45px; bottom: 40px; }
  #signup .register-page-copyright { right: 45px; bottom: 40px; }
  .register-page-copyright p { font-size: 0.9rem; }

  #login .register-page-form { padding: 50px 45px 50px 35px; }
  #signup .register-page-form { padding: 50px 35px 50px 45px; }

  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 10px 30px; }

  .separator-line { margin: 20px 0; }
  .register-page-form p.input-header { font-size: 0.9rem; margin-bottom: 10px; padding-left: 5px; }
  .register-page-form p.create-account { margin-top: 20px; }

  .reset-form-title { margin-bottom: 25px; }
  .reset-form-title p.p-sm { font-size: 0.95rem; margin-top: 20px; }

  .reset-page-wrapper { margin: 0; }
  .reset-page-wrapper form {  padding: 40px 25px 15px; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { height: 54px; margin-bottom: 20px; }

  #login .register-page-form .btn.submit { height: 54px; margin-top: 12px; }
  #signup .register-page-form .btn.submit { height: 54px; margin-top: 20px; }

  .btn-show-pass { top: 18px; }

  /* PAGE 404 */
  .page-404-txt { padding-top: 160px; padding-bottom: 80px; }
  .page-404-txt h2 { margin-bottom: 20px; }
  .page-404-txt h6 { padding: 0 8%; margin-bottom: 25px; }
  .page-404-img { padding: 0 8%; margin-bottom: 40px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 580px; }
  #modal-2 .modal-dialog { width: 350px; }
  #modal-3 .modal-dialog.modal-xl { width: 700px; }

  #modal-2 .modal-body-content { padding: 30px 30px 10px; }
  #modal-3 .modal-body-content { padding: 50px 30px 40px; }

  .modal-body .newsletter-form { margin-top: 20px; }
  .modal .newsletter-form .form-control, .modal .newsletter-form .btn { height: 50px; font-size: 0.975rem; }

  #modal-3 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }
  #modal-3 .request-form .btn { height: 54px; font-size: 0.975rem; }



}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 768px) and (max-width: 991.98px) { 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 85%; } 

  .py-5 { padding-top: 0; padding-bottom: 0; }
  .py-10 { padding-top: 0; padding-bottom: 0; }
  .py-15 { padding-top: 5px; padding-bottom: 5px; }
  .py-20 { padding-top: 10px; padding-bottom: 10px; }
  .py-30 { padding-top: 15px; padding-bottom: 15px; }
  .py-40 { padding-top: 20px; padding-bottom: 20px; }
  .py-50 { padding-top: 30px; padding-bottom: 30px; }
  .py-60 { padding-top: 40px; padding-bottom: 40px; }
  .py-70 { padding-top: 50px; padding-bottom: 50px; }
  .py-80 { padding-top: 50px; padding-bottom: 50px; }
  .py-90 { padding-top: 60px; padding-bottom: 60px; }
  .py-100 { padding-top: 70px; padding-bottom: 70px; }

  .pt-30 { padding-top: 10px; }
  .pt-40 { padding-top: 20px; }
  .pt-50 { padding-top: 30px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 50px; }
  .pt-80 { padding-top: 50px; }
  .pt-90 { padding-top: 60px; }
  .pt-100 { padding-top: 70px; }

  .pb-30 { padding-bottom: 10px; }
  .pb-40 { padding-bottom: 20px; }
  .pb-50 { padding-bottom: 30px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 50px; }
  .pb-80 { padding-bottom: 50px; }
  .pb-90 { padding-bottom: 60px; }
  .pb-100 { padding-bottom: 70px; }

  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 30px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 50px; }
  .mt-75 { margin-top: 55px; }
  .mt-80 { margin-top: 50px; }
  .mt-85 { margin-top: 55px; }
  .mt-90 { margin-top: 60px; }
  .mt-95 { margin-top: 65px; }
  .mt-100 { margin-top: 70px; }

  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 30px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 50px; }
  .mb-75 { margin-bottom: 55px; }
  .mb-80 { margin-bottom: 50px; }
  .mb-85 { margin-bottom: 55px; }
  .mb-90 { margin-bottom: 60px; }
  .mb-95 { margin-bottom: 65px; }
  .mb-100 { margin-bottom: 70px; }

  /* Backgroud Shape */
  .shape--02:after { width: 140%; height: 82%; top: 12%; left: -20%; }
  .shape--03:after { height: 129%; top: -60px; }
  .shape--04:after { height: 80%; top: 10%; }

  ol.digit-list p { margin-bottom: 5px; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1rem; }                /* 13.6px */
  .s-17, .s-18 { font-size: 1.066176rem; }         /* 14.5px */
  .s-19 { font-size: 1.3rem; }                     /* 18px */
  .s-20, .s-21, .s-22, .s-23 { font-size: 1.397058rem; }  /* 19px */
  .s-24, .s-26, .s-28 { font-size: 1.470588rem; }  /* 20px */
  .s-30, .s-32, .s-34 { font-size: 1.764705rem; }  /* 24px */ 
  .s-36, .s-38, .s-40 { font-size: 1.98529rem; }   /* 27px */   
  .s-42, .s-43, .s-44, .s-45 { font-size: 2.20588rem; }   /* 30px */
  .s-46 { font-size: 2.35294rem; }                 /* 32px */
  .s-47 { font-size: 2.42647rem; }                 /* 33px */
  .s-48 { font-size: 2.5rem; }                     /* 34px */ 
  .s-50 { font-size: 2.64705rem; }                 /* 36px */ 
  .s-52, .s-54 { font-size: 2.79411rem; }          /* 38px */
  .s-56 { font-size: 2.94117rem; }                 /* 40px */
  .s-58 { font-size: 3.08823rem; }                 /* 42px */
  .s-60 { font-size: 3.23529rem; }                 /* 44px */
  .s-62 { font-size: 3.38235rem; }                 /* 46px */
  .s-64 { font-size: 3.52941rem; }                 /* 48px */
  .s-66 { font-size: 3.67647rem; }                 /* 50px */
  .s-68 { font-size: 3.82352rem; }                 /* 52px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; }                 
  p.p-md, p.p-lg { font-size: 1.066176rem; } 

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* Button */
  .btn.btn-sm { font-size: 0.975rem; padding: 0.65rem 1.35rem; }
  .btn.ico-30.ico-left { padding: 0.3665rem 0; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.31175rem 0.5rem; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-right: 10px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1rem; line-height: 0.65rem!important;top: 3px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.065rem; top: -8px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.065rem; top: -8px; margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }

  /* Button Text */
  p.btn-txt { margin: 13px 0 0 0; }
  p.btns-group-txt, .btn-rating { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .star-rating span.rating-txt { font-size: 1rem; }

  /* OS Button */
  .os-btn { width: 240px; min-width: 240px; padding: 14px 15px 12px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 10px; }

  /* Store Badges*/
  .badge-img-xs .store img { max-height: 2.595rem; }
  .badge-img-sm .store img { max-height: 2.72rem; }
  .badge-img-md .store img { max-height: 2.97rem; }
  .badge-img-lg .store img { max-height: 3.095rem; }

  /* Watch Video Link */
  .watch-video { margin-top: 18px; }
  .watch-video-link { margin-right: 10px; }
  .watch-video-link span[class^="flaticon-"]:before, .watch-video-link span[class^="flaticon-"]:after { margin-left: 3px; }
  p.video-txt-lg { font-size: 1.1rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 7rem; height: 7rem; margin-top: -3.5rem; margin-left: -3.5rem; }
  .video-btn-lg { width: 6.5rem; height: 6.5rem; margin-top: -3.125rem;; margin-left: -3.125rem;; }
  .video-btn-md { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-sm { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 5.5rem; line-height: 7rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 5rem; line-height: 6.25rem!important; margin-left: 10px;
  }

  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-sm [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
    font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px;
  }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 95px; height: 95px; top: -25px; left: calc(50% - 50px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -30px; left: calc(50% - 52px); }
  .ico-55 .shape-ico svg { width: 100px; height: 100px; top: -30px; left: calc(50% - 55px); }
  .ico-60 .shape-ico svg { width: 105px; height: 105px; top: -20px; left: calc(50% - 50px); }

  /* Vector Rounded Icons */
  .ico-rounded { width: 70px; height: 70px; }
  .ico-rounded-md { width: 70px; height: 70px; }
  .ico-rounded-lg { width: 80px; height: 80px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 70px!important; }

  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 70px!important; }

  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  /* Vector Square Icons */
  .ico-square { width: 70px; height: 70px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 70px!important; }

  /* Section ID */
  .section-id { margin-bottom: 25px; }
  .section-id.rounded-id { padding: 7px 22px; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60, .section-title.mb-70, .section-title.mb-80 { margin-bottom: 40px; }

  .section-title p.p-md, .section-title p.p-lg, .section-title p.p-xl { margin-top: 12px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.32352rem; margin-top: 12px; }
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-25 { margin-top: 18px; }
  .section-title .btns-group.mt-35 { margin-top: 22px; }
  .section-title .stores-badge.mt-30 { margin-top: 25px; }

  .section-title .advantages, .section-title .btns-txt, .section-title .btns-group-txt { margin-top: 15px; }

  /*------------------------------------------*/
  /*  PRELOAD ANIMATION    
  /*------------------------------------------*/

  #loading-center { height: 90px; width: 90px; margin-top: -45px; margin-left: -45px; }
  .loader { width: 90px; height: 90px; }
  .loader::after { border: 45px solid; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart { display: none; }

  .wsmobileheader .smllogo { display: block; margin-top: 22px; padding-left: 22px; }
  .blog-header .wsmobileheader .smllogo { margin-top: 28px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 34px; }
  .blog-header .smllogo img { max-height: 28px; }

  .wsmobileheader { height: 80px; box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); } 
  .tra-menu .wsmobileheader, .white-menu .wsmobileheader { background-color: #fff; }
  .dark-menu .wsmobileheader, .black-scroll .wsmobileheader { background-color: #1d222f; }

  .theme--dark .tra-menu .wsmobileheader, 
  .theme--dark .white-menu .wsmobileheader { 
    background-color: #1d222f!important; 
    -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
    -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
    box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  }

  /* Mobile Header Phone Icon */
  .wsmenu > .wsmenu-list > li.header-phone > a { font-size: 19px; padding: 7px 32px 6px 15px; margin: 0; }
  .wsmenu > .wsmenu-list > li.header-phone > a span { width: 24px; height: 24px;line-height: 25px;top: 3px; }

  /* Mobile Header Burger Menu */
  .wsanimated-arrow { padding: 14px 22px 14px 0px; margin: 24px 28px 0 0; }
  .wsanimated-arrow span:before { top: -8px; width: 26px; }
  .wsanimated-arrow span:after { bottom: -8px; width: 20px; }

  .dark-menu .wsanimated-arrow span, .dark-menu .wsanimated-arrow span:before, 
  .dark-menu .wsanimated-arrow span:after, .black-scroll .wsanimated-arrow span, 
  .black-scroll .wsanimated-arrow span:before, .black-scroll .wsanimated-arrow span:after { background: #fff; }

  .theme--dark .wsanimated-arrow span, .theme--dark .wsanimated-arrow span:before, 
  .theme--dark .wsanimated-arrow span:after { background: #b1b7cd; }

  .wsactive .wsanimated-arrow span:before, .wsactive .wsanimated-arrow.active span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span { background: transparent; }

  .wsmenu > .wsmenu-list > li > .wsmenu-click { height: 64px; }
  .wsmenu > .wsmenu-list > li.mobile-last-link > a { border-bottom: none; }

  /* Dark Header Settings */
  .dark-menu .wsmenu > .wsmenu-list, .black-scroll .wsmenu > .wsmenu-list { background-color: #2c3142!important; }

  .dark-menu .wsmenu > .wsmenu-list > li > a, 
  .black-scroll .wsmenu > .wsmenu-list > li > a { border-bottom-color: rgba(255, 255, 255, .13); }

  .dark-menu .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: rgba(255, 255, 255, .85); }

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 1.25rem; line-height: 46px; padding: 5px 32px 4px 20px; margin: 0; }
  .tra-menu .wsmenu > .wsmenu-list > li > a, .tra-menu.navbar-light .wsmenu > .wsmenu-list > li > a { color: #666; }
  .black-scroll .wsmenu > .wsmenu-list > li > a.h-link { color: #fff; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 10px; width: 10px; margin: 21px 18px 0px 0px; }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i { transform: rotate(-45deg); margin-top: 28px; }

  .theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a,
  .theme--dark .navbar-dark .wsmenu > .wsmenu-list > li > a { color: #fff!important; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.95rem; line-height: 20px; top: -1px; }

  /* SubMenu / HalfMenu / MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu { width: 100%!important; min-width: 100%!important; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu { width: 100%!important; padding: 10px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { width: 100%!important; left: 0; padding: 12px 10px; }

  .wsmegamenu.w-75 .col-lg-3, .wsmegamenu.icons-menu.w-75 .col-lg-4, 
  .wsmegamenu.halfmenu .col-lg-6, .wsmegamenu.halfmenu.icons-menu .col-lg-6 { padding: 0; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu { right: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 5px; -webkit-border-radius: 0; -moz-border-radius: 0; 
  -o-border-radius: 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; border-bottom: 1px solid #ddd; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu { 
    opacity: 1!important; visibility: visible!important; -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg); 
  }

  .dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu, .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu, .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { 
    border-bottom: 1px solid rgba(255, 255, 255, .13)!important; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0; 
  }

  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { background-color: #2c3142!important; }

  /* Sub Menu / Half Menu / Mega Menu Links */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { 
    color: #666; font-size: 1.1765rem; padding: 15px 14px; 
  }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
    color: #ddd;
    background-color: rgba(255, 255, 255, 0)!important;
  }

  /* Sub Menu / Half Menu / Mega Menu Links Hover */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 15px 14px; text-decoration: none; }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
    background-color: rgba(255, 255, 255, .05)!important;
  }

  /* Sub Menu / Half Menu / Mega Menu Icons */
  .icons-menu.ico-30 [class*="flaticon-"]:before, .icons-menu.ico-30 [class*="flaticon-"]:after { font-size: 2.1875rem; }
  .icons-menu.ico-40 [class*="flaticon-"]:before, .icons-menu.ico-40 [class*="flaticon-"]:after { font-size: 2.5rem; }

  .icons-menu-ico { margin-right: 14px; }
  .wsmegamenu.ico-30 .icons-menu-ico span { top: 8px!important; }

  .icons-menu-txt h6 { font-size: 1.1765rem; }
  .icons-menu-txt span { font-size: 1.066176rem; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { font-size: 1.1765rem; padding: 0 14px; margin-bottom: 10px; } 

  .dark-menu .title, .black-scroll .title, 
  .dark-menu .icons-menu-txt span, .black-scroll .icons-menu-txt span { color: #b1b7cd!important; } 

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn {  
    font-size: 1.25rem;
    line-height: 44px; 
    border-width: 2px;
    padding: 5px 22px;
    margin: 1px 0 2px 0;
    -webkit-border-radius: 0; 
    -moz-border-radius: 0; 
    border-radius: 0; 
  }

  .wsmenu > .wsmenu-list > li a.btn.reg-btn { line-height: 44px; padding: 5px 22px; }
  .wsmenu > .wsmenu-list > li a.btn.fst-btn-link, .wsmenu > .wsmenu-list > li.reg-fst-link > a { margin-left: 0; }

  /* Header Store Badges */
  .wsmenu > .wsmenu-list > li a.store.header-store { margin: 5px 0 0 12px; display: block; float: none; border-bottom: none; }
  .store.header-store img { max-height: 46px; }

  /* Header Social Links */
  .wsmenu > .wsmenu-list > li.header-socials { display: block; }
  .header-socials a { margin-left: 25px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.65rem; } 

  /* Navbar Transparent Buttons */
  .white-scroll .btn--tra-black { color: #fff!important; background-color: #353f4f!important; border-color: #353f4f!important; }

  .black-scroll .btn--tra-white, 
  .black-scroll .btn--tra-black { color: #353f4f!important; background-color: #fff!important; border-color: #fff!important; }

  .white-scroll .btn--tra-grey, 
  .black-scroll .btn--tra-grey { color: #353f4f!important; background-color: #eee!important; border-color: #eee!important; }

  /* Navbar Buttons Hover */
  .white-scroll .hover--tra-white:hover
  { color: #353f4f!important; border-color: #353f4f!important; background-color: transparent!important; }

  .theme--dark .white-scroll .hover--tra-white:hover
  { color: #fff!important; border-color: #fff!important; background-color: transparent!important; }

  .white-scroll .hover--blue:hover, 
  .black-scroll .hover--blue:hover { color: #fff!important; border-color: #007aff!important; background-color: #007aff!important; }

  .white-scroll .hover--tra-blue:hover, .black-scroll .hover--tra-blue:hover 
  { color: #007aff!important; border-color:#007aff!important; background-color: transparent!important; }

  .white-scroll .hover--violet:hover, 
  .black-scroll .hover--violet:hover {color: #fff!important;border-color: #b55e98!important;background-color: #b55e98!important;}

  .white-scroll .hover--tra-violet:hover, .black-scroll .hover--tra-violet:hover 
  { color: #b55e98!important; border-color: #b55e98!important; background-color: transparent!important; }

  .theme--dark .wsmenu > .wsmenu-list { background-color: #2c3142!important; }
  .theme--dark .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: rgba(255, 255, 255, 1); }

  .theme--dark .navbar-dark .hover--tra-black:hover,
  .theme--dark .navbar-dark .scroll .hover--tra-black:hover {
    color: #fff!important;background-color:transparent!important;border-color: #fff!important; 
  }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  .hero-section h2 { margin-bottom: 18px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 24px; }

  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 24px; }
  .hero-section p.s-20, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 26px; }
  .hero-section p.s-21 { font-size: 1.397058rem; margin-bottom: 22px; }
  .hero-section .btn.mt-10, .hero-section .btns-group.mt-10 { margin-top: 5px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 28px; }
  .hero-square-logo img { max-height: 65px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 25px; }
  .hero-app-logo img { max-height: 78px; }
  #hero-25 .hero-app-logo img { max-height: 88px; }

  /* HERO DIGITS */
  .hero-digits { margin-top: -8px; }
  #hero-9 .hero-digits { margin-top: 10px; }
  .hero-section .col-lg-6 .hero-digits { padding: 0 15%; }
  #hd-1-1, #hd-1-2 { width: 50%; padding-right: 0; }
  .hero-digits-block { display: block!important; }
  .hero-digits .block-txt { padding-left: 0; }
  .hero-digits h2.statistic-number { font-size: 2.75rem; }
  .hero-digits h2.statistic-number span { font-size: 2.25rem; }
  .hero-digits .block-txt p.p-sm { margin-top: 10px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form.mt-45 { margin-top: 30px; }
  .hero-section .quick-form.mt-35 { margin-top: 0; }
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.125rem; height: 52px; }
  .hero-section .quick-form.form-half .form-control { height: 48px; font-size: 1.0625rem; padding: 0 12px; }
  .hero-section .quick-form.form-half .btn { height: 48px; font-size: 1rem; padding: 13px 22px; margin-left: 10px; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -5px 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 48px; font-size: 1.0625rem; padding: 0 14px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 48px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-1 */
  #hero-1 { padding-top: 70px; padding-bottom: 70px; } 
  .hero-1-txt p { padding-right: 0; }
  .hero-1-img { margin: 0 -30px 0 -20px; }

  /* HERO-2 */
  #hero-2 { padding-top: 70px; padding-bottom: 70px; } 
  .hero-2-txt p { padding-right: 0; }
  .hero-2-img { padding-right: 5px; }

  /* HERO-3 */
  #hero-3 { padding-top: 80px; }
  .hero-3-txt { margin-top: -50px; }
  .hero-3-txt p.p-lg { padding-right: 0; }
  .hero-3-img { margin: 0 -90% 0 10px; }

  /* HERO-4 */
  #hero-4 { padding-top: 80px; }
  #hero-4:after { height: 11%; }
  .hero-4-txt p { padding: 0; }
  .hero-4-img { margin-top: 40px; }

  /* HERO-5 */
  #hero-5 { padding-top: 80px; padding-bottom: 50px; }
  .hero-5-txt h2 { margin-bottom: 20px; }
  .hero-5-txt p.p-lg { padding-right: 0; margin-bottom: 25px; }

  /* HERO-6 */
  #hero-6 { padding-top: 70px; }
  .hero-6-wrapper { padding: 50px 40px; margin: 0 -10px; }
  .hero-6-txt h2 { padding-right: 5%; margin-bottom: 15px; }
  .hero-6-txt p.p-lg { margin-bottom: 18px; }

  /* HERO-7 */
  #hero-7 { padding-top: 80px; padding-bottom: 80px; }
  .hero-7-txt p.p-lg { margin-bottom: 15px; }
  #hero-7 .quick-form { margin-right: 2%; }
  .hero-7-img { padding-left: 5px; }

  /* HERO-8 */
  #hero-8 { padding-top: 80px; padding-bottom: 30px; }
  .hero-8-txt { margin-top: -30px; }
  .hero-8-txt p, .hero-8-txt .hero-digits { padding-right: 0; }

  #hero-8-form { padding: 26px 25px; margin-left: 25px; border-radius: 10px; }
  #hero-8-form h4, #hero-10-form p { margin-bottom: 15px; }
  #hero-8-form p.p-sm { padding: 0!important; }

  /* HERO-9 */
  #hero-9 { padding-top: 80px; }
  .hero-9-txt h2 { padding: 0 3%; }
  .hero-9-txt h2 img { max-height: 66px; margin: 0 3px; top: -5px; }
  .hero-9-txt p { padding: 0; margin-top: 28px; }

  #hero-9 .quick-form { margin-left: 6%; margin-right: 6%; }
  .hero-9-img { margin: 45px 0 0; }

  /* HERO-10 */
  #hero-10 { padding-top: 70px; padding-bottom: 70px; }
  .hero-10-txt h4 { padding-right: 5%; margin-bottom: 30px; }
  .hero-10-img { margin: 0 -98% 0 15px; }

  /* HERO-11 */
  .hero-11-txt { margin-top: -40px; }
  .hero-11-txt p { padding-right: 0; }
  .hero-11-img { margin: -100px -12% -50px 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 80px; }
  .hero-12-txt p{ padding: 0; }
  .hero-12-img { margin-top: 40px; margin-bottom: -100px; }
  .hero-12-img .video-btn { top: calc(50% - 35px); }

  /* HERO-13 */
  #hero-13 { margin-top: 80px; padding-top: 60px; padding-bottom: 70px; } 
  .hero-13-txt h2 { margin-bottom: 15px; }
  .hero-13-txt p { padding-right: 0; }

  /* HERO-6 */
  #hero-14 { padding-top: 80px; }
  .hero-14-txt h2.s-62 { margin-bottom: 18px; }
  .hero-14-txt p.s-21 { margin-bottom: 25px; }
  #hero-14 .quick-form { margin-left: 7%; margin-right: 7%; }
  .hero-14-img { margin-top: 40px; }
  .hero-14-txt .btn-txt { margin-top: 25px; }

  /* HERO-15 */
  #hero-15 { padding-top: 70px; }
  .hero-15-txt { margin-top: -35px; }
  .hero-15-txt h2 { padding-right: 0; }

  /* HERO-16 */
  #hero-16 { padding-top: 80px; margin-bottom: 50px; }
  .hero-16-txt h2 { padding: 0 6%; }
  .hero-16-img { margin-top: 40px; margin-bottom: -50px; }

  /* HERO-17 */
  #hero-17 { padding-top: 80px; }
  .hero-17-txt { padding: 0 3%; }
  #hero-17 .quick-form { margin-left: 10%; margin-right: 10%; }
  #hero-17 #brands-1 { padding: 30px 3% 0; } 
  #hero-17 #brands-1 .brands-carousel-5 .brand-logo { padding: 0 9px; } 

  /* HERO-18 */
  #hero-18 { padding-top: 70px; padding-bottom: 60px; } 
  #hero-18:after { width: 78%; left: 22%; }
  .hero-18-txt p { padding-right: 0; }
  .hero-18-img { padding-right: 5px; }

  /* HERO-19 */
  #hero-19 { padding-top: 80px; }
  .hero-19-txt h2 { padding: 0 5%; }
  .hero-19-txt p { padding: 0 8%; }

  /* HERO-20 */
  #hero-20 { padding-top: 90px; padding-bottom: 80px; }
  #hero-20:after { width: 54%; }
  .hero-20-txt { padding: 0 10% 0 0; }
  .hero-20-txt .hero-logo { display: none; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 18px; }

  /* HERO-21 */
  #hero-21 { padding-top: 80px; }
  .hero-21-txt { margin-bottom: 40px; }
  .hero-21-txt h2 { padding: 0 3%; margin-bottom: 20px; }
  #hero-21 .hero-overlay { padding: 65px 45px 0; }
  .hero-21-img { margin-bottom: -24%; }
  .hero-21-img .video-btn { top: calc(50% - 50px); }

  /* HERO-22 */
  #hero-22 { padding-top: 80px; }
  #hero-22-form { padding: 35px 25px 25px; }
  #hero-22 .hero-logo { margin-bottom: 20px; }
  #hero-22-form h4 { font-size: 1.65rem; margin-bottom: 15px; }
  #hero-22-form p { font-size: 1.1rem; margin-bottom: 15px; }

  /* HERO-23 */
  #hero-23 { padding-top: 80px; margin-bottom: 25px; }
  .hero-23-txt p { padding: 0; }
  .hero-23-img { margin-top: 20px; margin-bottom: -25px; }

  /* HERO-24 */
  #hero-24 { padding-top: 100px; padding-bottom: 100px; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; padding-bottom: 80px; }
  .hero-25-txt h2 { margin-bottom: 14px; }
  .hero-25-txt p.p-lg { padding-right: 0; margin-bottom: 22px; }

  /* HERO-26 */
  #hero-26 { padding-top: 80px; }
  .hero-26-txt h2 { padding-right: 2%; } 
  #hero-26 .quick-form { margin-right: 3%; }
  .hero-26-img { margin: 0 -94% 0 -20px; }

  /* HERO-27 */
  #hero-27 .hero-overlay { padding-top: 80px; }
  .hero-27-txt h2 { margin-bottom: 24px!important; }
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { padding: 0; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 0; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { padding-right: 5%; margin-bottom: 30px; }

  .a2-txt { padding: 12px 15px 20px; }
  .a-2-1 { margin-left: -10%; margin-bottom: 20px; } 
  .a-2-2 { margin-left: -20%; }
  .a-2-3 { margin-right: -8%; margin-bottom: 20px; }
  .a-2-4 { margin-right: -20%; }

  .a2-txt-quote { position: relative; margin-bottom: -35px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.1rem; }
  .a2-txt-author span { font-size: 0.95rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 20px; }
  #about-3 .txt-block h5 { margin-bottom: 14px; }
  #about-4 .txt-block p { margin-top: 15px; }

  #a4-1 { padding-right: 10px; margin-top: -30px; }
  #a4-2 { padding-left: 10px; margin-bottom: -30px; }

  /* FEATURES */
  .fbox-14-wrapper { padding: 60px 50px; }
  .fbox-14-wrapper .col { padding: 0 14px; }

  #features-12.py-100.shape--bg:after { width: 52%; height: 68%; top: 16%; left: 48%; }
  #features-12.pt-100.shape--bg:after { width: 52%; height: 75%; top: 19%; left: 48%; }
  #features-13.py-100.shape--bg:after { width: 52%; height: 68%; top: 16%; }
  #features-13.pt-100.shape--bg:after { width: 52%; height: 75%; top: 19%; }

  .fbox-12-wrapper { padding-left: 0; }
  .fbox-13-wrapper {  padding-right: 0; }

  #features-4 .row-cols-md-3 .col { padding: 0 8px; }
  .row-cols-md-2 .fb-1, .row-cols-md-2 .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3  { margin-bottom: 35px; }

  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .row-cols-md-3 .fbox-4 { padding: 30px 15px; }
  .row-cols-md-2 .fbox-4 { padding: 30px 45px; }
  .fbox-5 { padding: 35px 25px; } 

  .row-cols-md-2 .fbox-4.fb-1, .row-cols-md-2 .fbox-4.fb-2, .row-cols-md-2 .fbox-7.fb-1, .row-cols-md-2 .fbox-7.fb-2, 
  .row-cols-md-2 .fbox-9.fb-1, .row-cols-md-2 .fbox-9.fb-2, .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3, 
  .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, .rows-2 .fbox-9.fb-3 { margin-bottom: 22px; }

  .fbox-7 { padding: 30px 15px 27px; }
  .fbox-8, .fbox-8.bg--white-100 { padding: 30px 15px; }
  .fbox-9 { padding: 25px 15px 22px; } 

  .fbox-12, .fbox-13 { padding: 22px 18px; } 
  .fbox-12.mb-30, .fbox-13.mb-30 { margin-bottom: 20px; } 

  .fbox-14 { text-align: center; padding: 40px 30px 35px; } 

  .fbox-img.h-155 img, .fbox-img.h-160 img, .fbox-img.h-165 img, 
  .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 128px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-3-wrapper .fbox-img.h-135 img, 
  .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img { max-height: 132px; }

  .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img, .fbox-3 .fbox-img.h-160 img, 
  .fbox-3 .fbox-img.h-170 img, .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 120px; }

  .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, 
  .fbox-4 .fbox-img.h-170 img, .fbox-4 .fbox-img.h-175 img { max-height: 110px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 25px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, .fbox-2 .fbox-img.h-150, 
  .fbox-2 .fbox-img.h-155 { padding-top: 25px; margin: 0 40px 45px; }

  .fbox-2 .fbox-img.h-160 { padding-top: 25px; margin: 0 0 45px; }

  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, 
  .fbox-2 .fbox-img.h-175, .fbox-2 .fbox-img.h-180 { padding-top: 30px; margin: 0 0 45px; }  

  .fbox-3 .fbox-img.h-160, .fbox-3 .fbox-img.h-170, 
  .fbox-3 .fbox-img.h-175, .fbox-3 .fbox-img.h-180 { padding-top: 25px; margin: 0 0 25px; }

  .fbox-3 .fbox-img.h-135 { padding-top: 30px; margin: 0 40px 25px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 30px 6% 0; }
  .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, .fbox-5.fb-4 .fbox-5-img { margin: 0 6% 25px; }

  .fbox-ico { margin-bottom: 18px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-rounded-md, 
  .fbox-ico.ico-rounded-lg, .fbox-ico.ico-square { margin-bottom: 18px; }

  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:after { font-size: 3.75rem; }

  .fbox-wrapper .row-cols-md-2 .ico-rounded { width: 80px; height: 80px; }
  .fbox-wrapper .row-cols-md-2 .ico-rounded [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-rounded [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  .fbox-wrapper .row-cols-md-2 .ico-square { width: 80px; height: 80px; }
  .fbox-wrapper .row-cols-md-2 .ico-square [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-square [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after { font-size: 2.75rem; }

  .fbox-ico-wrap { margin-right: 18px; }
  .rows-2 .fbox-ico-wrap { margin-right: 0; }
  .rows-2 .fbox-11 { display: block; text-align: center; }
  .rows-2 .fbox-11 .fbox-ico { margin-top: 0; }

  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { line-height: 1!important; margin-top: 10px; margin-bottom: 15px; }

  .features-section h6.s-18 { font-size: 1.397058rem; margin-bottom: 12px; }
  .features-section h6.s-20, .features-section h6.s-22, .fbox-txt h5 { margin-bottom: 12px; }
  .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 12px; }
  .fbox-12 h5, .fbox-13 h5 { font-size: 1.3rem; margin-bottom: 10px; }
  .fbox-14 h5 { padding-right: 0; margin-bottom: 12px; }
  .row-cols-md-2 .fbox-7 p { padding: 0 15%; } 
  .fbox-14 p { padding: 0 20%; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 80px; }
  .cbox-6-wrapper { padding: 40px 35px; }

  .ct-11 .txt-block { padding-right: 25px; }
  .ct-12 .txt-block { padding-left: 25px; }
  .ct-13 .row.mb-60 { margin-bottom: 30px }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 60px 40px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay, .bc-5-wrapper .section-overlay { padding: 60px 40px 0; }
  .bc-6-wrapper .section-overlay { padding: 60px 30px; }
  .bc-6 { padding: 24px 16px; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 15px; }
  .txt-block h5, .bc-6 h4 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }

  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.14705rem; }

  .ct-08 .section-title h2, .ct-09 .section-title h2, .ct-10 .section-title h2 { padding: 0 5%; }
  .bc-5-wrapper .section-title {  margin-bottom: 35px; }

  .txt-box { margin-bottom: 5px; }
  .txt-block .txt-box h5 { margin-bottom: 13.5px; }
  .bc-6-txt h5 { margin-bottom: 12px; }
  ul.simple-list li:not(:last-child) p { margin-bottom: 8px; }
  .txt-block .btn { margin-top: 15px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 10px; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 5px; }
  .bc-6-link.mt-15 { margin-top: 10px; }
  .txt-block-tra-link.mt-25 { margin-top: 14px; }

  .img-block-txt .box-title h6, .img-block-txt .box-title h5 { line-height: 1; margin-bottom: 15px; }
  .img-block-txt .box-title span { display: block; top: 0; margin-right: 0; }

  /* Text Block */
  .ct-07 .txt-block { margin-top: 30px; }
  .bc-1-wrapper .txt-block.pr-25.left-column, .bc-2-wrapper .txt-block.pr-25.left-column { padding-right: 0; }
  .bc-1-wrapper .txt-block.pl-25.right-column, .bc-2-wrapper .txt-block.pl-25.right-column { padding-left: 0; }
  .bc-6-txt { padding: 0; }

  /* Content Box */
  .cbox-5-content { padding: 30px; margin: 0 5%; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 3rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.5rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 12px 16px; margin-top: 18px; }

  #cb-6-1, #cb-6-2 { margin-bottom: 30px; }

  /* Content Box Icon */
  .cbox-2 .ico-wrap { margin-right: 1.15rem; }
  .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.125rem; line-height: 1.85rem; }
  .ct-03 .cbox-2-ico { width: 2rem; height: 2rem; font-size: 1.0625rem; line-height: 1.8rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 .cbox-3-ico { top: 5px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  /* Content Box Text */
  .cbox-2-txt, .ct-03 .cbox-2-txt, .cbox-3-txt { margin-bottom: 20px; }
  .cbox-4-txt { margin-bottom: 16px; }

  /* Content Box Typography */
  .cbox-2-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }
  .cbox-6 h6 { margin-bottom: 12px; }
  .cbox-1-txt p {  margin-bottom: 5px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 30px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }
  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 30px 14px 30px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.85rem; top: 0.5px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before, 
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 2.1875rem; line-height: 1.5rem; top: 9px; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 20px; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 15px; }

  /* Tabs */
  .tabs-1 { margin: 0 auto 50px; }
  .tabs-1 li { font-size: 1.125rem; }

  /* Advantages */
  .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 5px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 10px; }
  .payment-icons img { max-height: 26px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after {  font-size: 2rem; line-height: 2rem!important; margin-right: 4px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote p { font-size: 1.05rem; padding-left: 14px; margin-top: 15px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Box Shapes */
  .cbox-5-shape-1 { right: -55px; top: -20px; }
  .cbox-5-shape-2 { left: -20px; bottom: -25px; }
  .cbox-5-shape-1 img, .cbox-5-shape-2 img { height: 55%; width: 55%; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 35px 20px; }
  .img-block-hidden, .ct-10 .img-block, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -80px; }

  .ct-01 .img-block, .ct-03 .img-block.right-column { margin-left: 0; }
  .ct-02 .img-block, .ct-03 .img-block.left-column { margin-right: 0; }
  .ct-04 .img-block { margin-left: 15px; }
  .ct-04 .img-block img { max-width: 220%; }
  .ct-06 .img-block { margin-right: -20%; }
  .ct-07 .img-block { margin-left: -20%; }

  .img-block-txt, .img-block-btn { margin-top: 45px; }

  .bc-1-wrapper .img-block.left-column, .bc-2-wrapper .img-block.left-column { padding-right: 0; }
  .bc-1-wrapper .img-block.right-column, .bc-2-wrapper .img-block.right-column { padding-left: 0; }
  .bc-2-img { margin: 0 -45% 0 15px; }
  .bc-3-img { margin: 0 -90% -25px 15px; }
  .bc-4-img { margin-left: 15px; margin-right: -100%; }
  .bc-5-img.bc-5-tablet.img-block-hidden { margin-bottom: -150px; }
  .bc-6-img { padding: 30px 20px 0; margin-bottom: 20px; }

  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 60px); }

  .ct-11 .img-block { padding-left: 15px; }
  .ct-12 .img-block { padding-right: 15px; }
  .ct-13 .img-block { margin-top: 30px; }

  /* STATISTIC */
  .sb-rounded .statistic-block { padding: 20px; }
  #sb-2-1, #sb-2-2 { width: 50%; }

  #statistic-5 .statistic-digit { padding-bottom: 15px; margin: 0 40px 15px 0; }
  #statistic-1 .statistic-block { display: block!important; text-align: center; }
  .statistic-1-wrapper .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 15px; }
  .statistic-1-wrapper .statistic-block-txt { width: 100%; display: inline-block!important; padding: 0 8%; }

  #sb-2-3, #sb-2-4 { width: 50%; padding-right: 0; }
  #sb-2-3 .statistic-block, #sb-2-4 .statistic-block { display: block!important; }
  #sb-2-3 .statistic-block-txt, #sb-2-4 .statistic-block-txt { padding-left: 0; }
  #statistic-2 .statistic-block-digit { display: block!important; }

  h2.statistic-number { letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number, .#statistic-5 .statistic-digit h2 { font-size: 2.5rem; letter-spacing: -0.5px; }
  #statistic-1 h2.statistic-number { font-size: 2.5rem; line-height: 1; letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 10px; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 10px; }

  #statistic-5 .statistic-txt h5 { margin-bottom: 10px; }
  .statistic-3-wrapper p { margin-top: 12px; }
  .statistic-4-wrapper p { margin-bottom: 12px; }
  #statistic-5 .statistic-txt p { padding-right: 0; }

  .txt-block-stat .statistic-block .txt-block-rating { margin-top: -2px; margin-bottom: -1px; }

  /* PROJECTS */
  .projects-wrapper { padding: 0; }
  #pt-1-1, #pt-1-2, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4 { margin-bottom: 35px; }
  .project-details h5 { margin-bottom: 20px; }
  .more-projects { margin-top: 35px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 10%; padding-bottom: 30px; margin-bottom: 25px; }

  .project-priview-img.mb-50 { margin-bottom: 40px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 40px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.175rem; } 

  /* RATING */
  .rating-section .rating-1-wrapper { padding: 0 10%; }
  .rating-section .rating-2-wrapper { padding: 0; }
  .inner-page-hero .rating-1-wrapper.mt-50 { margin-top: 40px; }

  .rbox-1 .star-rating { font-size: 1rem; }
  .rbox-2-txt { margin-top: 14px; }
  p.rbox-2-txt { margin-top: 10px; line-height: 1; }

  .rbox-1-img { margin-bottom: 12px; }
  .rbox-1-img img { max-height: 28px; }
  .rbox-2-txt img { display: block; max-height: 28px; margin: 8px auto 0; }

  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.3rem; } 

  /* TESTIMONIALS */
  .reviews-2-wrapper, .reviews-3-wrapper { padding: 0; }
  .reviews-4-wrapper, .reviews-5-wrapper { padding-left: 15px; }
  #reviews-4 .txt-block.left-column p, #reviews-5 .txt-block.left-column p { margin-right: -10px; }

  .review-1, .review-2, .review-3 { padding: 35px 25px 25px; }
  .review-4, .review-5 { padding: 35px 30px 25px; }

  #rw-2-1, #rw-2-2 { margin-bottom: 12px; }
  #rw-3-2 { margin-bottom: 30px; }
  #rw-3-2, #rw-3-3 { margin-left: 8px; }
  #rw-4-1, #rw-5-1 { margin-bottom: 25px; }

  .review-txt { margin-top: -38px; }
  .review-txt h6 { margin-bottom: 10px; }

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 0; }

  .review-avatar img, .owl-item .review-avatar img { width: 40px; height: 40px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 46px; height: 46px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 5px; }
  .review-1 .review-author h6 { font-size: 1rem; }

  /* PRESS */
  #press-05, #press-06 { margin-bottom: 30px; }
  .press-logo img { max-height: 74px; }
  .press-txt { margin-top: 10px; }

  /* BRANDS */
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 35px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 5px; }

  .brands-3-wrapper { padding: 35px 25px; }
  .brands-3-wrapper.brands-3-static { margin-left: 3%; margin-right: 3%; }

  #brands-1 .brands-carousel-5 .brand-logo { padding: 0 12px; } 
  #brands-1 .brands-carousel-6 .brand-logo { padding: 0 10px; } 
  #brands-1 .justify-content-center .brand-logo, #brands-3 .brand-logo { padding: 0 10px; }  
  #brands-3 .brands-3-static .brand-logo { padding: 0 8px; }
  #brands-4 .brand-logo { padding: 0 8px; } 

  .brands-section .brand-logo.mb-50 { margin-bottom: 40px; }
  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  .integrations-2-wrapper { padding: 60px 40px; }

  .in_tool_logo { padding: 30px; margin-bottom: 25px; }
  .in_tool_logo.ico-65 img { width: 48px; height: 48px; }
  .in_tool h6 { line-height: 1.3; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 52px; height: 52px; }
  .integrations-1-wrapper .in_tool { padding: 16px; }
  .integrations-1-wrapper .in_tool.mb-30, .integrations-1-wrapper .it-8 { margin-bottom: 20px; }
  .integrations-1-wrapper .in_tool-txt h6 { margin-top: 2px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 0; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }

  /* PRICING */
  #pricing-1 .container, #pricing-2 .container, #pricing-3 .container, 
  .pricing-1-wrapper .col, .pricing-2-wrapper .col, .pricing-3-wrapper .col { padding: 0 5px; }

  .pricing-1-table { padding: 30px 25px 25px; }
  .text-center .pricing-1-table { padding: 35px 25px 25px; }
  .pricing-2-table {  padding: 30px 25px; }
  .pricing-3-table { padding: 25px 20px 30px; }
  .pricing-4-table { padding: 30px 35px; }
  #pt-4-1 { margin: 0 10px 40px 35px; }
  #pt-4-2 { margin: 0 35px 40px 10px; }

  .pricing-table-header h5 { margin-bottom: 20px; }
  .pricing-table-header h4 { margin-bottom: 15px; }
  .pricing-3-table .pricing-table-header h4 { margin-bottom: 10px; }

  .pricing-1-table hr { margin-top: 30px; }
  .pricing-2-table hr, .pricing-3-table hr { margin-top: 20px; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -12px; right: -3px; padding: 5px 9px; }
  .text-center .pricing-1-table .pricing-discount { top: -30px; right: -5px; }
  .pricing-discount h6 { font-size: 1rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.8rem; }

  /* Price */
  .pricing-3-table .price.mt-25 { margin-top: 20px; }

  .price span { font-size: 2.45rem; letter-spacing: -1.5px; } 
  .pricing-2-table.highlight .price span { font-size: 2.05rem; } 
  .pricing-3-table .price span { font-size: 4.15rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 2.85rem; } 

  .price sup { font-size: 2.125rem; top: -2px; }
  .pricing-3-table .price sup { font-size: 2.25rem; top: -20px; }
  .pricing-4-table .price sup { font-size: 2rem; top: -7px; }

  .pricing-3-table .price sup.coins { font-size: 2rem; top: -25px; letter-spacing: -1px; }

  .price sup.validity { font-size: 1.2rem; }
  .pricing-3-table .price sup.validity { font-size: 1.125rem; top: 0; margin-top: 15px; margin-bottom: 25px; }
  .pricing-4-table .price sup.validity { font-size: 1.3rem; left: 4px; top: -2px; }

  .pt-btn { font-size: 0.95rem; padding: 0.785rem 0.5rem; margin-top: 25px; }
  .pricing-1-table .pt-btn, .pricing-2-table .pt-btn { margin-top: 15px; }
  .pricing-4-table .pt-btn { margin-top: 20px; }
  .pricing-3-table .pt-btn { margin-top: 0; }
  .p-table p.btn-txt { font-size: 0.875rem; }

  .price p { font-size: 0.9075rem; margin-top: 14px; margin-bottom: 14px; }
  .pricing-4-table .price p { margin-bottom: 10px; }
  .text-center .price p, .text-center .pricing-2-table .price p { padding: 0; }
  .pricing-table-header p.p-md { font-size: 0.9rem; }

  .pricing-features.mt-25 { margin-top: 15px; }
  .pricing-features li, .text-center .pricing-features li { padding: 6px 0; }
  .pricing-4-table .pricing-features li {padding: 6px 0; }  
  .pricing-features span { display: none; }
  .pricing-features p.p-md { font-size: 0.935rem; line-height: 1.5; }

  /* Pricing Notice */
  .pricing-notice { margin-top: 0; }
  .col-lg-9 .pricing-notice p { padding: 0 15%; }
  .col-lg-10 .pricing-notice p { padding: 0 8%; }
  .pricing-notice .btn.mt-25 { margin-top: 20px; }

  /* Pricing Toggle Button */
  .pricing-section .toggle-btn.mt-30 { margin-top: 20px; }
  #pricing-4 .ext-toggle-btn { margin: 0 35px 20px 10px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1rem; line-height: 32px; }

  .switch-wrap { min-width: 50px; height: 30px; }
  .toggle-btn-md .switch-wrap, .toggle-btn-lg .switch-wrap { min-width: 55px; height: 32px; margin: 0 6px; }

  .switcher { font-size: 0.85rem; height: 30px; }
  .toggle-btn-md .switcher, .toggle-btn-lg .switcher { height: 32px; padding: 0 10px 0 30px; border-radius: 32px; }

  .switcher .show-annual, .switcher .show-monthly { line-height: 30px; }
  .toggle-btn-md .switcher .show-annual, .toggle-btn-md .switcher .show-monthly,
  .toggle-btn-lg .switcher .show-annual, .toggle-btn-lg .switcher .show-monthly { line-height: 32px; }

  .switcher:before { left: 4px; bottom: 4px; width: 22px; height: 22px; }
  .toggle-btn-md .switcher:before, .toggle-btn-lg .switcher:before { left: 5px; bottom: 5px; width: 22px; height: 22px; }
  .switcher.bg--grey:before, .switcher.bg--tra-white:before, .switcher.bg--ghost-white:before { left: 4px; bottom: 3px; }

  .toggle-btn-md .switch-wrap input:checked + .switcher, 
  .toggle-btn-lg .switch-wrap input:checked + .switcher { padding: 0 30px 0 10px; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }
  .table>:not(caption)>*>* { padding-top: 10px; padding-bottom: 10px; }
  .comp-table .table-responsive thead th { font-size: 1rem; }
  .comp-table .table-responsive tbody th { font-size: 0.9375rem; }
  .comp-table .table-responsive tbody td { font-size: 0.9215rem; top: 1px; }

  .comp-table .table-responsive tbody td span { top: 1px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.715rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.925rem; }

  .comp-table-payment .col { padding: 0 10px; }
  .comp-table-payment h6 { font-size: 1.15rem; margin-bottom: 14px; }
  .payment-icons li { padding-right: 2px; }
  .comp-table-payment .payment-icons { margin-top: 18px; }
  .comp-table-payment .payment-icons img { width: auto; max-width: inherit; max-height: 22px; }

  /* TEAM */
  #tm-2-1, #tm-2-4, #tm-2-7, #tm-2-10 { margin-right: 0; }
  #tm-2-2, #tm-2-5, #tm-2-8, #tm-2-11 { margin-left: 0; margin-right: 0; }
  #tm-2-3, #tm-2-6, #tm-2-9, #tm-2-12 { margin-left: 0; }

  .team-member-photo { margin-bottom: 20px; }
  #team-2 .team-member-data { margin-left: 5px; }

  .team-member-data h6.s-20 { margin-bottom: 6px; } 
  .team-member-data p, .team-member-data p.p-md, .team-member-data p.p-lg { font-size: 1.135rem; } 
  
  ul.tm-social { top: 4px; right: 5px; }
  ul.tm-social li { margin-left: 8px; }

  .team-section .more-btn.mt-20 { margin-top: 10px; }

  /* CAREERS */
  .role-box { padding: 20px; margin-bottom: 20px; }
  .role-box-txt span, #careers-1 .role-box span { font-size: 0.85rem; margin-bottom: 12px; }
  .role-box-txt h6 { margin-bottom: 5px; }
  #careers-1 .role-box h6 { margin-bottom: 5px; }
  .role-box-txt p { margin-bottom: 10px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 25px; }
  #role-page .inner-page-title span { margin-bottom: 20px; }

  .role-info hr { margin: 25px 0; }
  #role-page .cbox-6-wrapper { margin: 25px 0; }
  .role-info h5 { margin-bottom: 15px; }
  .txt-block.role-info .btn { margin-top: 30px; }

  /* BANNER */
  #banner-11 { margin-top: 20px; }
  #banner-12 { margin-top: 30px; margin-bottom: 5px; }
  #banner-8 { text-align: center; }
  #banner-9 { padding-top: 55px; padding-bottom: 55px; }

  .banner-1-wrapper .banner-overlay { padding: 45px 12% 55px; } 
  .banner-2-wrapper .banner-overlay { padding: 45px 15% 55px; }
  .banner-3-wrapper .banner-overlay { padding: 45px 20% 55px; }
  #banner-5 .banner-overlay.pt-80.pb-90, #banner-7 .banner-overlay.pt-80.pb-90 { padding-top: 45px; padding-bottom: 55px; }
  #banner-6 .banner-overlay { padding-top: 45px; padding-bottom: 55px; }
  #banner-8 .banner-overlay { padding-top: 40px; padding-bottom: 50px; }
  .banner-11-wrapper .banner-overlay { padding: 0 40px 0 45px; } 
  .banner-12-wrapper .banner-overlay, .banner-13-wrapper .banner-overlay { padding-left: 45px; } 
  .banner-16-wrapper .banner-overlay { padding: 40px 16% 35px; }

  .banner-9-txt { padding-right: 25%; }
  .banner-12-txt { margin-top: 0; padding-right: 0; }
  .banner-13-txt { padding-right: 8%; }

  .banner-section .section-id { margin-bottom: 18px; }
  #banner-15.banner-section .section-id { margin-bottom: 22px; }

  .banner-1-txt h2 { margin-bottom: 16px; }
  .banner-2-txt h2 { padding: 0 3%; margin-bottom: 25px; }
  .banner-4-txt h2 { margin-bottom: 25px; }
  .col-md-8 .banner-5-txt h2 {padding: 0 5%; }
  .banner-7-txt h2 { margin-bottom: 25px; }
  .banner-10-txt h2 { margin-bottom: 22px; }
  .banner-11-txt h2 { margin-bottom: 14px; }
  .banner-12-txt h2, .banner-13-txt h2 { padding-right: 10%; margin-bottom: 14px; }
  .banner-15-txt h2 { margin-bottom: 30px; }
  .banner-16-txt h4 { margin-bottom: 10px; }
  
  .banner-section p.p-lg, .banner-section p.p-xl { margin-bottom: 20px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl { margin-top: 10px; margin-bottom: 18px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl { margin-top: 10px; margin-bottom: 15px; }
  .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 15px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 15px;margin-bottom: 20px; }
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 20px!important; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 15px; margin-bottom: 22px!important; }
  .banner-11-txt p { padding-right: 0; }
  .banner-14-txt p.p-lg { font-size: 1.2rem; margin-top: 25px; margin-bottom: 25px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 25px; }
  .banner-8-buttons.text-end { text-align: center!important; margin-top: 20px; }
  .banner-15-txt .download-links { margin-top: 15px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 10px; }

  .banner-9-img { padding-right: 10px; }
  .banner-10-img { padding-left: 0; } 
  .banner-11-img { margin-top: -20px; margin-left: -3px; }
  .banner-12-img { margin: -30px -20px -20px -40px; }
  .banner-13-img { margin: -30px -100px -30px 0; }

  /* DOWNLOAD */
  .version-release { margin-bottom: 50px; }
  .release-data { padding-bottom: 20px; margin-bottom: 20px; }
  .release-data h5 { margin-bottom: 30px; }

  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 50px; }

  .faqs-3-questions .questions-holder { padding: 0; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 27px; } 
  #faqs-2 .inner-page-title { margin-bottom: 25px; }
  #faqs-3 .section-title { margin-bottom: 45px; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 18px 0 20px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.825rem; top: 19px; right: 2px; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.935rem; top: 23px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 25px 0 10px 0; } 
  #faqs-2 .accordion-panel-item.mb-35 { margin-bottom: 25px; }
  #faqs-3 .question.mb-35 { margin-bottom: 20px; }

  .question h5, .accordion-panel-item h5 { margin-bottom: 12px; }

  .accordion-panel { padding: 0 0 5px 0; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 { margin: 0 auto 35px; }
  .faqs-section .tabs-1 li { min-width: 162px; padding: 20px 0; margin: 0 4px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 45px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 15px; }
  .more-questions-txt { padding: 18px 42px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-2 .newsletter-overlay { text-align: center; padding: 40px 18% 30px; }
  #newsletter-3 { margin-top: 20px; margin-bottom: 20px; }  
  #newsletter-4 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 50px; padding-bottom: 40px; }

  #newsletter-3 .newsletter-txt { padding-right: 5%; margin-top: -10px; }
  #newsletter-3 .col-lg-6 .newsletter-txt { margin-top: 0; }

  .newsletter-txt h2, #newsletter-4 .newsletter-txt h2 { margin-bottom: 20px; }
  .newsletter-txt h4, .newsletter-txt h3 { padding: 0 10%; margin-bottom: 22px; }

  #newsletter-4 .newsletter-txt p { padding: 0 5%; }
  #newsletter-5 .newsletter-txt p { margin-top: 15px; }

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form { margin-top: 0; padding: 0 10%; }
  #newsletter-4 .newsletter-form { padding: 0 5%; }
  #newsletter-5 .newsletter-form { margin-top: 20px; padding: 0 5%; }

  .newsletter-section .form-control { height: 46px; margin-right: 8px; }
  #newsletter-1.newsletter-section .form-control { height: 46px; font-size: 1.0625rem; }
  #newsletter-5.newsletter-section .form-control { height: 46px; font-size: 1.0625rem; }

  .newsletter-section .btn { height: 46px; }
  #newsletter-1 .btn { height: 46px; font-size: 1.0625rem; padding: 0.7rem 1.3rem; }
  #newsletter-5.newsletter-section .btn { height: 46px; }

  #newsletter-3 .newsletter-img { margin-top: -25px; margin-bottom: -25px; }
  #newsletter-3 .col-lg-6 .newsletter-img { padding-left: 0; margin-left: -20px; margin-right: -20px }

  /* BLOG */
  .blog-post.mb-40 { margin-bottom: 30px; }
  .posts-wrapper .col-md-12 .blog-post.mb-40 { margin-top: 5px; }
  #bp-1-3 { display: none; }

  .blog-post-img.mb-35 { margin-bottom: 25px; }
  .posts-wrapper .col-md-12 .blog-post-img.mb-35 { margin-bottom: 0; }
  .post-tag { font-size: 0.85rem; margin-bottom: 12px; }

  .blog-post-txt h6 { margin-bottom: 12px; }
  .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 14px; }

  .blog-post-meta.mt-20, .blog-post-meta.mt-30 { margin-top: 14px; }
  .meta-list-divider { padding: 0 1px; }

  /* Blog Listing */
  .wide-post { margin-bottom: 60px; }
  .wide-post .blog-post-img { padding-right: 0; }
  .wide-post .blog-post-txt { padding-left: 0; }

  .square-post { margin-top: 30px; margin-bottom: 40px; }
  .square-post .col-md-12 { padding: 0; }
  .square-post .blog-post-txt { padding: 0 10px 0 30px; }

  .posts-category { margin-top: 30px; margin-bottom: 40px; }
  .posts-category span { top: 1px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  .posts-wrapper .col-md-12 .blog-post-img { display: inline-block; width: 50%; float: left;  }
  .posts-wrapper .col-md-12 .blog-post-txt { display: inline-block; width: 50%; margin-top: 25px; padding-left: 25px; float: none; }

  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 3%; margin-bottom: 20px; }
  .single-post-title .blog-post-meta.mt-35 { margin-top: 25px; }
  .post-inner-img { margin-top: 30px; margin-bottom: 30px; }

  .single-post-txt h5, .single-post-txt h4 { margin-top: 15px; margin-bottom: 18px; }

  .txt-code { padding: 18px 15px; margin: 20px 0; }

  .single-post-txt figure { margin-bottom: 20px; }
  .single-post-txt .blockquote p { font-size: 1.25rem; margin-bottom: 25px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; }

  .post-share-list { margin-top: 30px; padding-bottom: 40px; }

  .post-author { padding-top: 50px; padding-bottom: 50px; }
  .post-author-avatar { margin-right: 25px; }
  .post-author-avatar img { width: 75px; height: 75px; }
  .post-author-txt h5 { margin-bottom: 12px; }
  .post-author-txt p { padding-right: 4%; }

  .author-follow-btn { padding: 5px 12px; top: 45px; right: 10px; }

  .post-comments { padding-top: 50px; }
  .post-comments h5 { margin-bottom: 40px; }
  .comment-body { margin-left: 20px; }
  .post-comments img { width: 52px; height: 52px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 1.0625rem; } 
  .post-comments #leave-comment h5 { margin-top: 50px; }

  .comment-form { margin-top: 40px; }
  .comment-form p { margin-bottom: 12px; }
  .comment-form .form-control { height: 52px; }
  .comment-form textarea { min-height: 190px; }
  .comment-form .btn { margin-top: 0; }

  /* CONTACTS */
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }

  #contacts-1 .contact-form .col-md-12 span { margin-bottom: 15px; }
  .contact-form .form-control, .contact-form .form-select { height: 52px; }
  .contact-form textarea { min-height: 160px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn.mt-15 { margin-top: 0; }

  /* FOOTER */
  .footer { padding-bottom: 25px; }
  .footer hr { margin-top: 5px; margin-bottom: 25px; }

  #footer-10.pt-50 { padding-top: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 33px; }
  #footer-1 .footer-logo, #footer-5 .footer-logo, #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 20px; }
  #footer-10 img.footer-logo { max-height: 36px; }

  .footer h6 { font-size: 1.17647rem; margin-bottom: 16px; } /* 16px */

  #footer-6 .footer-info { padding-left: 5%; }
  #footer-7 .footer-info { margin-bottom: 0; }
  #footer-8 .footer-info { margin-bottom: 25px; }
  #footer-10 .footer-info { margin-bottom: 30px; padding-bottom: 40px; }

  #footer-1 .footer-links.fl-1 { padding-left: 22%; }
  #footer-1 .footer-links.fl-2 { padding-left: 12%; }
  #footer-2 .footer-links.fl-1 { padding-left: 18%; }
  #footer-2 .footer-links.fl-2 { padding-left: 10%; }
  #footer-2 .footer-links.fl-3, #footer-2.ft-2-ntr .footer-links.fl-1, #footer-2.ft-2-ntr .footer-links.fl-2 { padding-left: 0; }
  #footer-6 .footer-links.fl-2 { padding-left: 18%; }
  #footer-8 .footer-links.text-end { text-align:left!important; margin-bottom: 10px; }
  #footer-10 .footer-links { margin-bottom: 10px; }

  .footer-mail-link { font-size: 1.073529rem; }

  #footer-5 .footer-info p { margin-bottom: 5px; }

  #footer-1 .footer-info .footer-mail-link { margin-top: 15px; }
  #footer-2 .footer-links .footer-mail-link, #footer-3 .footer-links .footer-mail-link { margin-bottom: 10px; }

  .footer .foo-links li p { margin-bottom: 8px; }

  .foo-links li a span.ext-link { left: 3px; top: 1px; }
  .foo-links.ico-15 [class*="flaticon-"]:before, .foo-links.ico-15 [class*="flaticon-"]:after { font-size: 0.775rem; }

  .footer-socials.ico-20 a, .footer-socials.ico-25 a { margin-right: 11px; } 
  .footer-socials.ico-20 li:last-child a, .footer-socials.ico-25 li:last-child a { margin-right: 0; } 
  .footer-socials.ico-25 [class*="flaticon-"]:before, .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.175rem; }

  #footer-2 .footer-form { padding-left: 3%; }
  #footer-7 .footer-form { padding-left: 0; }
  .footer-form .form-control, .footer-form .btn { height: 28px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 24px!important; }

  #footer-8 .bottom-footer { margin-top: 0; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero { margin-top: 80px; padding-top: 70px; }
  .page-hero-section-overlay { margin-top: 80px; padding-top: 60px; }

  .inner-page-title { margin-bottom: 45px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { font-size: 1.15rem; margin-top: 14px; }
  .page-hero-section h2 { line-height: 1.25; margin-bottom: 20px; }

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin-top: 20px; margin-bottom: 20px; }
  .txt-block.legal-info h4 { margin-top: 40px; margin-bottom: 20px; }
  .txt-block.legal-info h4 span, .txt-block.legal-info h5 span { margin-right: 2px; }

  /* SIGN IN / SIGN UP */
  #reset-password { padding-bottom: 80px; }

  .register-page-txt img { max-height: 26px; margin-bottom: 40px; }
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 35px; }

  .register-form-title { margin-bottom: 30px; }
  #login .register-page-txt, #signup .register-page-txt { padding: 50px 30px 0; }

  #signup .register-page-txt h2 { line-height: 1; }
  .register-page-txt p.mt-25 { margin-top: 20px; }
  .reset-password-link p { margin-top: 0; margin-bottom: 10px; }
  #signup .form-data span { font-size: 0.9rem; }

  #login .register-page-copyright { left: 30px; bottom: 40px; }
  #signup .register-page-copyright { right: 30px; bottom: 40px; }
  .register-page-copyright p { font-size: 0.95rem; }

  #login .register-page-form { padding: 45px 35px 45px 25px; }
  #signup .register-page-form { padding: 45px 25px 45px 35px; }
  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 10px 30px; }

  .separator-line { font-size: 1rem; margin: 20px 0; }
  .register-page-form p.input-header { font-size: 0.95rem; margin-bottom: 10px; padding-left: 5px; }
  .register-page-form p.create-account { margin-top: 20px; }

  .reset-password-link p { font-size: 1rem; margin-top: -3px; margin-bottom: 10px; }
  .form-data span { font-size: 0.875rem; }
  .reset-page-wrapper .form-data span { font-size: 1rem; }
  .register-page-form p.create-account { font-size: 1rem; }

  .reset-form-title { margin-bottom: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 15px; }

  .reset-page-wrapper { margin: 0 30px; }
  .reset-page-wrapper form { padding: 35px 25px 10px; }

  .reset-page-wrapper .form-control, 
  .register-page-form .form-control { height: 50px; font-size: 1.0625rem; padding: 5px 15px; margin-bottom: 20px; }

  #login .register-page-form .btn.submit { height: 50px; font-size: 1.0625rem; margin-top: 12px; }
  #signup .register-page-form .btn.submit { height: 50px; font-size: 1.0625rem; margin-top: 20px; }
  .reset-page-wrapper .btn.submit { height: 50px; font-size: 1.0625rem; }

  .btn-google img { width: 18px; height: 18px; top: -2px; }
  .register-page-form .btn-google { font-size: 1rem; }

  .btn-show-pass { top: 17px; }

  /* PAGE 404 */
  .page-404-txt { margin-top: 80px; padding-top: 100px; padding-bottom: 80px; }
  .page-404-txt h2 { margin-bottom: 20px; }
  .page-404-txt h6 { padding: 0 5%; margin-bottom: 25px; }
  .page-404-img { padding: 0 8%; margin-bottom: 30px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 520px; }
  #modal-2 .modal-dialog { width: 320px; }
  #modal-3 .modal-dialog.modal-xl { max-width: 600px!important; }

  #modal-2 .modal-body-content { padding: 30px 30px 10px; }
  #modal-3 .modal-body-content { padding: 40px 25px 30px; }

  .modal-body .newsletter-form { margin-top: 20px; }
  .modal .newsletter-form .form-control, .modal .newsletter-form .btn { height: 50px; font-size: 0.975rem; }

  #modal-3 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }
  #modal-3 .request-form .form-control {  margin: 0 0 20px 0; }
  #modal-3 .request-form .btn { height: 48px; font-size: 0.975rem; }




}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 767px) { 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .block-shadow { 
    -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .08); 
  }

  .ts-0 { padding-top: 80px; }

  .py-5 { padding-top: 0; padding-bottom: 0; }
  .py-10 { padding-top: 0; padding-bottom: 0; }
  .py-15 { padding-top: 5px; padding-bottom: 5px; }
  .py-20 { padding-top: 10px; padding-bottom: 10px; }
  .py-30 { padding-top: 15px; padding-bottom: 15px; }
  .py-40 { padding-top: 20px; padding-bottom: 20px; }
  .py-50 { padding-top: 30px; padding-bottom: 30px; }
  .py-60 { padding-top: 40px; padding-bottom: 40px; }
  .py-70 { padding-top: 50px; padding-bottom: 50px; }
  .py-80 { padding-top: 50px; padding-bottom: 50px; }
  .py-90 { padding-top: 60px; padding-bottom: 60px; }
  .py-100 { padding-top: 70px; padding-bottom: 70px; }

  .pt-30 { padding-top: 10px; }
  .pt-40 { padding-top: 20px; }
  .pt-50 { padding-top: 30px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 50px; }
  .pt-80 { padding-top: 50px; }
  .pt-90 { padding-top: 60px; }
  .pt-100 { padding-top: 70px; }

  .pb-30 { padding-bottom: 10px; }
  .pb-40 { padding-bottom: 20px; }
  .pb-50 { padding-bottom: 30px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 50px; }
  .pb-80 { padding-bottom: 50px; }
  .pb-90 { padding-bottom: 60px; }
  .pb-100 { padding-bottom: 70px; }

  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 30px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 50px; }
  .mt-75 { margin-top: 55px; }
  .mt-80 { margin-top: 50px; }
  .mt-85 { margin-top: 55px; }
  .mt-90 { margin-top: 60px; }
  .mt-95 { margin-top: 65px; }
  .mt-100 { margin-top: 70px; }

  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 30px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 50px; }
  .mb-75 { margin-bottom: 55px; }
  .mb-80 { margin-bottom: 50px; }
  .mb-85 { margin-bottom: 55px; }
  .mb-90 { margin-bottom: 60px; }
  .mb-95 { margin-bottom: 65px; }
  .mb-100 { margin-bottom: 70px; }

  h2, h3, h4, h5, h6 { line-height: 1.35; }

  p.p-sm { font-size: 1rem; }

  /* Button Text */
  p.btn-txt { margin: 12px 0 0 0; }
  p.btns-group-txt { margin: 12px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 16px; }

  /* Section ID */
  .section-id { margin-bottom: 24px; }
  .section-id.rounded-id { margin-bottom: 35px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 40px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 50px; }

  .section-title .btns-group.mt-25 { margin-top: 18px; }
  .section-title .btns-group.mt-35 { margin-top: 22px; }
  .section-title .stores-badge.mt-30 { margin-top: 25px; }

  .section-title .advantages, .section-title .btns-txt, .section-title .btns-group-txt { margin-top: 15px; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart { display: none; }

  .wsmobileheader .smllogo { display: block; margin-top: 22px; padding-left: 22px; }
  .blog-header .wsmobileheader .smllogo { margin-top: 27px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 36px; }
  .blog-header .smllogo img { max-height: 30px; }

  .wsmobileheader { height: 80px; box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); } 
  .tra-menu .wsmobileheader, .white-menu .wsmobileheader { background-color: #fff; }
  .dark-menu .wsmobileheader, .black-scroll .wsmobileheader { background-color: #1d222f; }

  .theme--dark .tra-menu .wsmobileheader, 
  .theme--dark .white-menu .wsmobileheader { 
    background-color: #1d222f!important; 
    -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
    -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
    box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  }

  /* Mobile Header Phone Icon */
  .wsmenu > .wsmenu-list > li.header-phone > a { font-size: 19px; padding: 7px 32px 6px 15px; margin: 0; }
  .wsmenu > .wsmenu-list > li.header-phone > a span { width: 24px; height: 24px;line-height: 25px;top: 3px; }

  /* Mobile Header Burger Menu */
  .wsanimated-arrow { padding: 14px 22px 14px 0px; margin: 24px 28px 0 0; }
  .wsanimated-arrow span:before { top: -8px; width: 26px; }
  .wsanimated-arrow span:after { bottom: -8px; width: 20px; }

  .dark-menu .wsanimated-arrow span, .dark-menu .wsanimated-arrow span:before, 
  .dark-menu .wsanimated-arrow span:after, .black-scroll .wsanimated-arrow span, 
  .black-scroll .wsanimated-arrow span:before, .black-scroll .wsanimated-arrow span:after { background: #fff; }

  .theme--dark .wsanimated-arrow span, .theme--dark .wsanimated-arrow span:before, 
  .theme--dark .wsanimated-arrow span:after { background: #b1b7cd; }

  .wsactive .wsanimated-arrow span:before, .wsactive .wsanimated-arrow.active span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span { background: transparent; }

  .wsmenu > .wsmenu-list > li > .wsmenu-click { height: 64px; }
  .wsmenu > .wsmenu-list > li.mobile-last-link > a { border-bottom: none; }

  /* Dark Header Settings */
  .dark-menu .wsmenu > .wsmenu-list, .black-scroll .wsmenu > .wsmenu-list { background-color: #2c3142!important; }

  .dark-menu .wsmenu > .wsmenu-list > li > a, 
  .black-scroll .wsmenu > .wsmenu-list > li > a { border-bottom-color: rgba(255, 255, 255, .13); }

  .dark-menu .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: rgba(255, 255, 255, .85); }

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 1.125rem; line-height: 46px; padding: 5px 32px 4px 20px; margin: 0; }
  .tra-menu .wsmenu > .wsmenu-list > li > a, .tra-menu.navbar-light .wsmenu > .wsmenu-list > li > a { color: #666; }
  .black-scroll .wsmenu > .wsmenu-list > li > a.h-link { color: #fff; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 10px; width: 10px; margin: 21px 18px 0px 0px; }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i { transform: rotate(-45deg); margin-top: 28px; }

  .theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a,
  .theme--dark .navbar-dark .wsmenu > .wsmenu-list > li > a { color: #fff!important; }

  /* SubMenu / HalfMenu / MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu { width: 100%!important; min-width: 100%!important; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu g{ width: 100%!important; padding: 10px; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { width: 100%!important; left: 0; padding: 12px 10px; }

  .wsmegamenu.w-75 .col-lg-3, .wsmegamenu.icons-menu.w-75 .col-lg-4, 
  .wsmegamenu.halfmenu .col-lg-6, .wsmegamenu.halfmenu.icons-menu .col-lg-6 { padding: 0; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu { right: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 5px; -webkit-border-radius: 0; -moz-border-radius: 0; 
  -o-border-radius: 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; border-bottom: 1px solid #ddd; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu { 
    opacity: 1!important; visibility: visible!important; -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg); 
  }

  .dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu, 
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { 
    border-bottom: 1px solid rgba(255, 255, 255, .13)!important; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0;
  }

  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { background-color: #2c3142!important; }

  /* Sub Menu / Half Menu / Mega Menu Links */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {color: #666; font-size: 1.0625rem; padding: 15px 14px;}

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
    color: #ddd;
    background-color: rgba(255, 255, 255, 0)!important;
  }

  /* Sub Menu / Half Menu / Mega Menu Links Hover */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 15px 14px; text-decoration: none; }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
    background-color: rgba(255, 255, 255, .05)!important;
  }

  /* Sub Menu / Half Menu / Mega Menu Icons */
  .icons-menu.ico-30 [class*="flaticon-"]:before, .icons-menu.ico-30 [class*="flaticon-"]:after { font-size: 2.1875rem; }
  .icons-menu.ico-40 [class*="flaticon-"]:before, .icons-menu.ico-40 [class*="flaticon-"]:after { font-size: 2.5rem; }

  .icons-menu-ico { margin-right: 14px; }
  .wsmegamenu.ico-30 .icons-menu-ico span { top: 8px!important; }

  .icons-menu-txt h6 { font-size: 1.0625rem; }
  .icons-menu-txt span { font-size: 1rem; }

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { font-size: 1.0625rem; padding: 0 7px; margin-bottom: 10px; } 

  .dark-menu .title, .black-scroll .title, .dark-menu .icons-menu-txt span, 
  .black-scroll .icons-menu-txt span { color: #b1b7cd!important; }  

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn {  
    font-size: 1.125rem;
    line-height: 44px; 
    border-width: 2px;
    padding: 5px 22px;
    margin: 1px 0 2px 0;
    -webkit-border-radius: 0; 
    -moz-border-radius: 0; 
    border-radius: 0; 
  }

  .wsmenu > .wsmenu-list > li a.btn.reg-btn { line-height: 44px; padding: 5px 22px; }
  .wsmenu > .wsmenu-list > li a.btn.fst-btn-link, .wsmenu > .wsmenu-list > li.reg-fst-link > a { margin-left: 0; }

  /* Header Store Badges */
  .wsmenu > .wsmenu-list > li a.store.header-store { margin: 5px 0 0 12px; display: block; float: none; border-bottom: none; }
  .store.header-store img { max-height: 46px; }

  /* Header Social Links */
  .wsmenu > .wsmenu-list > li.header-socials { display: block; }
  .header-socials a { margin-left: 25px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.45rem; } 

  /* Navbar Transparent Buttons */
  .white-scroll .hover--tra-white:hover
  { color: #353f4f!important; border-color: #353f4f!important; background-color: transparent!important; }

  .theme--dark .white-scroll .hover--tra-white:hover
  { color: #fff!important; border-color: #fff!important; background-color: transparent!important; }

  .white-scroll .btn--tra-black { color: #fff!important; background-color: #353f4f!important; border-color: #353f4f!important; }

  .black-scroll .btn--tra-white, 
  .black-scroll .btn--tra-black { color: #353f4f!important; background-color: #fff!important; border-color: #fff!important; }

  .white-scroll .btn--tra-grey, 
  .black-scroll .btn--tra-grey { color: #353f4f!important; background-color: #eee!important; border-color: #eee!important; }

  /* Navbar Buttons Hover */
  .white-scroll .hover--blue:hover, 
  .black-scroll .hover--blue:hover {color: #fff!important; border-color: #007aff!important; background-color: #007aff!important;}

  .white-scroll .hover--tra-blue:hover, .black-scroll .hover--tra-blue:hover 
  { color: #007aff!important; border-color: #007aff!important; background-color: transparent!important; }

  .white-scroll .hover--violet:hover, .black-scroll .hover--violet:hover 
  { color: #fff!important; border-color: #b55e98!important; background-color: #b55e98!important; }

  .white-scroll .hover--tra-violet:hover, .black-scroll .hover--tra-violet:hover 
  { color: #b55e98!important; border-color: #b55e98!important; background-color: transparent!important; }

  .theme--dark .wsmenu > .wsmenu-list { background-color: #2c3142!important; }
  .theme--dark .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: rgba(255, 255, 255, 1); }

  .theme--dark .navbar-dark .hover--tra-black:hover,
  .theme--dark .navbar-dark .scroll .hover--tra-black:hover {
    color: #fff!important;background-color:transparent!important;border-color: #fff!important; 
  }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 25px; }
  .hero-section h2.s-62 { margin-bottom: 15px; }

  .hero-section p.p-sm { padding: 0!important; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 22px; }
  .hero-section p.s-20, .hero-section p.s-21, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 24px; }
  .hero-section .btn.mt-10, .hero-section .btns-group.mt-10 { margin-top: 5px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* HERO DIGITS */
  #hd-1-1, #hd-1-2 { width: 50%; padding-right: 0; }
  .hero-digits-block { display: block!important; }
  .hero-digits .block-txt { padding-left: 0; }
  .hero-digits .block-txt p.p-sm { margin-top: 10px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control { text-align: center; margin-bottom: 15px; }
  .hero-section .quick-form .input-group-btn { display: block; width: 100%!important; }
  .hero-section .quick-form .btn { display: block; width: 100%!important; margin-left: 0; }
  .hero-section .quick-form.form-half .btn { margin-left: 0; }

  /* HERO-1 */
  #hero-1 { text-align: center; padding-top: 80px; padding-bottom: 80px; }
  .hero-1-txt p { padding-right: 0; }
  .hero-1-img { margin-top: 40px; }

  /* HERO-2 */
  #hero-2 { text-align: center; padding-top: 80px; padding-bottom: 80px; }
  .hero-2-txt p { padding-right: 0; }
  .hero-2-img { margin-top: 40px; padding-right: 0; }

  /* HERO-3 */
  #hero-3 { text-align: center; padding-top: 80px; }
  .hero-3-txt { margin-top: 0; }
  .hero-3-txt p { padding-right: 0; }
  .hero-3-img { margin: 40px 0 0; }

  /* HERO-4 */
  #hero-4 { padding-top: 80px; }
  .hero-4-txt h2, .hero-4-txt p { padding: 0; }

  /* HERO-5 */
  #hero-5 { text-align: center; padding-top: 80px; padding-bottom: 0; }
  .hero-5-txt p.p-lg { padding-right: 0%; }
  .hero-5-img { margin: 40px 0 -50px; }

  /* HERO-6 */
  #hero-6 { text-align: center; padding-top: 70px; }
  .hero-6-wrapper { padding: 65px 60px; }
  .hero-6-img { margin-top: 40px; }

  /* HERO-7 */
  #hero-7 { padding-top: 80px; text-align: center; }
  #hero-7 .quick-form { margin-right: 0; }
  .hero-7-img { padding-left: 0; margin-top: 40px; }

  /* HERO-8 */
  #hero-8 { text-align: center; padding-top: 80px; padding-bottom: 0; margin-bottom: 10px; }
  .hero-8-txt { margin-top: 0; }
  .hero-8-txt p { padding-right: 0; }
  #hero-8-form { margin-top: 40px; }

  /* HERO-9 */
  #hero-9 { padding-top: 80px; }
  .hero-9-txt h2 img { max-height: 64px; margin: 0 3px; top: -5px; }
  .hero-9-txt p { padding: 0; margin-top: 28px; }

  /* HERO-10 */
  #hero-10 { text-align: center; padding-top: 80px; padding-bottom: 0; }
  #hero-10:after {position: absolute; content: ''; z-index: -1; width: 100%; height: 7%; bottom: 0;left: 0;background-color: #fff;}
  .hero-10-txt h4 { padding-right: 0; margin-bottom: 30px; }
  .hero-10-img { margin: 40px 0 0 0; }

  /* HERO-11 */
   #hero-11 { text-align: center; padding-top: 80px; }
  .hero-11-txt { margin-top: 0; }
  .hero-11-txt p { padding-right: 0; }
  .hero-11-img { margin: 40px 0 -100px; }

  /* HERO-12 */
  #hero-12 { padding-top: 80px; border-bottom: none; }
  #hero-12:after {position: absolute; content: ''; z-index: -1; width: 100%; height: 7%; bottom: 0;left: 0;background-color: #fff;}
  .hero-12-txt h2, .hero-12-txt p { padding: 0; }
  .hero-12-img { margin-top: 40px; margin-bottom: 0; }
  .hero-12-img .video-btn { top: calc(50% - 2px); }

  /* HERO-13 */
  #hero-13 { text-align: center; padding-top: 80px; padding-bottom: 80px; } 
  #hero-13:after { display: none; }
  .hero-13-txt p { padding-right: 0; }
  .hero-13-img { margin-top: 40px; }

  /* HERO-14 */
  #hero-14 { padding-top: 80px; }
  .hero-14-txt h2.s-62 { margin-bottom: 18px; }

  /* HERO-15 */
  #hero-15 { text-align: center; padding-top: 80px; }
  .hero-15-txt { margin-top: 0; }
  .hero-15-txt h2, .hero-15-txt p { padding-right: 0; }
  .hero-15-img { margin-top: 40px; }

  /* HERO-16 */
  #hero-16 { padding-top: 80px; margin-bottom: 50px; }
  .hero-16-img { margin-bottom: -50px }

  /* HERO-17 */
  #hero-17 { padding-top: 80px; }
  #hero-17 .quick-form { margin-left: 0; margin-right:0; }
  #hero-17 #brands-1 { padding: 45px 0 0; } 

  /* HERO-18 */
  #hero-18 { text-align: center; padding-top: 80px; padding-bottom: 70px; }
  #hero-18:after { width: 100%; left: 0; }
  .hero-18-txt p { padding-right: 0; }
  #hero-18 .quick-form { margin-right: 0; }
  .hero-18-img { margin-top: 40px; padding-right: 0; }

  /* HERO-19 */
  #hero-19 { padding-top: 80px; }
  .hero-19-txt p { padding: 0; }

  /* HERO-20 */
  #hero-20 { text-align: center; padding-top: 80px; padding-bottom: 0; }
  #hero-20:after { width: 100%; }
  .hero-20-txt { padding: 0; }

  /* HERO-21 */
  #hero-21 { text-align: center; padding-top: 80px; }
  .hero-21-txt { margin-bottom: 40px; }
  #hero-21 .hero-overlay { padding: 65px 45px 0; }

  /* HERO-22 */
  #hero-22 { padding-top: 80px; }
  #hero-22 .hero-logo { margin-bottom: 20px; }

  /* HERO-23 */
  #hero-23 { padding-top: 80px; margin-bottom: 25px; }
  .hero-23-txt p { padding: 0; }
  .hero-23-img { margin-top: 15px; margin-bottom: -25px; }

  /* HERO-24 */
  #hero-24 { padding-top: 100px; padding-bottom: 100px; }

  /* HERO-25 */
  #hero-25 { text-align: center; padding-top: 80px; padding-bottom: 80px; }
  .hero-25-txt h2 { margin-bottom: 14px; }
  .hero-25-txt p.p-lg { padding-right: 0; margin-bottom: 22px; }

  /* HERO-26 */
  #hero-26 { text-align: center; padding-top: 80px; }
  .hero-26-txt { margin-top: 0; } 
  .hero-26-txt h2 { padding-right: 0; } 
  #hero-26 .quick-form { margin-right: 0; }
  .hero-26-img { margin: 40px 0 0 0; }

  /* HERO-27 */
  #hero-27 .hero-overlay { padding-top: 80px; }
  .hero-27-txt h2 { margin-bottom: 24px!important; }
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { padding: 0; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 0; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }

  .a-2-1 { display: none; margin-left: 0; margin-bottom: 30px; } 
  .a-2-2 { margin-left: 0; margin-bottom: 30px; }
  .a-2-3 { margin-right: 0; margin-bottom: 30px; }
  .a-2-4 { display: none; margin-right: 0; }

  #a3-1 { margin-bottom: 25px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 20px; }
  .txt-block h5.mb-20 { margin-bottom: 15px; }
  #about-4 .txt-block p { margin-top: 18px; }

  #a4-1 { display: none; }
  #a4-2 { padding-left: 0; margin-top: 30px; margin-bottom: 0; }

  /* FEATURES */
  .fbox-14-wrapper .col { padding: 0 12px; }

  #features-12.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; left: 0; }
  #features-12.pt-100.shape--bg:after { width: 100%; height: 66%; top: 30.25%; left: 0; }
  #features-13.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; }
  #features-13.pt-100.shape--bg:after { width: 100%; height: 66%; top: 30.25%; }

  .fbox-12-wrapper { text-align: center; padding-left: 0; }
  .fbox-13-wrapper { text-align: center; padding-right: 0; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5, .row-cols-md-2 .fb-3, .fbox-5.fb-3, 
  .fbox-5.fb-5, .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3, .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, 
  .rows-2 .fbox-9.fb-3, .rows-3 .fbox-11.fb-5 { margin-bottom: 40px; }

  .rows-3 .fb-3.fbox-11-lst { margin-bottom: 40px; }

  .fbox-5.fb-2, .fbox-5.fb-4, .fbox-5.fb-6 { margin-bottom: 0; }

  .fbox-5.fb-1, .fbox-5.fb-5, .fbox-5.fb-6 { display: flex; flex-flow: wrap; }

  .fbox-8.fb-1 { padding-top: 0; margin-bottom: 0; }
  .fbox-8.fb-2 { margin-bottom: 0; }
  .fbox-8.fb-3 { padding-bottom: 0; margin-bottom: 0; } 

  .fbox-9, .fbox-10 { text-align: center; }

  #fb-12-1, #fb-12-2, #fb-12-3, #fb-13-1, #fb-13-2, #fb-13-3 { margin-bottom: 40px; }
  #fb-12-1, #fb-13-1 { margin-top: 75px; }
  #features-12 .txt-block, #features-13 .txt-block { margin-bottom: 40px; }

  .fbox-14 { text-align: center; }
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 35px; }

  .fbox-1 .fbox-img { margin-bottom: 30px; }
  .fbox-2 .fbox-img img { margin-bottom: -20px; } 

  .fbox-5-1 .fbox-5-img, .fbox-5-2 .fbox-5-img, .fbox-5-3 .fbox-5-img, 
  .fbox-5-4 .fbox-5-img, .fbox-5-5 .fbox-5-img, .fbox-5-6 .fbox-5-img { margin: 0 5% 40px; }

  .fbox-ico { margin-bottom: 15px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-rounded-md, 
  .fbox-ico.ico-rounded-lg, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-11 { display: block; text-align: center; }
  .fbox-11 .fbox-ico { margin-top: 0; }
  .fbox-ico-wrap, .rows-2 .fbox-ico-wrap  { margin-right: 0; }

  .fbox-title { display: inline-block; text-align: center; margin: 0 auto 8px; }
  .fbox-wrapper .row-cols-lg-3 .fbox-title { margin-bottom: 0; } 
  .fbox-title h6 { display: inline-block; }
  .fbox-14 h5 { padding-right: 0; margin-bottom: 12px; }

  /* CONTENT */
  .ct-11, .ct-12 { padding: 0; }
  .ct-11 .txt-block { padding-right: 0; }
  .ct-12 .txt-block { padding-left: 0; }
  .ct-top { margin-bottom: 80px; }

  .ct-13 .row.mb-60 { margin-bottom: 0; }
  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 40px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }
  .bc-6-txt { padding: 0; }

  .ct-11:after, .ct-12:after { width: 100%; top: 0; left: 0; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 15px; }
  .txt-block h5 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }

  .txt-block .btn, .txt-block .btns-group .btn.btn--transparent { margin-top: 10px; }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay, 
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 60px 50px; }

  /* Text Block */
  .ct-06 { padding-top: 0!important; padding-bottom: 80px; margin-top: 80px; margin-bottom: 0; }
  .ct-10 .section-overlay { margin-bottom: 80px; }
  .ct-06 .txt-block, .ct-07 .txt-block, .bc-2-txt, .bc-2-wrapper .txt-block, .bc-3-txt { margin-top: 0; }

  #ib-box-1, #ib-box-2 { margin-bottom: 25px; }
  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 30px; }

  .bc-1-wrapper .txt-block.pr-25.left-column, .bc-2-wrapper .txt-block.pr-25.left-column { padding-right: 0; }
  .bc-1-wrapper .txt-block.pl-25.right-column, .bc-2-wrapper .txt-block.pl-25.right-column { padding-left: 0; }

  .bc-6-top { margin-bottom: 40px; }

  /* Tabs */
  .tabs-1 { margin: 0 auto 40px; }
  .tabs-1 li { display: block;font-size: 1.135rem; margin: 0 20px 10px; } 
  .tabs-1 li.current { margin: 0 20px 20px; }
  #tabs-1 .tab-content .img-block { margin-bottom: 40px; }

  /* Advantages */
  .text-center .advantages { margin-top: 16px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote p { padding-left: 14px; margin-top: 15px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Images */
  .img-block.img-block-bkg { margin-left: 0!important; margin-right: 0!important; }
  .img-block-hidden, .ct-10 .img-block, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -80px; }
  .ct-01 .img-block { margin-left: 0; margin-bottom: 40px; }
  .ct-02 .img-block { margin-right: 0; margin-bottom: 40px; }
  .ct-03 .img-block, .ct-05 .img-block, .ct-07 .img-block { margin-bottom: 40px; }
  .ct-03 .img-block.right-column { margin-left: 0; }
  .ct-03 .img-block.left-column { margin-right: 0; }
  .ct-04 .img-block { margin-left: 0; margin-bottom: 40px; }
  .ct-04 .img-block img { max-width: 100%; }
  .ct-06 .img-block { margin-top: -80px; margin-bottom: 40px; }

  .bc-1-wrapper .img-block.left-column, .bc-2-wrapper .img-block.left-column { padding-right: 0; }
  .bc-1-wrapper .img-block.right-column, .bc-2-wrapper .img-block.right-column { padding-left: 0; }

  .bc-1-wrapper .img-block, .bc-2-wrapper .img-block { margin-bottom: 40px; }
  .bc-2-img, .bc-3-img, .bc-4-img { margin: 0 0 40px 0; }
  .bc-5-img.bc-5-tablet.img-block-hidden { margin-bottom: -100px; }

  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 30px); }

  .ct-11 .img-block { margin-bottom: 40px; padding-left: 0; }
  .ct-12 .img-block { margin-bottom: 40px; padding-right: 0; }

  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 50px 15%; border-radius: 12px; overflow: hidden; }
  .ct-11 .img-block .tra-bkg, .ct-12 .img-block .tra-bkg { opacity: 100; -moz-opacity: 100; }

  /* STATISTIC */
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 40%; padding-right: 0; }
  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2 { margin-bottom: 40px; }
  #sb-4-1, #sb-4-2 { margin-bottom: 30px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 40px; }

  #statistic-5 { text-align: center; }
  #statistic-1 .statistic-block { display: block!important; text-align: center; }
  .statistic-1-wrapper .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 15px; }
  .statistic-1-wrapper .statistic-block-txt { width: 100%; display: inline-block!important; padding-left: 0; }
  #statistic-5 .statistic-digit { padding-bottom: 20px; margin: 0 0 20px 0; }

  #sb-2-3 .statistic-block, #sb-2-4 .statistic-block { display: block!important; }
  #sb-2-3 .statistic-block-txt, #sb-2-4 .statistic-block-txt { padding-left: 0; }
  #statistic-2 .statistic-block-digit { display: block!important; }

  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 10px; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 10px; }
  .statistic-3-wrapper p { margin-top: 12px; }
  .statistic-4-wrapper p { margin-bottom: 12px; }
  #statistic-5 .statistic-txt p { padding-right: 0; }

  .txt-block-stat .statistic-block .txt-block-rating { margin-top: -2px; margin-bottom: -1px; }

  /* PROJECTS */
  .projects-wrapper { padding: 0; }
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 40px; }

  /* RATING */
  .rating-section .rating-1-wrapper, .rating-section .rating-2-wrapper { padding: 0; }
  .inner-page-hero .rating-1-wrapper.mt-50 { margin-top: 40px; }

  #rb-1-1, #rb-1-2, #rb-2-1, #rb-2-2 { margin-bottom: 30px; }
  .rbox-2-txt img { max-height: 24px; top: -1px; left: 6px; }
  p.rbox-2-txt { line-height: 24px; margin-top: 8px; }

  /* TESTIMONIALS */
  .reviews-2-wrapper, .reviews-3-wrapper { padding: 0; }
  .reviews-4-wrapper, .reviews-5-wrapper { padding-left: 0; }
  #reviews-4 .txt-block, #reviews-5 .txt-block { margin-bottom: 40px; }

  #rw-2-1, #rw-2-2, #rw-2-3, #rw-3-1, #rw-3-2 { margin-bottom: 30px; }
  #rw-3-2, #rw-3-3 { margin-left: 0; }
  .inner-page-hero #rw-2-3, .inner-page-hero #rw-2-4, .inner-page-hero #rw-2-5 { margin-bottom: 30px; }

  /* INTEGRATIONS */
  .integrations-2-wrapper { padding: 60px 40px 70px; }
  .it-1, .it-2, .it-3, .it-4, .it-5 { display: block; margin-bottom: 30px; }
  .in_tool_logo { display: inline-block; padding: 30px; margin-bottom: 25px; }
  .in_tool_logo.ico-65 img { width: 52px; height: 52px; }
  .in_tool h6 { font-size: 1rem; }

  .integrations-1-wrapper .it-7, .integrations-1-wrapper .it-8 { margin-bottom: 30px; }

  /*.integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 0; }*/

  /* PRESS */
  #press-05, #press-06, #press-07 { margin-bottom: 40px; }

  /* PRICING */
  #pt-4-1, #pt-4-2 { margin: 0 0 40px 0; }

  .pricing-table-header h5, .pricing-table-header h4 { margin-bottom: 15px; }
  .pricing-3-table .pricing-table-header h4 { margin-bottom: 10px; }

  .price p { margin-top: 14px; margin-bottom: 18px; }

  .pricing-features.mt-25 { margin-top: 20px; }

  /* Pricing Notice */
  .pricing-notice { margin-top: 0; }
  .pricing-notice .btn.mt-25 { margin-top: 20px; }

  /* Pricing Toggle Button */
  .pricing-section .toggle-btn.mt-30 { margin-top: 20px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1rem; line-height: 32px; }

  .switch-wrap { min-width: 50px; height: 30px; }
  .toggle-btn-md .switch-wrap, .toggle-btn-lg .switch-wrap { min-width: 55px; height: 32px; margin: 0 6px; }

  .switcher { font-size: 0.85rem; height: 30px; }
  .toggle-btn-md .switcher, .toggle-btn-lg .switcher { height: 32px; padding: 0 10px 0 30px; border-radius: 32px; }

  .switcher .show-annual, .switcher .show-monthly { line-height: 30px; }
  .toggle-btn-md .switcher .show-annual, .toggle-btn-md .switcher .show-monthly,
  .toggle-btn-lg .switcher .show-annual, .toggle-btn-lg .switcher .show-monthly { line-height: 32px; }

  .switcher:before { left: 4px; bottom: 4px; width: 22px; height: 22px; }
  .toggle-btn-md .switcher:before, .toggle-btn-lg .switcher:before { left: 5px; bottom: 5px; width: 22px; height: 22px; }
  .switcher.bg--grey:before, .switcher.bg--tra-white:before, .switcher.bg--ghost-white:before { left: 4px; bottom: 3px; }

  .toggle-btn-md .switch-wrap input:checked + .switcher, 
  .toggle-btn-lg .switch-wrap input:checked + .switcher { padding: 0 30px 0 10px; }

  .comp-table-payment .payment-icons { margin-top: 18px; }

  /* TEAM */
  #tm-2-1, #tm-2-4, #tm-2-7, #tm-2-10 { margin-right: 0; }
  #tm-2-2, #tm-2-5, #tm-2-8, #tm-2-11 { margin-left: 0; margin-right: 0; }
  #tm-2-3, #tm-2-6, #tm-2-9, #tm-2-12 { margin-left: 0; }

  .team-section .more-btn.mt-20 { margin-top: 10px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 40px; }

  .role-info hr { margin: 30px 0; }
  #role-page .cbox-6-wrapper { margin: 30px 0; }
  .role-info h5 { margin-bottom: 15px; }

  /* BANNER */
  #banner-8, #banner-9, #banner-10 { text-align: center; } 
  #banner-11, #banner-12, #banner-13 { margin-top: 0; text-align: center; }

  .banner-11-txt p { padding-right: 0; }

  .banner-8-buttons.text-end { text-align: center!important; }

  .banner-11-img { margin-top: 40px; margin-left: 0; }
  .banner-12-img { margin: 40px 0 0; }
  .banner-13-img { display: none; }

  /* DOWNLOAD */
  #download-1 { padding-bottom: 30px; }
  .version-release { margin-bottom: 40px; }
  .release-data h5 { margin-bottom: 30px!important; }
  .release-data { padding-bottom: 30px; margin-bottom: 25px; } 
  .release-download { position: relative; right: 0; bottom: 0; margin-top: 20px; }

  /*FAQS */
  .faqs-3-questions .questions-holder { padding: 0; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-2 .newsletter-overlay { text-align: center; }
  #newsletter-3 { text-align: center; margin-top: 0; margin-bottom: 0; padding-top: 70px; padding-bottom: 70px; }
  .newsletter-img.text-end { display: none; } 

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form { margin-top: 0; padding-left: 0; }
  .newsletter-section .form-control { text-align: center; margin-right: 0; margin-bottom: 15px; }
  .newsletter-section .input-group-btn { display: block; width: 100%!important; }
  .newsletter-section .btn { display: block; width: 100%!important; margin: 0; }

  /* BLOG */
  #bp-1-1, #bp-1-2 { margin-bottom: 40px; }
  .blog-post-img.mb-35 { margin-bottom: 30px; }

  /* Blog Listing */
  #blog-page.pb-60 { padding-bottom: 30px; }
  .wide-post { margin-bottom: 40px; }
  .wide-post .blog-post-img { padding-right: 0; margin-bottom: 30px; }
  .wide-post .blog-post-txt { padding-left: 0; }

  .square-post { margin-top: 10px; margin-bottom: 50px; }
  .square-post .col-md-12 { padding: 0; }
  .square-post .blog-post-txt { padding: 45px 35px 35px; }

  .posts-category { margin-top: 10px; margin-bottom: 40px; }

  /* Single Blog Post */
  .post-inner-img { margin-top: 40px; margin-bottom: 40px; }

  .single-post-txt h5, .single-post-txt h4 { margin-top: 18px; margin-bottom: 18px; }

  .txt-code { padding: 18px 15px; margin: 20px 0; }

  .single-post-txt figure { margin-bottom: 20px; }

  .comment-body { margin-left: 20px; }

  .comment-form .form-control { height: 52px; }
  .comment-form textarea { min-height: 190px; }

  /* CONTACTS */
  .contact-form textarea { min-height: 190px; }
  .contact-form .form-btn.mt-15 { margin-top: 0; }

  /* FOOTER */
  #footer-1 .footer-info .footer-mail-link { margin-top: 15px; }
  #footer-2 .footer-links .footer-mail-link, #footer-3 .footer-links .footer-mail-link { margin-bottom: 15px; }
  #footer-11 { text-align: center; }

  #footer-9 .footer-info.text-end { text-align: left!important; }

  #footer-2 .footer-links, #footer-7 .footer-form { padding-left: 0; }

  #footer-8 .footer-links.text-end, .footer-stores-badge.text-end, .bottom-footer .bottom-footer-socials.text-end, 
  .bottom-footer .bottom-secondary-link.text-end, .bottom-footer .bottom-footer-list.text-end { text-align: left!important; }
  #footer-11 .bottom-footer-list.text-end { text-align: center!important; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero, .page-hero-section-overlay { padding-top: 80px; margin-top: 80px; margin-bottom: 40px; }
  .page-hero-section-overlay .txt-block { margin-top: 0; margin-bottom: 40px; }

  .inner-page-title { margin-bottom: 60px; }
  .inner-page-title p { line-height: 1.6666; margin-top: 15px; margin-bottom: 0; }

  .page-hero-section-overlay .img-block { margin-bottom: -50px; }

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin-top: 20px; margin-bottom: 20px; }
  .txt-block.legal-info h4 { margin-top: 40px; margin-bottom: 20px; }
  .txt-block.legal-info h4 span, .txt-block.legal-info h5 span { margin-right: 2px; }

  /* SIGN IN / SIGN UP */
  #login, #signup, #reset-password { padding-top: 70px; padding-bottom: 70px; }
  #login .register-page-wrapper:after, #signup .register-page-wrapper:after { width: 100%; left: 0; }

  .register-form-title { margin-bottom: 30px; }
  #login .register-page-txt, #signup .register-page-txt { display: none; }

  /* PAGE 404 */
  .page-404-txt { margin-top: 80px; padding-top: 70px; padding-bottom: 70px; }
  

}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 480px) and (max-width: 767.98px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6%; }
  .shape--02:after { width: 140%; height: 92%; top: 5.5%; left: -20%; }
  .shape--03:after { height: 128%; top: -65px; }
  .shape--04:after { height: 90%; top: 0; }
  .shape--07:after { height: 80%; top: 15%; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                /* 17px */
  .s-17 { font-size: 1.125rem; }                        /* 18px */
  .s-18, .s-19, .s-20, .s-21, .s-22 { font-size: 1.4375rem; }  /* 23px */
  .s-24, .s-26, .s-28 { font-size: 1.625rem; }          /* 26px */
  .s-30, .s-32, .s-34 { font-size: 1.81255rem; }        /* 29px */
  .s-36, .s-38, .s-40 { font-size: 2.0625rem; }         /* 33px */  
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47, .s-48 { font-size: 2.25rem; }  /* 36px */
  .s-50, .s-52, .s-54, .s-56 { font-size: 2.375rem; }   /* 38px */ 
  .s-58 { font-size: 2.5rem; }                          /* 40px */
  .s-60, .s-62 { font-size: 2.625rem; }                 /* 42px */

  .s-64, .s-66 { font-size: 2.75rem; }                  /* 44px */
  .s-68 { font-size: 2.875rem; }                        /* 46px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; } 
  p { font-size: 1.0625rem; } 
  p.p-md, p.p-lg { font-size: 1.125rem; }
  p.p-xl { font-size: 1.1875rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1.125rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.3845rem 0.5rem; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 3px; }
  .btn.ico-right span { margin-left: 3px; }

  /* Button Text */
  p.btn-txt, p.btns-group-txt, .btn-rating { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .star-rating span.rating-txt { font-size: 1.0625rem; }

   /* Store Badge */
  .store-btn-4 { margin-top: 15px; }

  /* OS Button */
  .os-btn { width: 280px; min-width: 280px; padding: 14px 15px 12px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 0; margin-bottom: 15px; }

  /* Watch Link */
  .watch-video { margin-top: 22px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1.0625rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 125px; height: 125px; top: -35px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 135px; height: 135px; top: -30px; left: calc(50% - 70px); }
  .ico-60 .shape-ico svg { width: 140px; height: 140px; top: -35px; left: calc(50% - 65px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after, 
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after, 
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* Vector Square Icons */
  .ico-square { width: 88px; height: 88px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 88px!important; }

  /* Section ID */
  .section-id { margin-bottom: 25px; }

  /* Section Title */
  .section-title p.p-md, .section-title p.p-lg, .section-title p.p-xl { margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.21875rem; margin-top: 12px; }  /* 19.5px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-25 { margin-top: 20px; }
  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 25px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 23px; padding-left: 18px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 34px; }

  .wsmenu > .wsmenu-list { width: 345px; margin-right: -345px; }
  .wsactive .wsmobileheader { margin-right: 345px; }
  .overlapblackbg { width: calc(100% - 345px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 18px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 12px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 46px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after {line-height: 52px!important;} 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-ico { margin-right: 18px; }
  .icons-menu-txt span { font-size: 1rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 25px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.375rem; margin-bottom: 22px; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 34px; }
  .hero-square-logo img { max-height: 65px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 32px; }
  .hero-app-logo img { max-height: 100px; }
  #hero-25 .hero-app-logo img { max-height: 100px; }

  /* HERO DIGITS */
  .hero-digits { padding: 0 10%; }
  .hero-digits h2.statistic-number { font-size: 2.75rem; }
  .hero-digits h2.statistic-number span { font-size: 2.25rem; }
  #hero-9 .hero-digits { padding: 0; }

  /* HERO QUICK FORM */
  .hero-section .quick-form.mt-45 { margin-top: 0; }
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.175rem; height: 60px; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -5px 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 56px; font-size: 1.0625rem; padding: 0 14px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 56px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-1 */
  .hero-1-txt, .hero-2-txt { padding: 0 3%; }

  /* HERO-3 */
  .hero-3-txt { padding: 0 4%; }

  /* HERO-4 */
  #hero-4:after { height: 8%; }
  .hero-4-img { margin-top: 40px; }

  /* HERO-5 */
  #hero-5 { margin-bottom: 50px; }
  .hero-5-txt {padding: 0 5%; }

  /* HERO-6 */
  .hero-6-wrapper { padding: 60px 50px; }

  /* HERO-7 */
  #hero-7 .quick-form { margin: 25px 4% 0; }

  /* HERO-8 */
  .hero-8-txt .hero-logo { display: none; }
  .hero-8-txt .hero-digits { padding: 0 10%; }

  #hero-8-form { padding: 40px 30px; margin: 40px 5% 0; border-radius: 10px; }
  #hero-8-form h4 { margin-bottom: 15px; }
  #hero-8-form p { padding: 0 3%; margin-bottom: 20px; }
  #hero-8-form p.p-sm { padding: 0 5%!important; }

  /* HERO-9 */
  .hero-9-txt h2 { padding: 0 3%; }
  .hero-9-txt h2 img { max-height: 66px; margin: 0 2px; top: -5px; }
  .hero-9-txt p { padding: 0 4%; margin-top: 28px; }
  .hero-9-txt p.s-20 { font-size: 1.175rem; }

  #hero-9 .quick-form { margin-left: 4%; margin-right: 4%; }
  .hero-9-img { margin: 45px 0 0; }

  /* HERO-10 */
  #hero-10 { padding-bottom: 0; }
  .hero-10-txt h2 { padding: 0 8%; }
  .hero-10-txt h4 { padding: 0 5%; margin-bottom: 30px; }

  /* HERO-11 */
  .hero-11-txt { padding: 0 3%; }
  .hero-11-img { margin: 40px 0 -80px; }

  /* HERO-12 */
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  .hero-13-txt { padding: 0 10%; }

  /* HERO-14 */
  .hero-14-txt p.s-21 { padding: 0 3%; margin-bottom: 25px; }
  #hero-14 .quick-form { margin-left: 4%; margin-right: 4%; }
  .hero-14-img { margin-top: 40px; }
  .hero-14-txt .btn-txt { margin-top: 25px; }

  /* HERO-15 */
  .hero-15-txt h2 { padding: 0 5%; }

  /* HERO-16 */
  #hero-16 { margin-bottom: 40px; }
  .hero-16-txt h2 { padding: 0 4%; }
  .hero-16-img { margin: 40px 0 -40px; }

  /* HERO-17 */
  .hero-17-txt p { padding: 0 2%; }
  #hero-17 .quick-form { margin-left: 4%; margin-right: 4%; }
  #hero-17 #brands-1 { padding-top: 35px; } 

  /* HERO-18 */
  .hero-18-txt { padding: 0 3%; }
  #hero-18 .quick-form { margin: 25px 4% 0; }

  /* HERO-19 */
  .hero-19-txt h2 { padding: 0 5%; }

  /* HERO-20 */
  .hero-20-txt .hero-logo { display: none; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 20px; }

  /* HERO-21 */
  .hero-21-txt h2 { margin-bottom: 20px; }
  #hero-21 .hero-overlay { padding: 55px 35px 0; }
  .hero-21-wrapper { border-radius: 12px; }
  .hero-21-img { margin-bottom: -18%; }
  .hero-21-img .video-btn { top: calc(50% - 30px); }

  /* HERO-22 */
  #hero-22-form { text-align: center; padding: 45px 40px 35px; margin: 0 3%; }
  #hero-22 .hero-logo { display: none; }
  #hero-22-form h4 { font-size: 2rem; padding: 0; margin-bottom: 16px; }
  #hero-22-form p { font-size: 1.175rem; margin-bottom: 25px; padding: 0 3%; }

  /* HERO-24 */
  #hero-24 { padding-top: 80px; padding-bottom: 80px; }
  #hero-24 .quick-form { margin-left: 4%; margin-right: 4%; }

  /* HERO-25 */
  .hero-25-txt { padding: 0 10%; }
  .hero-25-img { margin: 40px 5% 0; }

  /* HERO-26 */
  .hero-26-txt { padding: 0 5%; } 

  /* HERO-27 */
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { font-size: 1.285rem; padding: 0 2%; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  #about-2 .container-fluid { max-width: 540px; }
  .a2-txt { padding: 20px 30px 40px; }

  .a2-txt-quote { position: relative; margin-bottom: -40px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.125rem; }
  .a2-txt-author span { font-size: 0.975rem; margin-top: 1px; }

  #about-4 .txt-block h2 { padding: 0 10%; }

  /* FEATURES */
  .fbox-14-wrapper { padding: 70px 50px; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4 { padding: 45px 65px 40px; }
  .fbox-5 { padding: 50px 50px 45px; } 
  .fbox-7, .row-cols-md-2 .fbox-7 { padding: 45px 50px 40px; }
  .fbox-8.fb-1 { padding: 0 75px 50px; }
  .fbox-8.bg--white-100 {padding: 45px 75px; }
  .fbox-8.fb-3 { padding: 55px 75px 0; }
  .fbox-9 { padding: 40px 75px 35px; } 

  .fbox-12, .fbox-13 { padding: 40px 50px; } 

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 220px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 46px; margin: 0 10% 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 46px; margin: 0 10% 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 10% 30px; }

  .fbox-ico { margin-bottom: 18px; }
  .row-cols-md-2 .fbox-ico.ico-rounded, .fbox-ico.ico-rounded-md, 
  .fbox-ico.ico-rounded-lg, .fbox-ico.ico-square { margin-bottom: 22px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after { font-size: 4rem; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.625rem; }  /* 26px */
  .features-section h6, .fbox-txt h5 { margin-bottom: 15px; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 14% }

  .fbox-1 .fbox-txt p, .fbox-10 .fbox-txt p { padding: 0 5%; }
  .fbox-2 .fbox-txt p { padding: 0 6%; }
  .rows-2 .fbox-11 p { padding: 0 8%; } 
  .fbox-6 .fbox-txt p { padding: 0 5%; }
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0; }
  .rows-3 .fbox-11 p { padding: 0 2%; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 50px; }

  /* Content Box Wrapper */
  .bc-1-wrapper .section-overlay, .bc-2-wrapper .section-overlay, 
  .bc-3-wrapper .section-overlay, .bc-4-wrapper .section-overlay, .bc-6-wrapper .section-overlay { padding: 70px 50px; }
  .bc-5-wrapper .section-overlay { padding: 70px 40px 0; }
  .cbox-6-wrapper { padding: 45px 35px; }

  .bc-6 { padding: 35px 30px 40px; }
  
  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 20px; }
  .txt-block h5 { margin-bottom: 18px; }
  h5.h5-title { margin-top: 18px; margin-bottom: 18px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.3125rem; }

  .txt-block .btn { margin-top: 18px; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; }
  .txt-block-tra-link.mt-25 { margin-top: 18px; }

  .box-title h6, .box-title h5 { margin-bottom: 20px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Content Box */
  .ct-03 .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.0625rem; line-height: 1.95rem; }

  .right-column .cbox-5 { margin-bottom: 60px; }
  .cbox-5-content { padding: 35px; margin: 0 ; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .cbox-5-fea { margin-top: 15px; }
  .txt-block .cbox-5-title h2 { font-size: 3.25rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.75rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .cbox-5-txt .btn { margin-top: 10px; }

  .cbox-3 .cbox-3-ico { top: 4px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { top: 0; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-item .accordion-thumb:after, 
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after {top: 14px; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 15px; }
  .payment-icons img { max-height: 32px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.125rem; line-height: 2.5rem!important; margin-right: 5px; 
  }

  /* Content Box Shapes */
  .cbox-5-shape-1 { right: -50px; top: -30px; }
  .cbox-5-shape-2 { left: -30px; bottom: -25px; }
  .cbox-5-shape-1 img, .cbox-5-shape-2 img { height: 80%; width: 80%; }

  /* Content Images */
  .ct-10 .img-block, .img-block-hidden, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -50px; }
  .ct-01 .img-block, .ct-02 .img-block, 
  .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin-left: 3%; margin-right: 3%; }

  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .img-block-txt, .img-block-btn { margin-top: 45px; }

  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 50px 10%; }
  .bc-6-img { padding: 35px 30px 0; margin-bottom: 25px; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 20px; margin: 0 12% 20px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 2.5rem; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2.35rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2.5rem; letter-spacing: -0.5px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.5rem; letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }

  #statistic-5 .statistic-txt h5 { margin-bottom: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  #statistic-5 .statistic-txt p { padding: 0 5%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 50px; }
  .project-title h2 { padding-bottom: 35px; margin-bottom: 30px; }
  .project-data p { margin-bottom: 10px; }

  .project-priview-img.mb-50 { margin-bottom: 40px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 25px; margin-bottom: 25px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 40px; margin-bottom: 25px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 5px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.2125rem; } 

  /* RATING */
  .rating-title h5 { font-size: 1.1875rem; }
  .rbox-2-txt img { max-height: 34px; }
  p.rbox-2-txt { line-height: 30px; margin-top: 8px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  .reviews-section .col-md-8 .section-title h2 { padding: 0 5%; }

  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; padding: 0 10%; }
  .review-1 { padding: 35px 32px 30px; }
  .review-2, .review-3, .review-4, .review-5 { padding: 35px 35px 30px; }

  .review-txt { margin-top: -43px; }
  .review-txt h6 { margin-bottom: 10px; }

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.25rem; }

  /* BRANDS */
  .brands-title { padding: 0; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 30px; }
  .brands-title.mb-50 { margin-bottom: 40px; }

  .brands-title h5.s-19 { font-size: 1.25rem; }
  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }

  .brands-3-wrapper { padding: 35px 25px; }
  .brands-3-wrapper.brands-3-static { padding: 35px 30px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-4-6, #brands-4 .brand-logo.mb-40 { margin-bottom: 35px; }
  #brand-3-1, #brand-3-2 { margin-bottom: 25px; }
  #brand-4-10 { display: none; }

  #brands-1 .brands-carousel-5 .brand-logo, #brands-1 .justify-content-center .brand-logo { padding: 0 20px; }   
  #brands-2 .brand-logo { padding: 0 10px; } 
  #brands-3 .brand-logo { padding: 0 15px; } 
  #brands-3 .brands-3-static .brand-logo { padding: 0 8px; } 
  #brands-4 .brand-logo { padding: 0 15px; } 

  .brands-section .brand-logo.mb-50 { margin-bottom: 35px; }

  .brands-section .more-btn { margin-top: 40px; }

  /* INTEGRATIONS */
  #integrations-1 .section-title h2 { padding: 0 5%; }

  .integrations-1-wrapper .in_tool { padding: 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.4rem; line-height: 1; margin-top: 4px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 6px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }

  /* PRICING */
  .pricing-1-table, .text-center .pricing-1-table { padding: 35px 30px 40px; }
  .pricing-2-table { padding: 35px 30px 40px; }
  .pricing-3-table { padding: 35px 25px; }

  .pricing-table-header h5, .pricing-table-header h4 { font-size: 1.6875rem!important; }
  .p-table p.btn-txt { margin: 12px 0 0 0; }

  /* Price */
  .price span { font-size: 2.875rem; letter-spacing: -1.5px; } 
  .pricing-2-table.highlight .price span { font-size: 2.65rem; } 
  .pricing-3-table .price span { font-size: 4.5rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 2.875rem; } 

  .price sup { font-size: 2.15rem; top: -4px; }
  .pricing-3-table .price sup { font-size: 2.5rem; top: -20px; }
  .pricing-4-table .price sup { font-size: 2.15rem; top: -6px; right: 2px; }

  .pricing-3-table .price sup.coins { font-size: 2.5rem; top: -24px; letter-spacing: -2px; }

  .price sup.validity { font-size: 1.4375rem; }
  .pricing-3-table .price sup.validity { font-size: 1.4375rem; top: 0; margin-top: 20px; margin-bottom: 35px; }
  .pricing-4-table .price sup.validity { font-size: 1.4375rem; left: 4px; }

  .text-center .price p, .text-center .pricing-2-table .price p { padding: 0; }

  .pricing-2-table hr { margin-top: 20px; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 18px; }
  .pricing-features li, .pricing-4-table .pricing-features li { padding: 10px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  #pricing-4 .ext-toggle-btn { margin: 0 auto 30px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 35px; }
  .table>:not(caption)>*>* { padding-top: 9px; padding-bottom: 9px; }
  .comp-table .table-responsive thead th { font-size: 0.975rem; }
  .comp-table .table-responsive tbody th { font-size: 0.95rem; }
  .comp-table .table-responsive tbody td { font-size: 0.915rem; top: 0; }

  .comp-table .table-responsive tbody td span { top: 1px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.725rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.875rem; }

  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* TEAM */
  .team-member.mb-40, .team-member.mb-50, .team-member.mb-60 { margin-bottom: 40px; }
  .team-member-photo { margin-bottom: 20px; }
  #team-2 .team-member-data { margin-left: 5px; }

  .team-member-data h6.s-20 { margin-bottom: 6px; }
  .team-member-data p { font-size: 1.15rem; }
  ul.tm-social { top: 6px; right: 5px; }

  /* CAREERS */
  #careers-1 { padding-bottom: 40px; }
  .role-box { text-align: center; padding: 30px; }
  .role-box-txt span, #careers-1 .role-box span { margin-bottom: 12px; }
  .role-box-txt h6 { line-height: 1.4; margin-bottom: 5px; }
  #careers-1 .role-box h6 { line-height: 1.4; margin-bottom: 4px; }
  .role-box-txt p { margin-bottom: 18px; }

  /* CAREER ROLE */ 
  #role-page .inner-page-title { margin-bottom: 25px; }
  #role-page .inner-page-title span { margin-bottom: 20px; }
  .role-info hr, #role-page .cbox-6-wrapper { margin: 35px 0; }
  .txt-block.role-info .btn { margin-top: 35px; }

  /* BANNER */
  #banner-12, #banner-13 { margin-top: 0; margin-bottom: 15px; }
  #banner-9.py-70 { padding-top: 55px; padding-bottom: 65px; }  

  #banner-1 .container, #banner-2 .container, #banner-3 .container,
  #banner-11 .container, #banner-12 .container, #banner-13 .container { max-width: 100%; }
  .banner-1-wrapper, .banner-2-wrapper, .banner-3-wrapper,
  .banner-11-wrapper, .banner-12-wrapper, .banner-13-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .banner-1-wrapper .banner-overlay { padding: 55px 60px 65px; }
  .banner-2-wrapper .banner-overlay, .banner-3-wrapper .banner-overlay { padding: 55px 60px 65px; }

  #banner-5 .banner-overlay.pt-80.pb-90, #banner-7 .banner-overlay.pt-80.pb-90, #banner-6 .banner-overlay, 
  #banner-8 .banner-overlay, #banner-14 .banner-overlay, #banner-15 .banner-overlay { padding-top: 55px; padding-bottom: 65px; }

  #banner-10 .banner-overlay { padding-top: 60px; padding-bottom: 65px; }
  .banner-11-wrapper .banner-overlay { overflow: hidden; padding: 60px 60px 0; border-radius: 0 0!important; } 
  .banner-12-wrapper .banner-overlay { overflow: visible; padding: 60px 60px 0; border-radius: 0 0!important; } 
  .banner-13-wrapper .banner-overlay { overflow: visible; padding: 60px 60px 70px; border-radius: 0 0!important; } 
  .banner-16-wrapper .banner-overlay { padding: 40px 6%; }

  .col-md-8 .banner-5-txt {padding: 0 3%; }
  .banner-9-txt { padding: 0 14%; }
  .banner-12-txt, .banner-13-txt { margin-top: 0; padding: 0 3%; }

  .banner-section .section-id { margin-bottom: 20px; }
  #banner-15.banner-section .section-id { margin-bottom: 24px; }

  .banner-1-txt h2 { margin-bottom: 12px; }
  .banner-2-txt h2 { margin-bottom: 25px; }
  .banner-4-txt h2 { margin-bottom: 24px; }
  .banner-7-txt h2 { margin-bottom: 25px; }
  .banner-10-txt h2 { margin-bottom: 22px; }
  .banner-11-txt h2 { margin-bottom: 14px; }
  .banner-12-txt h2, .banner-13-txt h2 { margin-bottom: 14px; }
  .banner-15-txt h2 { margin-bottom: 30px; }
  .banner-16-txt h4 { margin-bottom: 14px; }
  
  .banner-section p.p-lg, .banner-section p.p-xl { margin-bottom: 20px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl,
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 22px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl, .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 12px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 12px;margin-bottom: 18px; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 15px; margin-bottom: 22px!important; }
  .banner-11-txt p { padding-right: 0; }
  .banner-14-txt p.p-lg { font-size: 1.125rem; margin-top: 20px; margin-bottom: 25px; }
  
  .banner-5-txt .btns-group.mt-35 { margin-top: 25px; }
  .banner-8-buttons.mt-20 { margin-top: 22px; }
  .banner-15-txt .download-links { margin-top: 20px; }
  .banner-section .btns-group .btn.mr-15 { margin-right: 8px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 14px; }

  .banner-9-img, .banner-10-img { margin: 40px 10% 0; } 
  .banner-11-img { margin: 40px 5% -30px; }
  .banner-12-img { transform: rotate(0deg); margin: 35px 0 -40px; }
  
  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 50px; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 20px; }
  #faqs-2 .inner-page-title { margin-bottom: 30px; }
  #faqs-3 .section-title { margin-bottom: 40px; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 20px 0 22px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 20px; right: 2px; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 22px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-2 .accordion-panel-item.mb-35, #faqs-3 .question.mb-35 { margin-bottom: 25px; }

  .question h5, .accordion-panel-item h5 { margin-bottom: 15px; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 { margin: 0 auto 20px; }
  .faqs-section .tabs-1 li { min-width: 240px; margin: 0 6px 20px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 45px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 15px; }
  .more-questions-txt { padding: 18px 42px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 50px; padding-bottom: 40px; }  
  #newsletter-2 .newsletter-overlay { padding: 50px 3% 40px; }
  #newsletter-3, #newsletter-4 .newsletter-overlay { padding-top: 55px; padding-bottom: 60px; }

  #newsletter-3 .newsletter-txt, #newsletter-4 .newsletter-txt, #newsletter-5 .newsletter-txt { padding: 0 8%; }

  .newsletter-txt h2, #newsletter-4 .newsletter-txt h2 { margin-bottom: 30px; }
  .newsletter-txt h4, .newsletter-txt h3 { font-size: 2.0625rem; padding: 0 5%; margin-bottom: 30px; }

  #newsletter-4 .newsletter-txt p { font-size: 1rem; padding: 0; margin-bottom: 0; }
  #newsletter-5 .newsletter-txt p { margin-top: 15px; }

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form { padding: 0 10%; }
  #newsletter-3 .newsletter-form, #newsletter-4 .newsletter-form { padding: 0 2%; }
  #newsletter-5 .newsletter-form { margin-top: 20px; padding: 0; }

  .newsletter-section .form-control, #newsletter-1.newsletter-section .form-control, 
  #newsletter-5.newsletter-section .form-control { height: 54px; font-size: 1.0625rem; }
  .newsletter-section .btn, #newsletter-1 .btn, #newsletter-5.newsletter-section .btn { height: 54px; font-size: 1.0625rem; }

  /* BLOG */
  .wide-post h3.s-36, .wide-post h3.s-38, .wide-post h3.s-40, .square-post h4.s-34 { font-size: 1.4375rem; }
  .square-post .blog-post-txt { padding: 45px 35px; }

  .blog-post-txt h6, .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 18px; }
  .blog-post-meta.mt-20, .blog-post-meta.mt-30 { margin-top: 18px; }

  .posts-category span { top: 1px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; } 
  
  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 5%; margin-bottom: 20px; }
  .single-post-title .blog-post-meta.mt-35 { margin-top: 20px; }
  .single-post-txt h5, .single-post-txt h4 { margin-top: 15px; margin-bottom: 18px; }

  .single-post-txt .blockquote p { font-size: 1.1875rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1.0625rem; }

  .post-share-list { margin-top: 30px; padding-bottom: 40px; }

  .post-author { padding-top: 60px; padding-bottom: 60px; }
  .post-author-avatar { margin-right: 14px; }
  .post-author-avatar img { width: 70px; height: 70px; }
  .post-author-txt h5 { margin-bottom: 15px; }

  .author-follow-btn { padding: 5px 12px; top: 60px; right: 10px; }

  .post-comments { padding-top: 50px; }
  .post-comments h5 { margin-bottom: 40px; }
  .post-comments img { width: 52px; height: 52px; }
  .comment-body { margin-left: 20px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .post-comments #leave-comment h5 { margin-top: 50px; }

  .comment-form { margin-top: 50px; }
  .comment-form .btn { margin-top: 0; }

  /* CONTACTS */
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }

  #contacts-1 .contact-form .col-md-12 span { margin-bottom: 15px; }
  .contact-form .form-control, .contact-form .form-select { height: 54px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 15px; margin-bottom: 30px; }

  #footer-10.pt-50 { padding-top: 5px; }
  #footer-11.p-404.pt-50 { padding-top: 40px; padding-bottom: 40px; }

  .footer-info, .footer-links, .footer-stores-badge {margin-bottom: 35px; }

  #footer-2 .footer-links.fl-3 { margin-top: 20px; }
  #footer-6 .footer-info, #footer-7 .footer-info { margin-top: 15px; margin-bottom: 0; }
  #footer-10 .footer-info { margin-bottom: 30px; padding-bottom: 45px; }

  #footer-8 .footer-links, #footer-10 .footer-links { margin-bottom: 10px; }
  #footer-4 .footer-stores-badge { margin-top: 15px; }

  img.footer-logo, img.footer-logo-dark { max-height: 38px; }
  #footer-1 .footer-logo, #footer-5 .footer-logo, #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 24px; }
  #footer-2 .footer-logo, #footer-3 .footer-logo { margin-bottom: 30px; }
  #footer-10 img.footer-logo { max-height: 38px; }

  .footer h6 { font-size: 1.21875rem; margin-bottom: 20px; } /* 19.5px */

  .footer-mail-link { font-size: 1.125rem; }
  .footer-mail-link span { top: 3px; right: 4px; }
  #footer-1 .footer-info .footer-mail-link { margin-top: 5px; }

  #footer-8 .foo-links li { display: block!important; padding-left: 0; }
  #footer-10 .foo-links li { padding: 0 8px; }

  .foo-links li a span.ext-link { left: 3px; top: 1px; }
  .foo-links.ico-10 li span { top: 1px; right: 3px; }

  .footer-stores-badge .store { margin-right: 10px; }

  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3925rem; }

  #footer-9 .footer-form { padding-right: 15%; margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.0625rem; height: 32px; }
  .footer-form .btn { height: 32px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 26px!important; }

  .bottom-footer-socials { margin-top: 10px; }
  .bottom-footer-socials li { padding-left: 12px; }
  .bottom-footer-socials li:first-child { padding-left: 0; }

  .bottom-footer-list { margin-top: 5px; } 

  .footer-list-divider { top: -1px; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { font-size: 1.15rem; margin-top: 10px; }
  .page-hero-section h1 { line-height: 1.25; margin-bottom: 20px; }

  /* SIGN IN / SIGN UP */
  .register-form-title h3 { font-size: 2.0625rem; }

  .separator-line, .register-page-form p.input-header, .reset-password-link p, 
  .form-data span, .register-page-form p.create-account, .register-page-form .btn-google { font-size: 1rem; }
  .reset-password-link p { margin-top: 0; margin-bottom: 10px; }

  .register-page-wrapper { margin: 0 25px; width: auto; }
  .reset-page-wrapper { margin: 0 45px; }

  #login .register-page-form, #signup .register-page-form { padding: 50px 40px; }
  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 10px 40px; }

  .reset-form-title { margin-bottom: 25px; }
  .reset-form-title p.p-sm { font-size: 1.0625rem; margin-top: 20px; }

  .reset-page-wrapper .form-control, .register-page-form .form-control, #login .register-page-form .btn.submit, 
  #signup .register-page-form .btn.submit, .reset-page-wrapper .btn.submit { height: 54px; font-size: 1.0625rem; }
  .btn-google img { width: 20px; height: 20px; top: -2px; right: 5px; }

  /* PAGE 404 */
  .page-404-txt { padding-top: 100px; }
  .page-404-txt h2 { margin-bottom: 15px; }
  .page-404-txt h2 { margin-bottom: 15px; }
  .page-404-txt h6 { font-size: 1.375rem; padding: 0 3%; line-height: 1.666; margin-bottom: 25px; }
  .page-404-img { padding: 0 8%; margin-bottom: 40px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 490px; }
  #modal-2 .modal-dialog { width: 390px; }
  #modal-3 .modal-dialog.modal-xl { width: 400px; }

  #modal-2 .modal-body-content { padding: 25px 30px 5px; }
  #modal-3 .modal-body-content { padding: 40px 25px 30px; }

  .modal-body .newsletter-form { margin-top: 20px; }
  .modal .newsletter-form .form-control, .modal .newsletter-form .btn { height: 54px; font-size: 1.0625rem; }

  #modal-3 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 414px) and (max-width: 479.98px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .division { padding-left: 10px; padding-right: 10px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6.5%; }
  .shape--02:after { width: 140%; height: 90%; top: 6.5%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 94%; top: 0; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 14%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                              /* 17px */
  .s-17 { font-size: 1.125rem; }                                      /* 18px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.3125rem; }  /* 21px */
  .s-24, .s-26, .s-28 { font-size: 1.4375rem; }                       /* 23px */
  .s-30, .s-32, .s-34 { font-size: 1.5625rem; }                       /* 25px */
  .s-36, .s-38, .s-40 { font-size: 1.6875rem; }                       /* 27px */
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.8125rem; }  /* 29px */
  .s-48 { font-size: 1.9375rem; }                                     /* 31px */
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 2.0625rem; }  /* 33px */
  .s-62 { font-size: 2.125rem; }                                      /* 34px */
  .s-64, .s-66 { font-size: 2.25rem; }                                /* 36px */
  .s-68 { font-size: 2.375rem; }                                      /* 38px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; } 
  p { font-size: 1.0625rem; } 
  p.p-md, p.p-lg { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1.125rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.68165rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 8px; margin-right: 8px; margin-bottom: 15px; }
  .btns-group .btn:last-child { margin-left: 8px; margin-right: 8px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 3px; }
  .btn.ico-right span { margin-left: 3px; }

  /* Button Text */
  p.btn-txt, .text-center p.btns-group-txt { margin-top: 18px; margin-bottom: 0; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }

  /* OS Button */
  .os-btn { width: 280px; min-width: 280px; padding: 14px 15px 10px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 0; margin-bottom: 15px; }

  /* Store Badge */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 15px; }

  /* Watch Video Link */
  .watch-video { margin-top: 20px; }
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1.0625rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 120px; height: 120px; top: -35px; left: calc(50% - 60px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -35px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 125px; height: 125px; top: -30px; left: calc(50% - 65px); }
  .ico-60 .shape-ico svg { width: 130px; height: 130px; top: -35px; left: calc(50% - 60px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after,
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* Vector Square Icons */
  .ico-square { width: 88px; height: 88px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 88px!important; }

  /* Section ID */
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 35px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 45px; }

  .section-title h2 { padding: 0 1%; }
  .content-section .col-md-8 .section-title h2 { padding: 0 1.5%; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 14px; }
  .section-title p.p-xl { font-size: 1.125rem; margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.1875rem; padding: 0 3%; margin-top: 12px; }  /* 19px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35 { margin-top: 20px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 23px; padding-left: 16px; }
  .blog-header .wsmobileheader .smllogo { margin-top: 29px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 34px; }
  .blog-header .smllogo img { max-height: 28px; }

  .wsmenu > .wsmenu-list { width: 325px; margin-right: -325px; }
  .wsactive .wsmobileheader { margin-right: 325px; }
  .overlapblackbg { width: calc(100% - 325px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 16px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 12px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 45px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-ico { margin-right: 18px; }
  .icons-menu-txt span { font-size: 1rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .18; }

  .hero-section h2 { margin-bottom: 18px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.3125rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 4px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo, .hero-app-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 34px; }

  .hero-square-logo { margin-bottom: 12px; }
  .hero-square-logo img { max-height: 64px; }

  .hero-app-logo img { max-height: 96px; }
  #hero-25 .hero-app-logo img { max-height: 96px; }

  /* HERO DIGITS */
  .hero-digits { padding: 0 3%; margin-top: -5px; }
  #hero-9 .hero-digits { padding: 0; margin-top: 3px; }
  .hero-digits h2.statistic-number { font-size: 2.5rem; }
  .hero-digits h2.statistic-number span { font-size: 2.25rem; }

  /* HERO QUICK FORM */
  .hero-section .quick-form.mt-45, .hero-section .quick-form.mt-35 { margin-top: 0; }
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.175rem; height: 58px; }
  .hero-section .quick-form.form-half .form-control { height: 58px; font-size: 1.175rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 58px; font-size: 1.15rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 0 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 56px; font-size: 1.0625rem; padding: 0 14px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 56px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-1 */
  .hero-1-txt { padding: 0 4%; }
  .hero-1-img { margin-top: 35px; }

  /* HERO-2 */
  .hero-2-txt { padding: 0 3%; }
  .hero-2-img { margin-top: 40px; }

  /* HERO-3 */
  .hero-3-txt { padding: 0 3%; }
  #hero-3:after { height: 8%; }
  .hero-3-img { margin-top: 35px; }

  /* HERO-4 */
  #hero-4 { padding-top: 70px; }
  #hero-4:after { height: 7%; }
  .hero-4-txt h2 { padding: 0 3%; }
  .font--inter .hero-4-txt h2 { padding: 0 5.5%; }
  .hero-4-txt p { padding: 0 4%; }
  .hero-4-img { margin-top: 40px; }

  /* HERO-5 */
  #hero-5 { margin-bottom: 50px; }
  .hero-5-txt h2 { margin-bottom: 15px; }
  .hero-5-txt p.p-lg { margin-bottom: 20px; }

  /* HERO-6 */
  #hero-6 { padding-top: 0; }
  #hero-6 .container { max-width: 100%; }
  .hero-6-wrapper { padding: 70px 25px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-6-img { margin: 35px 1% 0; }

  /* HERO-7 */
  #hero-7 { padding-top: 80px; padding-bottom: 80px; }
  .hero-7-txt h2 { padding: 0 6%; }
  #hero-7 .quick-form { margin-left: 3%; margin-right: 3%; }
  .hero-7-img { margin-top: 35px; }

  /* HERO-8 */
  .hero-8-txt { padding: 0 5%; }
  .hero-8-txt .hero-logo { display: none; }
  .hero-8-txt .hero-digits { padding: 0 3%; }

  #hero-8-form { padding: 30px 22px; margin: 40px 4% 0; border-radius: 10px; }
  #hero-8-form h4 { margin-bottom: 15px; }
  #hero-8-form p { font-size: 1.0625rem; margin-bottom: 16px; }
  #hero-8-form p.p-sm { font-size: 1rem; padding: 0 2%!important; }

  /* HERO-9 */
  .hero-9-txt h2 { padding: 0 3%; line-height: 1.45; }
  .hero-9-txt h2 img { max-height: 56px; margin: 0 2px; top: -9px; }
  .hero-9-txt p.s-20 { font-size: 1.125rem; margin-top: 10px; }

  #hero-9 .quick-form { margin-left: 2%; margin-right: 2%; }
  .hero-9-img { margin: 45px 0 0; }

  /* HERO-10 */
  #hero-10:after { height: 7%; }
  .hero-10-txt h2 { padding: 0 2%; margin-bottom: 15px; }
  .hero-10-txt h4 { padding: 0 2%; margin-bottom: 25px; }
  .hero-10-img { margin-top: 35px; }

  /* HERO-11 */
  .hero-11-txt { padding: 0 4%; }
  .hero-11-img { margin: 35px 3% -50px; }

  /* HERO-12 */
  #hero-12:after { height: 7%; }
  .hero-12-txt h2 { padding: 0 6%; }
  .font--inter .hero-12-txt h2 { padding: 0 5.5%; }
  .hero-12-txt p { padding: 0 4%; }
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  #hero-13 { padding-top: 70px; padding-bottom: 70px; } 
  .hero-13-txt { padding: 0 3%; }
  .hero-13-img { margin: 40px 2% 0; }

  /* HERO-14 */
  #hero-14 { margin-bottom: 20px; }
  .hero-14-txt h2 { padding: 0 3%; }
  .hero-14-txt h2.s-62 { font-size: 2.0625rem; } 
  .hero-14-txt p.s-21 { padding: 0 3%; margin-bottom: 25px; }
  #hero-14 .quick-form { margin-left: 4%; margin-right: 4%; }
  .hero-14-img { margin-top: 35px; margin-bottom: -20px; }
  .hero-14-txt .btn-txt { margin-top: 25px; }

  /* HERO-15 */
  .hero-15-txt { padding: 0 3%; }
  .hero-15-img { margin: 35px 2% 0; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; margin-bottom: 35px; }
  .hero-16-txt p { padding: 0 5%; }
  .hero-16-img { margin: 40px 0 -35px; }

  /* HERO-17 */
  .hero-17-txt p { padding: 0 2%; }
  #hero-17 .quick-form { margin-left: 4%; margin-right: 4%; }
  #hero-17 #brands-1 { padding-top: 35px; } 

  /* HERO-18 */
  .hero-18-txt { padding: 0 3%; }
  #hero-18 .quick-form { margin: 20px 0 0; }
  .hero-18-img { margin-top: 40px; }

  /* HERO-19 */
  .hero-19-txt h2 { padding: 0 5%; }
  .hero-19-txt p { padding: 0 2%; }

  /* HERO-20 */
  .hero-20-txt { padding: 0 7%; }
  .hero-20-txt .hero-logo { display: none; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 20px; }

  /* HERO-21 */
  #hero-21 { overflow: visible; margin-bottom: 50px; }
  #hero-21 .container { max-width: 100%; }
  .hero-21-txt { margin-bottom: 50px; }
  .hero-21-txt h2 {  margin-bottom: 18px; }

  .hero-21-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-21 .hero-overlay { position: relative; padding: 60px 25px 0; }
  .hero-21-img { margin-bottom: -50px; }
  .hero-21-img .video-btn { top: calc(50% - 2px); }

  /* HERO-22 */
  #hero-22-form { text-align: center; padding: 35px 25px 25px; margin: 0 2%; }
  #hero-22 .hero-logo { display: none; }
   #hero-22-form h4 { font-size: 1.6875rem; padding: 0; margin-bottom: 15px; }
  #hero-22-form p { font-size: 1.125rem; padding: 0 5%; margin-bottom: 16px; }

  /* HERO-23 */
  .hero-23-txt { padding: 0 4%; }
  .hero-23-img { margin-top: 12px; }

  /* HERO-24 */
  #hero-24 { padding-top: 80px; padding-bottom: 80px; }
  .hero-24-txt p.p-xl { padding: 0 3%; }
  #hero-24 .quick-form { margin-left: 4%; margin-right: 4%; }

  /* HERO-25 */
  .hero-25-txt p.p-lg { margin-bottom: 22px; }
  .hero-25-img { margin: 35px 2% 0; }

  /* HERO-26 */
  .hero-26-txt { padding: 0 5%; } 
  .hero-26-txt h2 { padding: 0 3%; } 
  .hero-26-img { margin-top: 35px; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 2.05rem; margin-bottom: 15px!important; }
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { font-size: 1.1875rem; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 0!important; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }
  .a2-txt { padding: 15px 20px 30px; }

  .a2-txt-quote { position: relative; margin-bottom: -40px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.125rem; }
  .a2-txt-author span { font-size: 0.975rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 15px; }

  #about-4 .txt-block h2 { padding: 0 6%; }
  #about-4 .txt-block p { margin-top: 12px; }
  #a4-2 { margin: 35px 3% 0; }

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 70px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 68%; top: 32%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 64%; top: 32.35%; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-7, .fbox-9 { padding: 40px 25px 35px; }
  .fbox-5 { padding: 45px 25px 40px; } 
  .row-cols-md-2 .fbox-7 { padding: 40px 55px 35px; }
  .fbox-8.fb-1 { padding: 0 25px 40px; }
  .fbox-8.bg--white-100 {padding: 45px 25px; }
  .fbox-8.fb-3 { padding: 45px 25px 0; }

  .fbox-12, .fbox-13 { padding: 35px 30px; } 
  #fb-12-1, #fb-13-1 { margin-top: 65px; }

  .fbox-14 { padding: 45px 40px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 185px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 165px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155,  .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 9% 30px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after{ font-size: 3.65rem; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.4375rem; }  /* 23px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 10%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, 
  .row-cols-md-2 .fbox-1 p, .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .fbox-5 .fbox-txt p, .fbox-10 p { padding: 0 8%; } 

  .row-cols-md-3 .fbox-4 p, .row-cols-md-2 .fbox-4 p { padding: 0 4%; } 
  .fbox-5 .fbox-txt p, .rows-3 .fbox-11 p { padding: 0 2%; } 
  .row-cols-md-2 .fbox-6 p { padding: 0 10%; } 
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0 2%; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 70px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 35px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 70px 22px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 70px 14px; }
  .bc-5-wrapper .section-overlay { padding: 70px 14px 0; }
  .cbox-6-wrapper { padding: 30px 20px; }
  .bc-6-wrapper .section-overlay { padding: 70px 20px; }
  .bc-6 { padding: 25px 17px 35px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; } 

  .bc-5-wrapper .section-title p { padding: 0 5%; } 

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 15px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.p-sm, .txt-block p { margin-bottom: 0.875rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.1875rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg {margin-bottom: 6px; }

  .txt-block .btn { margin-top: 18px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; }
  .bc-6-link.mt-15 { margin-top: 12px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  /* Content Box */
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 35px; margin: 0 ; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 3rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.5rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 14px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 24px; }

  /* Content Box Icon */
  .cbox-1.ico-10 span { right: 6px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 6px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.3rem; height: 2.3rem; font-size: 1.15rem; line-height: 2.05rem; }
  .ct-03 .cbox-2-ico { width: 2rem; height: 2rem; font-size: 1.0625rem; line-height: 1.8rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }

  .cbox-2-txt, .ct-03 .cbox-2-txt, .cbox-3-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 20px; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }
  .cbox-4-txt { margin-bottom: 30px; }
  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.35rem!important; margin-bottom: 20px; }
  .box-title span { display: block; position: relative; top:0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.35rem; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: 0; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 8px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 12px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 5px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after {  font-size: 2.125rem; line-height: 2.125rem!important; margin-right: 4px; }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 40px 25px; }
  .img-block-hidden, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -30px; }
  .ct-10 .img-block { margin-bottom: -40px; }

  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 35px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }
  .bc-5-img.bc-5-tablet.img-block-hidden { margin-bottom: -70px; }
  .bc-6-img { padding: 30px 15px 0; margin-bottom: 25px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 30px); }
  .img-block-txt, .img-block-btn { margin-top: 35px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 40px 8%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 12% 18px; }

  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-3-3, #sb-4-3 { margin-bottom: 30px; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 35px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 2.25rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2.125rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.125rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 8%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 35px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 35px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 35px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 31px; }
  .rbox-2-txt img { max-height: 31px; }
  p.rbox-2-txt { line-height: 31px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.90625rem; padding: 0 6%; }  /* 30.5px */

  .review-1 { padding: 35px 20px 30px; margin: 0 6px 20px; }
  .review-2, .review-3, .review-4, .review-5 { padding: 35px 20px 30px; }

  .review-txt { margin-top: -40px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1.0625rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.2rem; }

  /* PRESS */
  .press-logo img { max-height: 74px; }
  .press-txt { padding: 0 3%; margin-top: 10px; }

  /* BRANDS */
  #brands-3:after { height: 75%; }

  .brands-title { padding: 0 4%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 30px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1.0625rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, #brand-4-3, 
  #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 30px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 25px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 25px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 20px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-2 .container { max-width: 100%; }
  .integrations-2-wrapper { padding: 70px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .in_tool_logo.ico-65 img { width: 55px; height: 55px; }
  .in_tool h6 { font-size: 1.125rem; }

  .integrations-1-wrapper .in_tool { padding: 20px 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.4rem; line-height: 1; margin-top: 4px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 6px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }

  /* PRICING */
  .pricing-1-table, .text-center .pricing-1-table { padding: 35px 40px 35px; }
  .pricing-2-table { padding: 35px 50px; }
  .pricing-3-table { padding: 30px 50px 35px; }
  .pricing-4-table { padding: 35px 40px; }

  .pricing-table-header h5, .pricing-table-header h4 { font-size: 1.5625rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1.05rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9375rem; }

  .p-table p.btn-txt { margin: 12px 0 0 0; }

  .price span { font-size: 2.75rem; } 
  .pricing-2-table.highlight .price span { font-size: 2.5rem; } 
  .pricing-3-table .price span { font-size: 4.25rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 2.75rem; } 

  .price sup { font-size: 2rem; top: -5px; }
  .pricing-3-table .price sup { font-size: 2.45rem; top: -19px; }
  .pricing-4-table .price sup { font-size: 2rem; top: -5px; }

  .pricing-3-table .price sup.coins { font-size: 2.45rem; top: -22px; letter-spacing: -2px; }

  .price sup.validity { font-size: 1.3125rem; }
  .pricing-3-table .price sup.validity { font-size: 1.3125rem; top: 0; margin-top: 20px; margin-bottom: 35px; }
  .pricing-4-table .price sup.validity { font-size: 1.3125rem; left: 4px; }

  .text-center .price p, .text-center .pricing-2-table .price p { padding: 0; }

  .pricing-2-table hr { margin-top: 20px; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 20px; }
  .pricing-features li, .pricing-4-table .pricing-features li { padding: 10px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0 ; }

  /* Pricing Toggle Button */
  #pricing-4 .ext-toggle-btn { margin: 0 auto 30px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }
  .table>:not(caption)>*>* { padding-top: 9px; padding-bottom: 9px; }
  .comp-table .table-responsive thead th { font-size: 0.9375rem; }
  .comp-table .table-responsive tbody th { font-size: 0.915rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.9rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  .pbox { padding: 0; }
  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 40px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* TEAM */
  #team-1.pb-40, #team-2.pb-40 { padding-bottom: 30px; }
  .team-member.mb-40, .team-member.mb-50, .team-member.mb-60 { margin-bottom: 40px; }

  .team-member-photo { margin-bottom: 25px; }
  #team-1 .team-member-data, #team-2 .team-member-data { margin-left: 10px; }

  .team-member-data h6.s-20 { font-size: 1.4375rem; margin-bottom: 6px; }    /* 23px */
  .team-member-data p { font-size: 1.175rem; }

  ul.tm-social, #team-1 ul.tm-social { top: 5px; right: 10px; }
  .tm-social.ico-20 [class*="flaticon-"]:before, .tm-social.ico-20 [class*="flaticon-"]:after { font-size: 1.4125rem; }

  /* CAREERS */
  #careers-1 { padding-bottom: 40px; }
  .role-box { text-align: center; padding: 28px 50px; }
  .role-box-txt span, #careers-1 .role-box span { margin-bottom: 12px; }
  .role-box-txt h6 { line-height: 1.4; margin-bottom: 5px; }
  #careers-1 .role-box h6 { line-height: 1.4; margin-bottom: 6px; }
  .role-box-txt p { margin-bottom: 14px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 25px; }
  #role-page .inner-page-title span { margin-bottom: 20px; }
  #role-page .inner-page-title p { padding: 0; }
  .role-info hr, #role-page .cbox-6-wrapper { margin: 35px 0; }
  .txt-block.role-info .btn { margin-top: 30px; }

  /* BANNER */
  #banner-12, #banner-13 { margin-top: 0; margin-bottom: 25px; }
  #banner-9.py-70 { padding-top: 55px; padding-bottom: 65px; }

  #banner-1 .container, #banner-2 .container, #banner-3 .container,
  #banner-11 .container, #banner-12 .container, #banner-13 .container { max-width: 100%; }
  .banner-1-wrapper, .banner-2-wrapper, .banner-3-wrapper,
  .banner-11-wrapper, .banner-12-wrapper, .banner-13-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .banner-1-wrapper .banner-overlay, .banner-2-wrapper .banner-overlay, 
  .banner-3-wrapper .banner-overlay { padding: 60px 30px 70px; }
  .banner-1-wrapper .banner-overlay.sm-title { padding: 55px 30px 65px; }

  #banner-5 .banner-overlay.pt-80.pb-90, #banner-7 .banner-overlay.pt-80.pb-90, #banner-6 .banner-overlay, 
  #banner-8 .banner-overlay, #banner-14 .banner-overlay, #banner-15 .banner-overlay { padding-top: 55px; padding-bottom: 65px; }

  #banner-10 .banner-overlay { padding-top: 60px; padding-bottom: 60px; }
  .banner-11-wrapper .banner-overlay { overflow: hidden; padding: 55px 45px 0; border-radius: 0 0!important; } 
  .banner-12-wrapper .banner-overlay { overflow: visible; padding: 55px 45px 0; border-radius: 0 0!important; } 
  .banner-13-wrapper .banner-overlay { overflow: visible; padding: 55px 45px 65px; border-radius: 0 0!important; } 
  .banner-16-wrapper .banner-overlay { padding: 40px 10%; }
  
  .banner-5-txt {padding: 0; }
  .col-md-8 .banner-5-txt {padding: 0 3%; }
  .banner-8-txt {padding: 0 5%; }
  .banner-12-txt, .banner-13-txt { margin-top: 0; padding-right: 0; }

  .banner-section .section-id { margin-bottom: 18px; }
  #banner-15.banner-section .section-id { margin-bottom: 22px; }

  .banner-section h2 { font-size: 1.90625rem!important; }
  .banner-section h3 { font-size: 1.78125rem!important; }
  .banner-1-txt h2 { margin-bottom: 12px; }
  .banner-2-txt h2 { margin-bottom: 25px; }
  .banner-4-txt h2, .banner-10-txt h2 { margin-bottom: 22px; }
  .banner-7-txt h2 { margin-bottom: 25px; padding: 0 4%; }
  .banner-10-txt h6 { margin-bottom: 8px; }
  .banner-10-txt h2 { font-size: 3.5rem!important; margin-bottom: 18px; }
  .banner-11-txt h2, .banner-12-txt h2, .banner-13-txt h2 { margin-bottom: 14px; }
  .banner-15-txt h2 { margin-bottom: 30px; }
  .banner-16-txt h4 { padding: 0 5%; margin-bottom: 10px; }
  
  .banner-section p.p-lg, .banner-section p.p-xl { margin-bottom: 20px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl,
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 22px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl, .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 12px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 12px; margin-bottom: 18px; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 12px; margin-bottom: 22px!important; }
  .banner-11-txt p { padding-right: 0; }
  .banner-14-txt p.p-lg { font-size: 1.125rem; padding: 0 10%; margin-top: 20px; margin-bottom: 25px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 20px; }
  .banner-8-buttons { margin-top: 15px; padding: 0 4%; }
  .banner-15-txt .download-links { margin-top: 15px; }
  .banner-section .btns-group .btn:first-child { margin-right: 0; margin-left: 0; margin-bottom: 15px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 12px; }

  .banner-9-img { margin: 40px 5% 0; }
  .banner-10-img { margin: 40px 8% 0; }
  .banner-11-img { margin: 35px 3% -20px; }
  .banner-12-img { transform: rotate(0deg); margin: 35px 0 -40px; }

  /* DOWNLOAD */
  span.version-data { font-size: 2rem; margin-right: 10px; }
  span.release-date span { margin-left: 10px; }

  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 50px; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 20px; }
  #faqs-2 .inner-page-title { margin-bottom: 30px; }
  #faqs-3 .section-title { margin-bottom: 40px; }
  #faqs-4 .inner-page-title { margin-bottom: 40px; }
  #faqs-3 .section-title h2 { padding: 0 4%; }

  #faqs-3 .section-title .contact-link { display: block; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 20px 0 22px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 23px; right: 0; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 22px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-2 .accordion-panel-item.mb-35, #faqs-3 .question.mb-35 { margin-bottom: 25px; }

  .faqs-section .accordion-thumb h5 { line-height: 1.4; padding-right: 8%; }
  .question h5, .accordion-panel-item h5 { line-height: 1.4; margin-bottom: 15px; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 { margin: 0 5px 10px; }
  .faqs-section .tabs-1 li { min-width: 100%; padding: 20px 0; margin: 0 0 20px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 45px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 15px; }
  .more-questions-txt { padding: 18px 36px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 50px; padding-bottom: 40px; }  
  #newsletter-2 .container { max-width: 100%; }
  #newsletter-2 .newsletter-overlay { padding: 50px 8% 40px; margin: 0 -15px; border-radius: 0 0!important; }
  #newsletter-3, #newsletter-4 .newsletter-overlay { padding-top: 55px; padding-bottom: 60px; }

  #newsletter-4 .newsletter-txt, #newsletter-5 .newsletter-txt { padding: 0; }

  .newsletter-txt h2, #newsletter-4 .newsletter-txt h2 { margin-bottom: 25px; }
   #newsletter-3 .newsletter-txt h2 { padding: 0 1%; }
  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.75rem; padding: 0; margin-bottom: 25px; }

  #newsletter-3 .newsletter-txt p { padding: 0 5%; }
  #newsletter-4 .newsletter-txt p { font-size: 1rem; padding: 0 5%; margin-bottom: 0; }
  #newsletter-5 .newsletter-txt p { margin-top: 15px; }

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form, 
  #newsletter-3 .newsletter-form, #newsletter-4 .newsletter-form { padding: 0 5%; }
  #newsletter-5 .newsletter-form { margin-top: 25px; padding: 0 5%; }

  .newsletter-section .form-control, #newsletter-1.newsletter-section .form-control, 
  #newsletter-5.newsletter-section .form-control { height: 54px; font-size: 1.0625rem; }
  .newsletter-section .btn, #newsletter-1 .btn, #newsletter-5.newsletter-section .btn { height: 54px; font-size: 1.0625rem; }

  /* BLOG */
  .blog-post-img.mb-35 { margin-bottom: 30px; }
  .post-tag { font-size: 0.825rem; margin-bottom: 14px; }

  .blog-post-txt h6 { margin-bottom: 14px; }
  .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 14px; }
  .blog-post-meta.mt-20, .blog-post-meta.mt-30 { margin-top: 14px; }
  .post-meta-list p { margin-bottom: 0; }

  /* Blog Listing */
  .wide-post { margin-bottom: 40px; }
  .wide-post h3.s-36, .wide-post h3.s-38, .wide-post h3.s-40, .square-post h4.s-34 { font-size: 1.3125rem; }
  .wide-post .blog-post-img { margin-bottom: 30px; }

  .square-post { margin-top: 10px; margin-bottom: 40px; }
  .square-post .blog-post-txt { padding: 40px 20px; }

  .posts-category { margin-top: 10px; margin-bottom: 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 8%; margin-bottom: 20px; }
  .single-post-title .blog-post-meta.mt-35 { margin-top: 25px; }
  #single-post .post-meta-list .meta-list-divider { display: none!important; }
  #single-post .post-meta-list li { display: block!important; margin-top: 10px; }

  .post-inner-img { margin-top: 30px; margin-bottom: 30px; }

  .single-post-txt h5, .single-post-txt h4 { margin-top: 15px; margin-bottom: 18px; }

  .single-post-txt .blockquote p { font-size: 1.0625rem; margin-bottom: 25px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; line-height: 1.3; }

  .post-share-list { margin-top: 30px; padding-bottom: 40px; }

  .post-author { display: block!important; padding: 50px 0; }
  .post-author-avatar { display: block!important; text-align: left; margin-right: 0; margin-bottom: 20px; }
  .post-author-avatar img { width: 70px; height: 70px; }
  .post-author-txt h5 { margin-bottom: 10px; }

  .author-follow-btn { padding: 5px 12px; top: 135px; right: 10px; }

  .post-comments { padding-top: 50px; }
  .post-comments h5 { margin-bottom: 40px; }
  .post-comments img { width: 45px; height: 45px; }
  .comment-body { margin-left: 18px; }
  .comment-meta { margin-bottom: 8px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .post-comments #leave-comment h5 { margin-top: 50px; }

  .comment-form p { margin-bottom: 10px; }

  .comment-form { margin-top: 50px; }
  .comment-form .btn { font-size: 1rem; margin-top: 0; }

  /* CONTACTS */
  #contacts-1 .section-title p { padding: 0 3%; }
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }

  #contacts-1 .contact-form .col-md-12 span { line-height: 1.4; margin-bottom: 15px; }
  .contact-form .form-control, .contact-form .form-select { height: 54px; }
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }
  .bottom-footer p.p-sm { margin-bottom: 0; }

  #footer-10.pt-50 { padding-top: 0; }
  #footer-11.p-404.pt-50 { padding-top: 40px; padding-bottom: 40px; }

  .footer .col-sm-4, .footer .col-sm-6, .footer .col-sm-8 { width: 50%!important; float: left; }
  #footer-3.footer .col-sm-6.col-md-3 { width: 100%!important; float: none; }

  .footer-info, .footer-links, .footer-stores-badge { margin-bottom: 35px; }
  #footer-2 .footer-info { margin-bottom: 40px; }

  #footer-6 .footer-info, #footer-7 .footer-info { margin-top: 15px; margin-bottom: 0; }
  #footer-10 .footer-info { margin-bottom: 25px; padding-bottom: 35px; }
  #footer-8 .footer-links, #footer-10 .footer-links { margin-bottom: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 37px; }
  #footer-1 .footer-logo, #footer-5 .footer-logo, #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 24px; }
  #footer-10 img.footer-logo { max-height: 37px; }

  .footer h6 { font-size: 1.21875rem; margin-bottom: 20px; } /* 19.5px */

  .footer-mail-link { font-size: 1.125rem; } /* 18px */
  .footer-mail-link span { top: 3px; right: 0; }
  #footer-1 .footer-info .footer-mail-link { margin-top: 5px; }

  #footer-8 .foo-links li { display: block!important; padding-left: 0; }
  #footer-10 .foo-links li { padding: 0 8px; }

  .foo-links li a span.ext-link { left: 3px; top: 0.5px; }
  .foo-links.ico-10 li span { top: 1px; right: 3px; }

  .footer-stores-badge .store { margin-right: 0; }

  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3925rem; }

  .footer-form { margin-right: 5%; }
  #footer-9 .footer-form { margin-bottom: 0; }
  #footer-9 .footer-form h6 { padding-right: 10%; }
  .footer-form .form-control { font-size: 1.1rem; height: 34px; }
  .footer-form .btn { height: 34px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.125rem; line-height: 28px!important; }

  .bottom-footer-socials { margin-top: 10px; }
  .bottom-footer-socials li { padding-left: 12px; }
  .bottom-footer-socials li:first-child { padding-left: 0; }

  .bottom-footer-list { margin-top: 3px; } 
  #footer-4 .bottom-footer-list li, #footer-9 .bottom-footer-list li { padding-left: 8px; }

  .footer-list-divider { top: -1px; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { font-size: 1.15rem; padding: 0 5%; line-height: 1.6666; margin-top: 10px; }
  .page-hero-section h1 { line-height: 1.25; margin-bottom: 20px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 40px; }

  .register-form-title { margin-bottom: 25px; }
  .register-form-title h3 { font-size: 1.6875rem; }

  .separator-line, .register-page-form p.input-header, .form-data span, .register-page-form .btn-google { font-size: 1rem; }
  #signup .form-data span { font-size: 0.9rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 0; margin-bottom: 8px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .separator-line { margin: 24px 0; }

  .reset-page-wrapper { margin: 0 15px; }
  .reset-page-wrapper form {  padding: 40px 20px 15px; }

  #login .register-page-form, #signup .register-page-form { padding: 35px 20px; }
  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 15px; }

  .reset-form-title { margin-bottom: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 20px; }

  .reset-page-wrapper .form-control, .register-page-form .form-control, #login .register-page-form .btn.submit, 
  #signup .register-page-form .btn.submit, .reset-page-wrapper .btn.submit { height: 54px; font-size: 1.0625rem; }
  .btn-google img { width: 20px; height: 20px; top: -2px; right: 5px; }

  .btn-show-pass { top: 18px; }

  /* PAGE 404 */
  .page-404-txt { padding-top: 100px; padding-bottom: 60px; }
  .page-404-txt h2 { margin-bottom: 15px; }
  .page-404-txt h6 { line-height: 1.6666; padding: 0 2%; margin-bottom: 22px; }
  .page-404-img { padding: 0 4%; margin-bottom: 35px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 370px; margin: auto auto; }
  #modal-2 .modal-dialog { width: 340px; margin: auto auto; }
  #modal-3 .modal-dialog.modal-xl { width: 360px; margin: auto auto; }

  #modal-1.modal .btn-close { right: 10px!important; top: 10px!important; }

  #modal-2 .modal-body-content { padding: 25px 30px 5px; }
  #modal-3 .modal-body-content { padding: 30px 15px 20px; }

  .modal-body .newsletter-form { margin-top: 20px; }
  .modal .newsletter-form .form-control, .modal .newsletter-form .btn { height: 52px; font-size: 1rem; }

  #modal-3 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 390px) and (max-width: 413.98px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .division { padding-left: 3px; padding-right: 3px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6.5%; }
  .shape--02:after { width: 140%; height: 90%; top: 6.5%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 94%; top: 0; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 15%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                             /* 17px */
  .s-17 { font-size: 1.125rem; }                                     /* 18px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.3125rem; } /* 21px */
  .s-24, .s-26, .s-28 { font-size: 1.4375rem; }                      /* 23px */
  .s-30, .s-32, .s-34 { font-size: 1.5625rem; }                      /* 25px */
  .s-36, .s-38, .s-40 { font-size: 1.6875rem; }                      /* 27px */ 
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.8125rem; } /* 29px */
  .s-48 { font-size: 1.9375rem; }                                    /* 31px */
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 2rem; }      /* 32px */ 
  .s-62 { font-size: 2.0625rem; }                                    /* 33px */
  .s-64, .s-66 { font-size: 2.125rem; }                              /* 34px */
  .s-68 { font-size: 2.25rem; }                                      /* 36px */

  /* Paragraphs */
  p.p-sm { font-size: 1rem; } 
  p { font-size: 1.0625rem; } 
  p.p-md, p.p-lg { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* Button */
  .btn { font-size: 1.0625rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.61915rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 8px; margin-right: 8px; margin-bottom: 15px; }
  .btns-group .btn:last-child { margin-left: 8px; margin-right: 8px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 3px; }
  .btn.ico-right span { margin-left: 3px; }

  /* Button Text */
  p.btn-txt, .text-center p.btns-group-txt { margin-top: 18px; margin-bottom: 0; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }

  /* OS Button */
  .os-btn { width: 280px; min-width: 280px; padding: 14px 15px 10px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 0; margin-bottom: 15px; }

  /* Store Badge */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 15px; }

  /* Watch Video Link */
  .watch-video { margin-top: 20px; }
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1.0625rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 120px; height: 120px; top: -35px; left: calc(50% - 60px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -35px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 125px; height: 125px; top: -30px; left: calc(50% - 65px); }
  .ico-60 .shape-ico svg { width: 130px; height: 130px; top: -35px; left: calc(50% - 60px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after,
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* Vector Square Icons */
  .ico-square { width: 88px; height: 88px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 88px!important; }

  /* Section ID */
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 35px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 40px; }

  .section-title h2 { padding: 0 1%; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 14px; }
  .section-title p.p-xl { font-size: 1.125rem; margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.1875rem; padding: 0 2%; margin-top: 12px; }  /* 19px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35 { margin-top: 20px; }
  .section-title .btns-group .btn { margin-left: 10px; margin-right: 10px; }  
  .section-title .btns-group .btn:first-child { margin-bottom: 15px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 23px; padding-left: 16px; }
  .blog-header .wsmobileheader .smllogo { margin-top: 29px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 34px; }
  .blog-header .smllogo img { max-height: 28px; }

  .wsmenu > .wsmenu-list { width: 325px; margin-right: -325px; }
  .wsactive .wsmobileheader { margin-right: 325px; }
  .overlapblackbg { width: calc(100% - 325px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 16px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 12px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 45px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-ico { margin-right: 18px; }
  .icons-menu-txt span { font-size: 1rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .18; }

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.3125rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 4px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 28px; }
  .hero-logo img { max-height: 32px; }

  .hero-square-logo { margin-bottom: 10px; }
  .hero-square-logo img { max-height: 64px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 25px; }
  .hero-app-logo img { max-height: 90px; }
  #hero-25 .hero-app-logo img { max-height: 90px; }

  /* HERO DIGITS */
  .hero-digits { margin-top: -5px; }
  #hero-9 .hero-digits { margin-top: 3px; }
  .hero-digits h2.statistic-number { font-size: 2.25rem; }
  .hero-digits h2.statistic-number span { font-size: 2rem; }

  /* HERO QUICK FORM */
  .hero-section .quick-form.mt-45, .hero-section .quick-form.mt-35 { margin-top: 0; }
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.175rem; height: 58px; }
  .hero-section .quick-form.form-half .form-control { height: 58px; font-size: 1.175rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 58px; font-size: 1.15rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 0 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 56px; font-size: 1.0625rem; padding: 0 14px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 56px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-1 */
  .hero-1-txt { padding: 0 3%; }
  .hero-1-img { margin-top: 35px; }

  /* HERO-2 */
  .hero-2-txt { padding: 0 2%; }
  .hero-2-img { margin-top: 35px; }

  /* HERO-3 */
  .hero-3-txt { padding: 0 2%; }
  #hero-3:after { height: 8%; }
  .hero-3-img { margin-top: 35px; }

  /* HERO-4 */
  #hero-4 { padding-top: 70px; }
  #hero-4:after { height: 7%; }

  .hero-4-txt h2 { padding: 0 3%; }
  .font--inter .hero-4-txt h2 { padding: 0 4%; }
  .hero-4-txt p { padding: 0 5px; }
  .hero-4-img { margin-top: 35px; }

   /* HERO-5 */
  #hero-5 { margin-bottom: 50px; }
  .hero-5-txt h2 { margin-bottom: 15px; }
  .hero-5-txt p.p-lg { margin-bottom: 20px; }

  /* HERO-6 */
  #hero-6 { padding-top: 0; }
  #hero-6 .container { max-width: 100%; }
  .hero-6-wrapper { padding: 70px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-6-txt p { padding: 0 3%; }
  .hero-6-img { margin: 35px 1% 0; }

  /* HERO-7 */
  #hero-7 { padding-top: 80px; padding-bottom: 80px; }
  .hero-7-txt h2 { padding: 0 5%; }
  #hero-7 .quick-form { margin-left: 3%; margin-right: 3%; }
  .hero-7-img { margin-top: 35px; }

  /* HERO-8 */
  .hero-8-txt { padding: 0 4%; }
  .hero-8-txt .hero-logo { display: none; }
  .hero-8-txt .hero-digits { padding: 0; }

  #hero-8-form { padding: 30px 18px; margin: 40px 2% 0; border-radius: 10px; }
  #hero-8-form h4 { margin-bottom: 15px; }
  #hero-8-form p { font-size: 1.0625rem; margin-bottom: 16px; }
  #hero-8-form p.p-sm { font-size: 1rem; padding: 0!important; }

  /* HERO-9 */
  .hero-9-txt h2 { padding: 0 3%; line-height: 1.45; }
  .hero-9-txt h2 img { max-height: 52px; margin: 0 2px; top: -9px; }
  .hero-9-txt p.s-20 { font-size: 1.125rem; margin-top: 10px; }

  #hero-9 .quick-form { margin-left: 2%; margin-right: 2%; }
  .hero-9-img { margin-top: 35px; }

  /* HERO-10 */
  #hero-10:after { height: 7%; }
  .hero-10-txt h2 { padding: 0 2%; }
  .hero-10-txt h4 { padding: 0 2%; margin-bottom: 25px; }
  .hero-10-img { margin-top: 35px; }

  /* HERO-11 */
  .hero-11-txt p { padding: 0 4%; }
  .hero-11-img { margin: 30px 3% -50px; }

  /* HERO-12 */
  #hero-12:after { height: 7%; }
  .hero-12-txt h2 { padding: 0 5%; }
  .font--inter .hero-12-txt h2 { padding: 0 4%; }
  .hero-12-txt p { padding: 0 5px; }
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  .hero-13-txt { padding: 0 3%; }
  .hero-13-img { margin: 40px 2% 0; }

  /* HERO-14 */
  #hero-14 { margin-bottom: 20px; }
  .hero-14-txt h2.s-62 { font-size: 2rem; padding: 0 3%; margin-bottom: 15px; }
  .hero-14-txt p.s-21 { padding: 0; margin-bottom: 25px; }
  #hero-14 .quick-form { margin-left: 4%; margin-right: 4%; }
  .hero-14-img { margin-top: 35px; margin-bottom: -20px; }
  .hero-14-txt .btn-txt { margin-top: 25px; }

  /* HERO-15 */
  .hero-15-txt { padding: 0 3%; }
  .hero-15-img { margin: 0 2%; }
  .hero-15-img { margin: 35px 2% 0; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; margin-bottom: 35px; }
  .hero-16-txt h2 { padding: 0 5%; }
  .hero-16-img { margin: 35px 0 -35px; }

  /* HERO-17 */
  .hero-17-txt p { padding: 0 2%; }
  #hero-17 .quick-form { margin-left: 2%; margin-right: 2%; }
  #hero-17 #brands-1 { padding-top: 40px; } 

  /* HERO-18 */
  .hero-18-txt { padding: 0 2%; }
  .hero-18-img { margin-top: 35px; }

  /* HERO-19 */
  .hero-19-txt h2 { padding: 0 4%; }
  .hero-19-txt p { padding: 0 1%; }

  /* HERO-20 */
  .hero-20-txt { padding: 0 4%; }
  .hero-20-txt .hero-logo { display: none; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 20px; }

  /* HERO-21 */
  #hero-21 { margin-bottom: 40px; }
  #hero-21 .container { max-width: 100%; }
  .hero-21-txt { margin-bottom: 50px; }
  .hero-21-txt h2 { margin-bottom: 20px; }

  .hero-21-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-21 .hero-overlay { position: relative; padding: 60px 25px 0; }
  .hero-21-img { margin-bottom: -40px; }
  .hero-21-img .video-btn { top: calc(50% - 2px); }

  /* HERO-22 */
  #hero-22-form { text-align: center; padding: 35px 20px 25px; margin: 0 1%; }
  #hero-22 .hero-logo { display: none; }
  #hero-22-form h4 { font-size: 1.6875rem; padding: 0; margin-bottom: 15px; }
  #hero-22-form p { font-size: 1.125rem; padding: 0 5%; margin-bottom: 16px; }

  /* HERO-23 */
  .hero-23-txt { padding: 0 2%; }
  .hero-23-img { margin-top: 12px; }

  /* HERO-24 */
  #hero-24 { padding-top: 80px; padding-bottom: 80px; }
  .hero-24-txt p.p-xl { padding: 0 2%; }
  #hero-24 .quick-form { margin-left: 2%; margin-right: 2%; }

  /* HERO-25 */
  .hero-25-img { margin: 35px 2% 0; }
  .hero-25-txt p.p-lg { margin-bottom: 22px; }

  /* HERO-26 */
  .hero-26-txt { padding: 0 5%; } 
  .hero-26-img { margin-top: 35px; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 1.9rem; margin-bottom: 15px!important; }
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { font-size: 1.15rem; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 0!important; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }
  .a2-txt { padding: 15px 20px 30px; }

  .a2-txt-quote { position: relative; margin-bottom: -40px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.125rem; }
  .a2-txt-author span { font-size: 0.975rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 15px; }

  #about-4 .txt-block h2 { padding: 0 6%; }
  #about-4 .txt-block p { margin-top: 15px; }
  #a4-2 { margin: 35px 3% 0; }

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 70px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 67%; top: 33%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 64%; top: 32.6%; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-7, .fbox-9 { padding: 40px 25px 35px; }
  .fbox-5 { padding: 45px 25px 40px; } 
  .row-cols-md-2 .fbox-7 { padding: 40px 35px 35px; }
  .fbox-8.fb-1 { padding: 0 25px 40px; }
  .fbox-8.bg--white-100 { padding: 45px 25px; }
  .fbox-8.fb-3 { padding: 45px 25px 0; }
  .fbox-12, .fbox-13 { padding: 32px 25px; } 
  #fb-12-1, #fb-13-1 { margin-top: 60px; }

  .fbox-14 { padding: 40px 30px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 185px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 180px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 165px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 12px 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 9% 30px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after{ font-size: 3.65rem; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.4375rem; }  /* 23px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 15px; }

  .fbox-14 h5 { padding: 0 10%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, .row-cols-md-2 .fbox-1 p,
  .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .row-cols-md-2 .fbox-6 p, .fbox-5 .fbox-txt p, .fbox-10 p { padding: 0 8%; } 
  .row-cols-md-3 .fbox-4 p, .row-cols-md-2 .fbox-4 p { padding: 0 4%; } 
  .fbox-5 .fbox-txt p, .rows-3 .fbox-11 p { padding: 0 2%; } 
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0; }

  /* CONTENT */
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 70px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 35px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 70px 17px; }
  .bc-4-wrapper .section-overlay { padding: 70px 20px; }
  .bc-5-wrapper .section-overlay { padding: 70px 17px 0; }
  .cbox-6-wrapper { padding: 30px 17px; }
  .bc-6-wrapper .section-overlay { padding: 70px 22px; }
  .bc-6 { padding: 20px 15px 30px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; }  

  .bc-5-wrapper .section-title p { padding: 0 3%; }

  .txt-box { margin-bottom: 15px; } 

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.p-sm, .txt-block p { margin-bottom: 0.875rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.1875rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg {margin-bottom: 6px; }

  .txt-block .btn { margin-top: 18px; }
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; }
  .bc-6-link.mt-15 { margin-top: 12px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  /* Content Box */
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 35px; margin: 0 ; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 2.65rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.15rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 14px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 24px; }

  /* Content Box Icon */
  .cbox-1.ico-10 span { right: 6px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 6px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.3rem; height: 2.3rem; font-size: 1.15rem; line-height: 2.05rem; }
  .ct-03 .cbox-2-ico { width: 1.6rem; height: 1.6rem; font-size: 1rem; line-height: 1.4rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }

  .cbox-2-txt, .cbox-3-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 20px; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }
  .cbox-4-txt { margin-bottom: 30px; }
  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.35rem!important; margin-bottom: 20px; }
  .box-title span { display: block; position: relative; top:0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.35rem; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: 0; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 8px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 12px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after {  font-size: 2.125rem; line-height: 2.125rem!important; margin-right: 4px; }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 35px 20px; }
  .img-block-hidden, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -30px; }
  .ct-10 .img-block { margin-bottom: -40px; }

  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 35px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }
  .bc-5-img.bc-5-tablet.img-block-hidden { margin-bottom: -70px; }
  .bc-6-img { padding: 30px 15px 0; margin-bottom: 25px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 30px); }
  .img-block-txt, .img-block-btn { margin-top: 40px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 40px; padding: 40px 7%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 12% 18px; }

  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2, #sb-3-3 { margin-bottom: 35px; }
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; }
  #sb-4-3 { margin-bottom: 30px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 35px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 2.25rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.25rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 8%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 40px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 35px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 35px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 35px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 45px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; padding: 0 15%; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 31px; }
  .rbox-2-txt img { max-height: 31px; }
  p.rbox-2-txt { line-height: 31px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.90625rem; padding: 0 4%; }  /* 30.5px */

  .review-1 { padding: 35px 20px 30px; margin: 0 6px 20px; }
  .review-2, .review-3, .review-4, .review-5 { padding: 35px 20px 30px; }

  .review-txt { margin-top: -40px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1.0625rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.2rem; }

  /* PRESS */
  .press-logo img { max-height: 74px; }
  .press-txt { padding: 0 1%; margin-top: 10px; }

  /* BRANDS */
  #brands-3:after { height: 75%; }

  .brands-title { padding: 0 4%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 30px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1.0625rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, 
  #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 25px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 25px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 25px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 17px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-2 .container { max-width: 100%; }
  .integrations-2-wrapper { padding: 70px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .in_tool_logo.ico-65 img { width: 55px; height: 55px; }
  .in_tool h6 { font-size: 1.125rem; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 52px; height: 52px; }
  .integrations-1-wrapper .in_tool { padding: 18px 22px; }
  .integrations-1-wrapper .in_tool.mb-30, .integrations-1-wrapper .it-7, .integrations-1-wrapper .it-8 { margin-bottom: 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.325rem; line-height: 1; margin-top: 5px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 6px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }
 
  /* PRICING */
  .pricing-1-table, .text-center .pricing-1-table { padding: 35px 40px 35px; }
  .pricing-2-table { padding: 35px 50px; }
  .pricing-3-table { padding: 30px 50px 35px; }
  .pricing-4-table { padding: 35px 40px; }

  .pricing-table-header h5, .pricing-table-header h4 { font-size: 1.5625rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1.05rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9375rem; }

  .p-table p.btn-txt { margin: 12px 0 0 0; }

  .price span { font-size: 2.75rem; } 
  .pricing-2-table.highlight .price span { font-size: 2.5rem; } 
  .pricing-3-table .price span { font-size: 4.25rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 2.75rem; } 

  .price sup { font-size: 2rem; top: -5px; }
  .pricing-3-table .price sup { font-size: 2.45rem; top: -19px; }
  .pricing-4-table .price sup { font-size: 2rem; top: -5px; }

  .pricing-3-table .price sup.coins { font-size: 2.45rem; top: -22px; letter-spacing: -2px; }

  .price sup.validity { font-size: 1.3125rem; }
  .pricing-3-table .price sup.validity { font-size: 1.3125rem; top: 0; margin-top: 20px; margin-bottom: 35px; }
  .pricing-4-table .price sup.validity { font-size: 1.3125rem; left: 4px; }

  .text-center .price p, .text-center .pricing-2-table .price p { padding: 0; }

  .pricing-2-table hr { margin-top: 20px; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 20px; }
  .pricing-features li, .pricing-4-table .pricing-features li { padding: 10px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0 ; }

  /* Pricing Toggle Button */
  #pricing-4 .ext-toggle-btn { margin: 0 auto 30px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }
  .table>:not(caption)>*>* { padding-top: 9px; padding-bottom: 9px; }
  .comp-table .table-responsive thead th { font-size: 0.9375rem; }
  .comp-table .table-responsive tbody th { font-size: 0.915rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.9rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  .pbox { padding: 0; }
  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* TEAM */
  #team-1.pb-40, #team-2.pb-40 { padding-bottom: 30px; }
  .team-member.mb-40, .team-member.mb-50, .team-member.mb-60 { margin-bottom: 40px; }

  .team-member-photo { margin-bottom: 25px; }
  #team-1 .team-member-data, #team-2 .team-member-data { margin-left: 10px; }

  .team-member-data h6.s-20 { font-size: 1.4375rem; margin-bottom: 6px; }    /* 23px */
  .team-member-data p { font-size: 1.175rem; }
  ul.tm-social, #team-1 ul.tm-social { top: 5px; right: 10px; }
  .tm-social.ico-20 [class*="flaticon-"]:before, .tm-social.ico-20 [class*="flaticon-"]:after { font-size: 1.4125rem; }

  /* CAREERS */
  #careers-1 { padding-bottom: 40px; }
  .role-box { text-align: center; padding: 28px 40px; }
  .role-box-txt span, #careers-1 .role-box span { margin-bottom: 12px; }
  .role-box-txt h6 { line-height: 1.4; margin-bottom: 5px; }
  #careers-1 .role-box h6 { line-height: 1.4; margin-bottom: 6px; }
  .role-box-txt p { margin-bottom: 14px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 25px; }
  #role-page .inner-page-title span { margin-bottom: 20px; }
  #role-page .inner-page-title p { padding: 0; }
  .role-info hr, #role-page .cbox-6-wrapper { margin: 35px 0; }
  .txt-block.role-info .btn { margin-top: 30px; }

  /* BANNER */
  #banner-12, #banner-13 { margin-top: 0; margin-bottom: 25px; }
  #banner-9.py-70 { padding-top: 55px; padding-bottom: 65px; }

  #banner-1 .container, #banner-2 .container, #banner-3 .container,
  #banner-11 .container, #banner-12 .container, #banner-13 .container { max-width: 100%; }
  .banner-1-wrapper, .banner-2-wrapper, .banner-3-wrapper,
  .banner-11-wrapper, .banner-12-wrapper, .banner-13-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .banner-1-wrapper .banner-overlay, .banner-2-wrapper .banner-overlay, 
  .banner-3-wrapper .banner-overlay { padding: 60px 20px 70px; }
  .banner-1-wrapper .banner-overlay.sm-title { padding: 55px 25px 65px; }

  #banner-5 .banner-overlay.pt-80.pb-90, #banner-7 .banner-overlay.pt-80.pb-90, #banner-6 .banner-overlay, 
  #banner-8 .banner-overlay, #banner-14 .banner-overlay, #banner-15 .banner-overlay { padding-top: 55px; padding-bottom: 65px; }

  #banner-10 .banner-overlay { padding-top: 60px; padding-bottom: 60px; }
  .banner-11-wrapper .banner-overlay { overflow: hidden; padding: 55px 35px 0; border-radius: 0 0!important; } 
  .banner-12-wrapper .banner-overlay { overflow: visible; padding: 55px 35px 0; border-radius: 0 0!important; } 
  .banner-13-wrapper .banner-overlay { overflow: visible; padding: 55px 35px 65px; border-radius: 0 0!important; } 
  .banner-16-wrapper .banner-overlay { padding: 35px 10%; }
  
  .banner-5-txt { padding: 0 3%; }
  .banner-8-txt {padding: 0 5%; }
  .banner-12-txt, .banner-13-txt { margin-top: 0; padding-right: 0; }

  .banner-section .section-id { margin-bottom: 18px; }
  #banner-15.banner-section .section-id { margin-bottom: 22px; }

  .banner-section h2 { font-size: 1.90625rem!important; }
  .banner-section h3 { font-size: 1.78125rem!important; }
  .banner-1-txt h2 { margin-bottom: 12px; }
  .banner-2-txt h2, .banner-10-txt h2 { margin-bottom: 25px; }
  .banner-4-txt h2 { padding: 0 8%; margin-bottom: 22px; }
  .banner-7-txt h2 { margin-bottom: 25px; padding: 0 2%; }
  .banner-10-txt h6 { margin-bottom: 8px; }
  .banner-10-txt h2 { font-size: 3.5rem!important; margin-bottom: 18px; }
  .banner-11-txt h2, .banner-12-txt h2, .banner-13-txt h2 { margin-bottom: 14px; }
  .banner-15-txt h2 { margin-bottom: 30px; }
  .banner-16-txt h4 { padding: 0 5%; margin-bottom: 10px; }
  
  .banner-section p.p-lg, .banner-section p.p-xl { margin-bottom: 20px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl,
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 20px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl, .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 12px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 12px; margin-bottom: 18px; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 12px; margin-bottom: 22px!important; }
  .banner-11-txt p { padding-right: 0; }
  .banner-14-txt p.p-lg { font-size: 1.125rem; padding: 0 10%; margin-top: 20px; margin-bottom: 25px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 20px; }
  .banner-8-buttons { margin-top: 15px; padding: 0 4%; }
  .banner-15-txt .download-links { margin-top: 15px; }
  .banner-section .btns-group .btn:first-child { margin-right: 0; margin-left: 0; margin-bottom: 15px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 12px; }

  .banner-9-img { margin: 40px 5% 0; }
  .banner-10-img { margin: 40px 8% 0; }
  .banner-11-img { margin: 35px 3% -20px; }
  .banner-12-img { transform: rotate(0deg); margin: 35px 0 -40px; }

  /* DOWNLOAD */
  span.version-data { font-size: 2rem; margin-right: 10px; }
  span.release-date span { margin-left: 10px; }

  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 50px; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 20px; }
  #faqs-2 .inner-page-title { margin-bottom: 30px; }
  #faqs-3 .section-title { margin-bottom: 40px; }
  #faqs-4 .inner-page-title { margin-bottom: 40px; }

  #faqs-3 .section-title .contact-link { display: block; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 20px 0 22px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 23px; right: 0; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 22px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-2 .accordion-panel-item.mb-35, #faqs-3 .question.mb-35 { margin-bottom: 25px; }

  .faqs-section .accordion-thumb h5 { line-height: 1.4; padding-right: 8%; }
  .question h5, .accordion-panel-item h5 { line-height: 1.4; margin-bottom: 15px; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 { margin: 0 5px 10px; }
  .faqs-section .tabs-1 li { min-width: 100%; padding: 20px 0; margin: 0 0 20px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 45px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 15px; }
  .more-questions-txt { padding: 18px 36px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 50px; padding-bottom: 40px; }  
  #newsletter-2 .container { max-width: 100%; }
  #newsletter-2 .newsletter-overlay { padding: 50px 8% 40px; margin: 0 -15px; border-radius: 0 0!important; }
  #newsletter-3, #newsletter-4 .newsletter-overlay { padding-top: 55px; padding-bottom: 60px; }

  #newsletter-4 .newsletter-txt, #newsletter-5 .newsletter-txt { padding: 0; }

  .newsletter-txt h2, #newsletter-4 .newsletter-txt h2 { margin-bottom: 25px; }
  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.75rem; padding: 0; margin-bottom: 25px; }

  #newsletter-3 .newsletter-txt p { padding: 0 4%; }
  #newsletter-4 .newsletter-txt p { font-size: 1rem; padding: 0 4%; margin-bottom: 0; }
  #newsletter-5 .newsletter-txt p { margin-top: 15px; }

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form, #newsletter-3 .newsletter-form { padding: 0 5%; }
  #newsletter-4 .newsletter-form { padding: 0 5%; }
  #newsletter-5 .newsletter-form { margin-top: 25px; padding: 0 5%; }

  .newsletter-section .form-control, #newsletter-1.newsletter-section .form-control, 
  #newsletter-5.newsletter-section .form-control { height: 54px; font-size: 1.0625rem; }
  .newsletter-section .btn, #newsletter-1 .btn, #newsletter-5.newsletter-section .btn { height: 54px; font-size: 1.0625rem; }

  /* BLOG */
  .blog-post-img.mb-35 { margin-bottom: 30px; }
  .post-tag { font-size: 0.825rem; margin-bottom: 14px; }

  .blog-post-txt h6, .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 14px; }
  .blog-post-meta.mt-20, .blog-post-meta.mt-30 { margin-top: 14px; }
  .post-meta-list p { margin-bottom: 0; }

  /* Blog Listing */
  .wide-post { margin-bottom: 40px; }
  .wide-post h3.s-36, .wide-post h3.s-38, .wide-post h3.s-40, .square-post h4.s-34 { font-size: 1.3125rem; }
  .wide-post .blog-post-img { margin-bottom: 30px; }

  .square-post { margin-top: 10px; margin-bottom: 40px; }
  .square-post .blog-post-txt { padding: 40px 20px; }

  .posts-category { margin-top: 10px; margin-bottom: 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 6%; margin-bottom: 20px; }
  .single-post-title .blog-post-meta.mt-35 { margin-top: 25px; }
  #single-post .post-meta-list .meta-list-divider { display: none!important; }
  #single-post .post-meta-list li { display: block!important; margin-top: 10px; }

  .post-inner-img { margin-top: 30px; margin-bottom: 30px; }

  .single-post-txt h5, .single-post-txt h4 { margin-top: 15px; margin-bottom: 18px; }

  .single-post-txt .blockquote p { font-size: 1.0625rem; margin-bottom: 25px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; line-height: 1.3; }

  .post-share-list { margin-top: 30px; padding-bottom: 40px; }

  .post-author { display: block!important; padding: 50px 0; }
  .post-author-avatar { display: block!important; text-align: left; margin-right: 0; margin-bottom: 20px; }
  .post-author-avatar img { width: 70px; height: 70px; }
  .post-author-txt h5 { margin-bottom: 10px; }

  .author-follow-btn { padding: 5px 12px; top: 135px; right: 10px; }

  .post-comments { padding-top: 50px; }
  .post-comments h5 { margin-bottom: 40px; }
  .post-comments img { width: 45px; height: 45px; }
  .comment-body { margin-left: 18px; }
  .comment-meta { margin-bottom: 8px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .post-comments #leave-comment h5 { margin-top: 50px; }

  .comment-form p { margin-bottom: 10px; }

  .comment-form { margin-top: 50px; }
  .comment-form .btn { margin-top: 0; }

  /* CONTACTS */
  #contacts-1 .section-title p { padding: 0 3%; }
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }

  #contacts-1 .contact-form .col-md-12 span { line-height: 1.4; margin-bottom: 15px; }
  .contact-form .form-control, .contact-form .form-select { height: 54px; }
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }
  .bottom-footer p.p-sm { margin-bottom: 0; }

  #footer-10.pt-50 { padding-top: 0; }
  #footer-11.p-404.pt-50 { padding-top: 40px; padding-bottom: 40px; }

  .footer .col-sm-4, .footer .col-sm-6, .footer .col-sm-8 { width: 50%!important; float: left; }
  #footer-3.footer .col-sm-6.col-md-3 { width: 100%!important; float: none; }

  .footer-info, .footer-links, .footer-stores-badge { margin-bottom: 35px; }
  #footer-2 .footer-info { margin-bottom: 40px; }

  #footer-6 .footer-info, #footer-7 .footer-info { margin-top: 15px; margin-bottom: 0; }
  #footer-10 .footer-info { margin-bottom: 25px; padding-bottom: 35px; }
  #footer-8 .footer-links, #footer-10 .footer-links { margin-bottom: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 37px; }
  #footer-1 .footer-logo, #footer-5 .footer-logo, #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 24px; }
  #footer-10 img.footer-logo { max-height: 37px; }

  .footer h6 { font-size: 1.21875rem; margin-bottom: 20px; } /* 19.5px */

  .footer-mail-link { font-size: 1.125rem; } /* 18px */
  .footer-mail-link span { top: 3px; right: 2px; }
  #footer-1 .footer-info .footer-mail-link { margin-top: 5px; }

  #footer-8 .foo-links li { display: block!important; padding-left: 0; }
  #footer-10 .foo-links li { padding: 0 8px; }

  .foo-links.ico-10 li span { top: 1px; right: 3px; }
  .foo-links li a span.ext-link { left: 3px; top: 0.5px; }

  .footer-stores-badge .store { margin-right: 0; }

  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3925rem; }

  .footer-form { margin-right: 5%; }
  #footer-9 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.1rem; height: 34px; }
  .footer-form .btn { height: 34px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.125rem; line-height: 28px!important; }

  .bottom-footer-socials { margin-top: 10px; }
  .bottom-footer-socials li { padding-left: 12px; }
  .bottom-footer-socials li:first-child { padding-left: 0; }

  .bottom-footer-list { margin-top: 3px; } 
  #footer-4 .bottom-footer-list li, #footer-9 .bottom-footer-list li { padding-left: 8px; }

  .footer-list-divider { top: -1px; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { font-size: 1.15rem; padding: 0 5%; line-height: 1.6666; margin-top: 10px; }
  .page-hero-section h1 { line-height: 1.25; margin-bottom: 20px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 40px; }

  .register-form-title { margin-bottom: 25px; }
  .register-form-title h3 { font-size: 1.6875rem; }

  .separator-line, .register-page-form p.input-header, .form-data span, .register-page-form .btn-google { font-size: 1rem; }
  #signup .form-data span { font-size: 0.935rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 0; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .separator-line { margin: 20px 0; }

  .reset-form-title { margin-bottom: 16px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 15px; }

  .reset-page-wrapper { margin: 0 10px; }
  .reset-page-wrapper form {  padding: 35px 10px 10px; }
  #login .register-page-form, #signup .register-page-form { padding: 35px 25px; }
  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 15px 10px; }

  .reset-page-wrapper .form-control, 
  .register-page-form .form-control { height: 56px; font-size: 1.0625rem; padding: 5px 12px; margin-bottom: 20px; }
  
  #login .register-page-form .btn.submit { height: 56px; font-size: 1.0625rem; margin-top: 12px; }
  #signup .register-page-form .btn.submit { height: 56px; font-size: 1.0625rem; margin-top: 20px; }
  .reset-page-wrapper .btn.submit { height: 56px; font-size: 1.0625rem; }
  .btn-google img { width: 20px; height: 20px; top: -2px; right: 5px; }

  .btn-show-pass { top: 18px; }

  /* PAGE 404 */
  .page-404-txt { padding-top: 100px; padding-bottom: 60px; }
  .page-404-txt h2 { margin-bottom: 15px; } 
  .page-404-txt h6 { line-height: 1.6666; padding: 0; margin-bottom: 22px; }
  .page-404-img { padding: 0 4%; margin-bottom: 35px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 350px; margin: auto auto; }
  #modal-2 .modal-dialog { width: 340px; margin: auto auto; }
  #modal-3 .modal-dialog.modal-xl { width: 360px; margin: auto auto; }

  #modal-1.modal .btn-close { right: 10px!important; top: 10px!important; }

  #modal-2 .modal-body-content { padding: 25px 30px 5px; }
  #modal-3 .modal-body-content { padding: 30px 15px 22px; }

  .modal-body .newsletter-form { margin-top: 20px; }
  .modal .form-control, .modal .newsletter-form .btn { height: 52px; font-size: 1rem; }

  #modal-3 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }
  #modal-3 .request-form .btn { height: 52px; font-size: 1.0625rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 321px) and (max-width: 389.98px) {

  html { font-size: 97.5%; } 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .ts-0 { padding-top: 80px; }
  .division { padding-left: 3px; padding-right: 3px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 7%; }
  .shape--02:after { width: 140%; height: 90%; top: 7%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 94%; top: 0; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 15%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1.0625rem; }                                /* 16.5px */
  .s-17 { font-size: 1.125rem; }                                        /* 17.5px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.217948rem; }  /* 19px */
  .s-24, .s-26, .s-28 { font-size: 1.34615rem; }                        /* 21px */
  .s-30, .s-32, .s-34 { font-size: 1.47435rem; }                        /* 23px */
  .s-36, .s-38, .s-40 { font-size: 1.60256rem; }                        /* 25px */  
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.730769rem; }  /* 27px */
  .s-48 { font-size: 1.85897rem; }                                      /* 29px */  
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 1.923076rem; }  /* 30px */ 
  .s-62 { font-size: 1.98717rem; }                                      /* 31px */ 
  .s-64, .s-66 { font-size: 2.05128rem; }                               /* 32px */
  .s-68 { font-size: 2.17948rem; }                                      /* 34px */

  /* Paragraphs */
  p.p-md, p.p-lg { font-size: 1.0625rem; }
  p.p-xl { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.61095rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 5px; margin-right: 5px; margin-bottom: 15px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.5rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.1rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.1rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }

  /* Button Text */
  p.p-sm.btns-group-txt { font-size: 0.95192rem; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }

  /* OS Button */
  .os-btn { width: 270px; min-width: 270px; padding: 14px 15px 10px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 15px; }
  .btn-os.mr-15 { margin-right: 0; margin-bottom: 15px; }

  /* Store Badge */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 15px; }

  /* Watch Video Link */
  .watch-video { margin-top: 18px; }
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.0625rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-lg { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }
  .video-btn-md { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after,
  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
    font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px;
  }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 120px; height: 120px; top: -35px; left: calc(50% - 60px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -35px; left: calc(50% - 65px); }
  .ico-60 .shape-ico svg { width: 125px; height: 125px; top: -30px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 120px; height: 120px; top: -30px; left: calc(50% - 60px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after,
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* Vector Square Icons */
  .ico-square { width: 88px; height: 88px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 88px!important; }

  /* Section ID */
  .section-id { margin-bottom: 25px; }
  .section-id.rounded-id { padding: 8px 22px; font-size: 0.8rem; margin-bottom: 30px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 35px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 40px; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 14px; }
  .section-title p.p-xl { font-size: 1.0625rem; margin-top: 14px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.15384rem; padding: 0 2%; margin-top: 12px; }  /* 18px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 22px; }
  .section-title .btns-group .btn { margin-left: 10px; margin-right: 10px; }  
  .section-title .btns-group .btn:first-child { margin-bottom: 15px; }

  /*------------------------------------------*/
  /*  PRELOAD ANIMATION    
  /*------------------------------------------*/

  #loading-center { height: 90px; width: 90px; margin-top: -45px; margin-left: -45px; }
  .loader { width: 90px; height: 90px; }
  .loader::after { border: 45px solid; }  

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 23px; padding-left: 16px; }
  .blog-header .wsmobileheader .smllogo { margin-top: 29px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 34px; }
  .blog-header .smllogo img { max-height: 28px; }

  .wsmenu > .wsmenu-list { width: 290px; margin-right: -290px; }
  .wsactive .wsmobileheader { margin-right: 290px; }
  .overlapblackbg { width: calc(100% - 290px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 16px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 8px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 12px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 44px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-ico { margin-right: 17px; }
  .icons-menu-txt span { font-size: 0.9rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .16; }

  .hero-section h2 { margin-bottom: 15px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl { margin-bottom: 25px; }
  .hero-section p.s-21 { font-size: 1.217948rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 3px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 30px; }
  .hero-logo img { max-height: 30px; }

  .hero-square-logo { margin-bottom: 10px; }
  .hero-square-logo img { max-height: 60px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 25px; }
  .hero-app-logo img { max-height: 88px; }
  #hero-25 .hero-app-logo img { max-height: 88px; }

  /* HERO DIGITS */
  .hero-digits { margin-top: -5px; }
  #hero-9 .hero-digits { margin-top: 3px; }

  .hero-digits h2.statistic-number { font-size: 2.3125rem; }
  .hero-digits h2.statistic-number span { font-size: 2rem; }

  /* HERO QUICK FORM */
  .hero-section .quick-form.mt-45, .hero-section .quick-form.mt-35 { margin-top: 0; }
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.15rem; height: 56px; }
  .hero-section .quick-form.form-half .form-control { height: 56px; font-size: 1.15rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 56px; font-size: 1.125rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -10px 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 54px; font-size: 1.0625rem; padding: 0 14px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 54px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-1 */
  .hero-1-txt { padding: 0 3%; }
  .hero-1-img { margin-top: 35px; }

  /* HERO-2 */
  .hero-2-txt { padding: 0 2%; }
  .hero-2-img { margin-top: 35px; }

  /* HERO-3 */
  .hero-3-txt { padding: 0 2%; }
  #hero-3:after { height: 8%; }
  .hero-3-img { margin-top: 35px; }

  /* HERO-4 */
  #hero-4 { padding-top: 70px; }
  #hero-4:after { height: 7%; }

  .hero-4-txt h2 { padding: 0 3%; }
  .font--inter .hero-4-txt h2 { padding: 0 3%; }
  .hero-4-txt p { padding: 0 5px; }
  .hero-4-img { margin-top: 35px; }

   /* HERO-5 */
  #hero-5 { margin-bottom: 50px; }
  .hero-5-txt h2 { margin-bottom: 12px; }
  .hero-5-txt p.p-lg { margin-bottom: 20px; }

  /* HERO-6 */
  #hero-6 { padding-top: 0; }
  #hero-6 .container { max-width: 100%; }
  .hero-6-wrapper { padding: 70px 15px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-6-txt p { padding: 0 3%; }
  .hero-6-img { margin: 35px 1% 0; }

  /* HERO-7 */
  #hero-7 { padding-top: 70px; padding-bottom: 80px; }
  .hero-7-txt h2 { padding: 0 4%; }
  #hero-7 .quick-form { margin-left: 3%; margin-right: 3%; }
  .hero-7-img { margin-top: 35px; }

  /* HERO-8 */
  .hero-8-txt { padding: 0 4%; }
  .hero-8-txt .hero-logo { display: none; }
  .hero-8-txt .hero-digits { padding: 0; }

  #hero-8-form { padding: 26px 15px; margin: 35px 2% 0; border-radius: 10px; }
  #hero-8-form h4 { margin-bottom: 15px; }
  #hero-8-form p { font-size: 1rem; margin-bottom: 20px; }
  #hero-8-form p.p-sm { font-size: 0.9375rem; padding: 0!important; }

  /* HERO-9 */
  #hero-9 { padding-top: 70px; }
  .hero-9-txt h2 { padding: 0 3%; line-height: 1.45; }
  .hero-9-txt h2 img { max-height: 48px; margin: 0 2px; top: -6px; }
  .hero-9-txt p.s-20 { font-size: 1.0625rem; margin-top: 10px; }

  #hero-9 .quick-form { margin-left: 2%; margin-right: 2%; }
  .hero-9-img { margin: 35px 0 0; }

  /* HERO-10 */
  #hero-10:after { height: 7%; }
  .hero-10-txt h2 { padding: 0 3%; }
  .hero-10-txt h4 { padding: 0 3%; margin-bottom: 25px; }
  .hero-10-img { margin-top: 35px; }

  /* HERO-11 */
  .hero-11-txt p { padding: 0 4%; }
  .hero-11-img { margin: 30px 3% -50px; }

  /* HERO-12 */
  #hero-12 { padding-top: 70px; }
  #hero-12:after { height: 6%; }
  .hero-12-txt h2 { padding: 0 4%; }
  .font--inter .hero-12-txt h2 { padding: 0 3%; }
  .hero-12-txt p { padding: 0 5px; }
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  #hero-13 { padding-top: 70px; padding-bottom: 70px; } 
  .hero-13-txt { padding: 0 3%; }
  .hero-13-img { margin: 35px 1% 0; }

  /* HERO-14 */
  #hero-14 { padding-top: 80px; margin-bottom: 15px; }
  .hero-14-txt h2.s-62 { font-size: 1.923076rem; padding: 0 3%; margin-bottom: 15px; }
   .hero-14-txt p.s-21 { padding: 0; margin-bottom: 25px; }
  #hero-14 .quick-form { margin-left: 4%; margin-right: 4%; }
  .hero-14-img { margin-top: 35px; margin-bottom: -15px; }
  .hero-14-txt .btn-txt { margin-top: 25px; }

  /* HERO-15 */
  .hero-15-txt { padding: 0 2%; }
  .hero-15-img { margin: 35px 1% 0; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; margin-bottom: 35px; }
  .hero-16-txt h2 { padding: 0 4%; }
  .hero-16-txt p { padding: 0 2%; }
  .hero-16-img { margin: 35px 0 -35px; }

  /* HERO-17 */
  .hero-17-txt p { padding: 0 2%; }
  #hero-17 .quick-form { margin-left: 2%; margin-right: 2%; }
  #hero-17 #brands-1 { padding-top: 35px; } 

  /* HERO-18 */
  .hero-18-txt p { padding: 0 3%; }
  #hero-18 .quick-form { margin: 20px 2% 0; }
  .hero-18-img { margin-top: 35px; }

  /* HERO-19 */
  .hero-19-txt h2 { padding: 0 3%; }
  .hero-19-txt p { padding: 0 1%; }

  /* HERO-20 */
  #hero-20 { padding-top: 80px; padding-bottom: 80px; }
  .hero-20-txt { padding: 0 3%; }
  .hero-20-txt .hero-logo { display: none; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 20px; }

  /* HERO-21 */
  #hero-21 { margin-bottom: 40px; }
  #hero-21 .container { max-width: 100%; }
  .hero-21-txt { margin-bottom: 50px; }
  .hero-21-txt h2 { margin-bottom: 20px; }

  .hero-21-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-21 .hero-overlay { position: relative; padding: 50px 25px 0; }
  .hero-21-img { margin-bottom: -40px; }
  .hero-21-img .video-btn { top: calc(50% - 2px); }

  /* HERO-22 */
  #hero-22-form { text-align: center; padding: 30px 18px 20px; margin: 0 1%; }
  #hero-22 .hero-logo { display: none; }
  #hero-22-form h4 { font-size: 1.60256rem; padding: 0; margin-bottom: 15px; }
  #hero-22-form p { font-size: 1.1rem; padding: 0 5%; margin-bottom: 20px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; margin-bottom: 20px; }
  .hero-23-txt { padding: 0 2%; }
  .hero-23-img { margin-top: 10px; margin-bottom: -20px; }

  /* HERO-24 */
  #hero-24 { padding-top: 70px; padding-bottom: 70px; }
  .hero-24-txt p.p-xl { padding: 0 2%; }
  #hero-24 .quick-form { margin-left: 2%; margin-right: 2%; }

  /* HERO-25 */
  .hero-25-txt p.p-lg { margin-bottom: 22px; }
  .hero-25-img { margin: 35px 1% 0; }

  /* HERO-26 */
  .hero-26-txt { padding: 0 5%; } 
  .hero-26-img { margin-top: 35px; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 1.85rem; margin-bottom: 15px!important; }
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { font-size: 1.0625rem; }
  .hero-27-txt p.s-20 { margin-bottom: 20px!important; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 0!important; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/ 

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }
  .a2-txt { padding: 15px 20px 30px; }

  .a2-txt-quote { position: relative; margin-bottom: -40px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.125rem; }
  .a2-txt-author span { font-size: 0.975rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 15px; }

  #about-4 .txt-block h2 { padding: 0 5%; }
  #about-4 .txt-block p { margin-top: 15px; }
  #a4-2 { margin: 35px 3% 0; }

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 70px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 68%; top: 32%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 65%; top: 32%; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-7, .fbox-9 { padding: 40px 25px 35px; }
  .fbox-5 { padding: 45px 25px 40px; } 
  .row-cols-md-2 .fbox-7 { padding: 40px 40px 35px; }
  .fbox-8.fb-1 { padding: 0 25px 40px; }
  .fbox-8.bg--white-100 { padding: 45px 25px; }
  .fbox-8.fb-3 { padding: 45px 25px 0; }
  .fbox-12, .fbox-13 { padding: 32px 25px; } 
  #fb-12-1, #fb-13-1 { margin-top: 50px; }
  #features-12 .txt-block, #features-13 .txt-block { margin-bottom: 35px; }

  .fbox-14 { padding: 30px 25px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 30px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 170px; }

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 170px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 170px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 155px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 30px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 40px; margin: 0 0 50px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 40px; margin: 0 0 30px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 6% 30px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after{ font-size: 3.6rem; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.34615rem; }  /* 21px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 15px; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 15px; margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 8%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, 
  .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, .fbox-10 .fbox-txt p { padding: 0 4%; }

  .row-cols-md-2 .fbox-1 p, .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .row-cols-md-2 .fbox-6 p { padding: 0 8%; }
  .row-cols-md-2 .fbox-4 p { padding: 0 7%; }
  .fbox-5 .fbox-txt p, .rows-2 .fbox-11 p { padding: 0 5%; }
  .rows-3 .fbox-11 p { padding: 0 2%; }
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0 1%; }

  /* CONTENT */
  .ct-06 { padding-bottom: 70px; }
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 70px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 30px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 70px 20px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 70px 15px; }
  .bc-5-wrapper .section-overlay { padding: 70px 15px 0; }
  .cbox-6-wrapper { padding: 30px 20px; }
  .bc-6-wrapper .section-overlay { padding: 70px 15px; }
  .bc-6 { padding: 20px 15px 30px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; } 

  .bc-5-wrapper .section-title p { padding: 0 2%; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }

  .txt-block p.p-sm, .txt-block p { margin-bottom: 0.897435rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.12820rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg { margin-bottom: 6px; }

  .txt-block .btn { margin-top: 18px; }
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .bc-6-link.mt-15 { margin-top: 12px; }

  /* Content Box */
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 25px; margin: 0; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 2.45rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.05rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 10px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 22px; }

  /* Content Box Icon */ 
  .cbox-1.ico-10 span { right: 6px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 6px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.3rem; height: 2.3rem; font-size: 1.15rem; line-height: 2.05rem; }
  .ct-03 .cbox-2-ico { width: 1.55rem; height: 1.55rem; font-size: 1rem; line-height: 1.35rem; }
  .cbox-2-line { height: calc(100% - 74px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }
  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.35rem!important; margin-bottom: 20px; }
  .box-title span { display: block; position: relative; top:0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.35rem; }

  /* Content Box Text */ 
  .cbox-2-txt, .ct-03 .cbox-2-txt, .cbox-3-txt { margin-bottom: 20px; }
  .cbox-4-txt { margin-bottom: 30px; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 12px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 25px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: -1px; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 8px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 30px 25px 14px 25px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 10px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.125rem;line-height: 2.125rem!important; margin-right: 4px;
  }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 35px 20px; }
  .img-block-hidden, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -30px; }
  .ct-10 .img-block { margin-bottom: -40px; }

  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 35px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 25px); }
  .img-block-txt, .img-block-btn { margin-top: 35px; }
  .bc-5-img.bc-5-tablet.img-block-hidden { margin-bottom: -60px; }
  .bc-6-img { padding: 30px 15px 0; margin-bottom: 25px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 35px; padding: 40px 6%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 10% 18px; }

  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2, #sb-3-3 { margin-bottom: 35px; }
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-2-3, #sb-2-4 { padding-right: 12px; }
  #sb-4-3 { margin-bottom: 30px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 35px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 2.21153rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 1.98717rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.21153rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 5%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 35px; }
  .project-details h5 { margin-bottom: 25px; }
  .more-projects { margin-top: 45px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 40px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 35px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 35px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 35px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 40px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.1rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; padding: 0 15%; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 32px; }
  .rbox-2-txt img { max-height: 30px; }
  p.rbox-2-txt { line-height: 30px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.3rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.3rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.826923rem; padding: 0 3%; }  /* 28.5px */

  .review-1 { padding: 35px 25px 30px; }
  .review-2 { padding: 35px 20px 24px; }
  .review-3, .review-4, .review-5 { padding: 35px 25px 24px; }

  .review-txt { margin-top: -43px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 52px; height: 52px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 52px; height: 52px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 3px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.1875rem; }

  /* PRESS */
  #press-01, #press-02, #press-03, #press-04, #press-05, #press-06, #press-07 { margin-bottom: 25px; }
  .press-logo img { max-height: 72px; }
  .press-txt { padding: 0 1%; margin-top: 10px; }

  /* BRANDS */
  #brands-3:after { height: 75%; }

  .brands-title { padding: 0 4%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 25px; }
  .brands-title.mb-50 { margin-bottom: 30px; }

  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, 
  #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 25px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 18px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 18px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 13px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-2 .container { max-width: 100%; }
  .integrations-2-wrapper { padding: 70px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .in_tool_logo.ico-65 img { width: 55px; height: 55px; }
  .in_tool h6 { font-size: 1.125rem; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 48px; height: 48px; }
  .integrations-1-wrapper .in_tool { padding: 18px 22px; }
  .integrations-1-wrapper .in_tool.mb-30, .integrations-1-wrapper .it-7, .integrations-1-wrapper .it-8 { margin-bottom: 25px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.25rem; line-height: 1; margin-top: 3px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 4px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }
 
  /* PRICING */
  .pricing-1-table, .text-center .pricing-1-table { padding: 35px 40px 30px; }
  .pricing-2-table {  padding: 35px; }
  .pricing-3-table { padding: 30px 30px 35px; }
  .pricing-4-table { padding: 35px 30px; }

  .pricing-table-header h5, .pricing-table-header h4 { font-size: 1.47435rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1.05rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9375rem; }

  .pricing-3-table .price.mt-25 { margin-top: 20px; }

  .p-table p.btn-txt { margin: 10px 0 0 0; }

  /* Price */
  .price span { font-size: 2.564102rem; letter-spacing: -1.5px; } 
  .pricing-2-table.highlight .price span { font-size: 2.35rem; } 
  .pricing-3-table .price span { font-size: 4.102564rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 2.564102rem; } 

  .price sup { font-size: 1.9230769rem; top: -4px; }
  .pricing-3-table .price sup { font-size: 2.35rem; top: -18px; }
  .pricing-4-table .price sup { font-size: 1.9230769rem; top: -4px; right: 2px; }

  .pricing-3-table .price sup.coins { font-size: 2.35rem; top: -21px; letter-spacing: -2px; }

  .price sup.validity { font-size: 1.282051rem; }
  .pricing-3-table .price sup.validity { font-size: 1.282051rem; top: 0; margin-top: 20px; margin-bottom: 35px; }
  .pricing-4-table .price sup.validity { font-size: 1.282051rem; left: 4px; top: 0; }

  .text-center .price p, .text-center .pricing-2-table .price p { padding: 0; }
  .pricing-4-table .pricing-table-header p.p-lg { font-size: 0.9375rem; }
  .pricing-4-table .price p { margin-top: 15px; margin-bottom: 10px; }

  .pricing-1-table .pt-btn { margin-top: 10px; }
  .pricing-2-table .pt-btn, .pricing-3-table .pt-btn, .pricing-4-table .pt-btn { margin-top: 20px; }

  .pricing-2-table hr { margin-top: 20px; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 15px; }
  .pricing-features li, .pricing-4-table .pricing-features li { padding: 10px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  #pricing-4 .ext-toggle-btn { margin: 0 auto 30px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }

  .comp-table-rouded { padding: 10px 10px 0; }
  .table>:not(caption)>*>* { padding-top: 9px; padding-bottom: 9px; }
  .comp-table .table-responsive thead th { font-size: 0.9rem; }
  .comp-table .table-responsive tbody th { font-size: 0.815rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.85rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  .pbox { padding: 0; }
  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last { margin-bottom: 40px; }
  .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* TEAM */
  #team-1.pb-40, #team-2.pb-40 { padding-bottom: 30px; }
  .team-member.mb-40, .team-member.mb-50, .team-member.mb-60 { margin-bottom: 40px; }

  .team-member-photo { margin-bottom: 25px; }
  #team-1 .team-member-data, #team-2 .team-member-data { margin-left: 10px; }

  .team-member-data h6.s-20 { font-size: 1.34615rem; margin-bottom: 6px; }   /* 21px */
  .team-member-data p, .team-member-data p.p-md, .team-member-data p.p-lg { font-size: 1.175rem; }

  ul.tm-social, #team-1 ul.tm-social { top: 0; right: 10px; }
  .tm-social.ico-20 [class*="flaticon-"]:before, .tm-social.ico-20 [class*="flaticon-"]:after { font-size: 1.34615rem; }

  /* CAREERS */
  #careers-1 { padding-bottom: 40px; }
  .role-box { text-align: center; padding: 25px 40px; }
  .role-box-txt span, #careers-1 .role-box span { margin-bottom: 12px; }
  .role-box-txt h6 { line-height: 1.4; margin-bottom: 5px; }
  #careers-1 .role-box h6 { line-height: 1.4; margin-bottom: 6px; }
  .role-box-txt p { margin-bottom: 14px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 25px; }
  #role-page .inner-page-title span { margin-bottom: 20px; }
  #role-page .inner-page-title p { padding: 0; }
  .role-info hr, #role-page .cbox-6-wrapper { margin: 35px 0; }
  .txt-block.role-info .btn { margin-top: 30px; }

  /* BANNER */
  #banner-12, #banner-13 { margin-top: 0; margin-bottom: 25px; }
  #banner-9.py-70 { padding-top: 60px; padding-bottom: 60px; }

  #banner-1 .container, #banner-2 .container, #banner-3 .container,
  #banner-11 .container, #banner-12 .container, #banner-13 .container { max-width: 100%; }
  .banner-1-wrapper, .banner-2-wrapper, .banner-3-wrapper,
  .banner-11-wrapper, .banner-12-wrapper, .banner-13-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .banner-1-wrapper .banner-overlay, .banner-2-wrapper .banner-overlay, 
  .banner-3-wrapper .banner-overlay { padding: 55px 20px 65px; }
  .banner-1-wrapper .banner-overlay.sm-title { padding: 55px 20px 65px; }

  #banner-5 .banner-overlay.pt-80.pb-90, #banner-7 .banner-overlay.pt-80.pb-90, #banner-6 .banner-overlay, 
  #banner-8 .banner-overlay, #banner-14 .banner-overlay, #banner-15 .banner-overlay { padding-top: 55px; padding-bottom: 65px; }

  #banner-10 .banner-overlay { padding-top: 60px; padding-bottom: 60px; }
  .banner-11-wrapper .banner-overlay { overflow: hidden; padding: 55px 25px 0; border-radius: 0 0!important; } 
  .banner-12-wrapper .banner-overlay { overflow: visible; padding: 55px 25px 0; border-radius: 0 0!important; }
  .banner-13-wrapper .banner-overlay { overflow: visible; padding: 55px 25px 65px; border-radius: 0 0!important; } 
  .banner-16-wrapper .banner-overlay { padding: 35px 10%; } 
  
  .banner-5-txt {padding: 0 3%; }
  .banner-8-txt {padding: 0 5%; }
  .banner-12-txt, .banner-13-txt { margin-top: 0; padding-right: 0; }

  .banner-section .section-id { margin-bottom: 18px; }
  #banner-15.banner-section .section-id { margin-bottom: 22px; }

  .banner-section h2 { font-size: 1.826923rem!important; }
  .banner-section h3 { font-size: 1.698717rem!important; }
  .banner-1-txt h2 { margin-bottom: 12px; }
  .banner-2-txt h2, .banner-10-txt h2 { margin-bottom: 25px; }
  .banner-4-txt h2 { padding: 0 6%; margin-bottom: 22px; }
  .banner-7-txt h2 { margin-bottom: 25px; }
  .banner-10-txt h6 { margin-bottom: 5px; }
  .banner-10-txt h2 { font-size: 3.25rem!important; margin-bottom: 18px; }
  .banner-11-txt h2, .banner-12-txt h2, .banner-13-txt h2 { margin-bottom: 14px; }
  .banner-15-txt h2 { margin-bottom: 25px; }
  .banner-16-txt h4 { padding: 0 5%; margin-bottom: 10px; }
  
  .banner-section p.p-lg, .banner-section p.p-xl { font-size: 1.121794rem; margin-bottom: 20px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl,
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 20px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl, .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 12px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 12px;margin-bottom: 18px; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 10px; margin-bottom: 20px!important; }
  .banner-11-txt p { padding-right: 0; }
  .banner-14-txt p.p-lg { padding: 0 5%; margin-top: 18px; margin-bottom: 20px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 20px; }
  .banner-8-buttons { margin-top: 15px; padding: 0 4%; }
  .banner-15-txt .download-links { margin-top: 15px; }
  .banner-section .btns-group .btn:first-child { margin-right: 0; margin-left: 0; margin-bottom: 15px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 12px; }

  .banner-9-img { margin: 40px 2% 0; }
  .banner-10-img { margin: 40px 5% 0; }
  .banner-11-img { margin: 35px 2% -20px; }
  .banner-12-img { transform: rotate(0deg); margin: 35px 0 -40px; }

  /* DOWNLOAD */
  .release-data { padding-bottom: 25px; margin-bottom: 25px; } 
  span.version-data { font-size: 1.75rem; margin-right: 10px; }
  span.release-date span { font-size: 1.125rem; margin-left: 10px; }

  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 50px; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 20px; }
  #faqs-2 .inner-page-title { margin-bottom: 30px; }
  #faqs-3 .section-title { margin-bottom: 40px; }
  #faqs-4 .inner-page-title { margin-bottom: 40px; }

  #faqs-3 .section-title .contact-link { display: block; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 20px 0 22px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 23px; right: 0; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 22px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-2 .accordion-panel-item.mb-35, #faqs-3 .question.mb-35 { margin-bottom: 25px; }

  .faqs-section .accordion-thumb h5 { line-height: 1.35; padding-right: 8%; }
  .question h5, .accordion-panel-item h5 { line-height: 1.35; margin-bottom: 15px; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 { margin: 0 5px 10px; }
  .faqs-section .tabs-1 li { min-width: 100%; padding: 20px 0; margin: 0 0 20px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 45px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 15px; }
  .more-questions-txt { padding: 14px 20px; }
  .more-questions-txt p { font-size: 1.1rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 50px; padding-bottom: 40px; }  
  #newsletter-2 .container { max-width: 100%; }
  #newsletter-2 .newsletter-overlay { padding: 50px 8% 40px; margin: 0 -15px; border-radius: 0 0!important; }
  #newsletter-3, #newsletter-4 .newsletter-overlay { padding-top: 55px; padding-bottom: 60px; }

  #newsletter-4 .newsletter-txt, #newsletter-5 .newsletter-txt { padding: 0; }

  .newsletter-txt h2, #newsletter-4 .newsletter-txt h2 { margin-bottom: 25px; }
  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.666666rem; padding: 0; margin-bottom: 25px; }

  #newsletter-3 .newsletter-txt p { padding: 0 2%; }
  #newsletter-4 .newsletter-txt p { font-size: 1rem; padding: 0 2%; margin-bottom: 0; }
  #newsletter-5 .newsletter-txt p { margin-top: 15px; }

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form, 
  #newsletter-3 .newsletter-form, #newsletter-4 .newsletter-form { padding: 0 3%; }
  #newsletter-5 .newsletter-form { margin-top: 25px; padding: 0 3%; }

  .newsletter-section .form-control, #newsletter-1.newsletter-section .form-control, 
  #newsletter-5.newsletter-section .form-control { height: 52px; font-size: 1.0625rem; }
  .newsletter-section .btn, #newsletter-1 .btn, #newsletter-5.newsletter-section .btn { height: 52px; font-size: 1.0625rem; }

  /* BLOG */
  #bp-1-1, #bp-1-2 { margin-bottom: 35px; }
  .blog-post-img.mb-35 { margin-bottom: 30px; }
  .post-tag { font-size: 0.815rem; margin-bottom: 14px; }

  .blog-post-txt h6, .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 14px; }
  .blog-post-meta.mt-20, .blog-post-meta.mt-30 { margin-top: 14px; }

  /* Blog Listing */
  .wide-post { margin-bottom: 40px; }
  .wide-post h3.s-36, .wide-post h3.s-38, .wide-post h3.s-40, .square-post h4.s-34 { font-size: 1.217948rem; }
  .wide-post .blog-post-img { margin-bottom: 30px; }

  .square-post { margin-top: 10px; margin-bottom: 40px; }
  .square-post .blog-post-txt { padding: 40px 20px 35px; }

  .posts-category { margin-top: 10px; margin-bottom: 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 5%; margin-bottom: 20px; }
  .single-post-title .blog-post-meta.mt-35 { margin-top: 25px; }
  #single-post .post-meta-list .meta-list-divider { display: none!important; }
  #single-post .post-meta-list li { display: block!important; margin-top: 10px; }
  .post-inner-img { margin-top: 30px; margin-bottom: 30px; }

  .single-post-txt h5, .single-post-txt h4 { margin-top: 15px; margin-bottom: 18px; }

  .single-post-txt .blockquote p { font-size: 1.0625rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; line-height: 1.3; }

  .post-share-list { margin-top: 30px; padding-bottom: 40px; }

  .post-author { display: block!important; padding: 50px 0; }
  .post-author-avatar { display: block!important; text-align: left; margin-right: 0; margin-bottom: 20px; }
  .post-author-avatar img { width: 70px; height: 70px; }
  .post-author-txt h5 { margin-bottom: 10px; }

  .author-follow-btn { padding: 5px 12px; top: 135px; right: 10px; }

  .post-comments { padding-top: 50px; }
  .post-comments h5 { margin-bottom: 40px; }
  .comment-body { margin-left: 18px; }
  .post-comments img { width: 43px; height: 43px; }
  .comment-meta { margin-bottom: 8px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .post-comments #leave-comment h5 { margin-top: 50px; }

  .comment-form p { margin-bottom: 10px; }

  .comment-form { margin-top: 50px; }
  .comment-form .btn { margin-top: 0; }

  /* CONTACTS */
  #contacts-1 .section-title p { padding: 0 3%; }
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }

  #contacts-1 .contact-form .col-md-12 span { line-height: 1.4; margin-bottom: 15px; }
  .contact-form .form-control, .contact-form .form-select { height: 54px; }
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }

  #footer-10.pt-50 { padding-top: 0; }
  #footer-11.p-404.pt-50 { padding-top: 40px; padding-bottom: 40px; }

  .footer .col-sm-4, .footer .col-sm-6, .footer .col-sm-8 { width: 50%!important; float: left; }
  #footer-3.footer .col-sm-6.col-md-3 { width: 100%!important; float: none; }

  .footer-info, .footer-links, .footer-stores-badge { margin-bottom: 35px; }

  #footer-2 .footer-info { margin-bottom: 40px; }
  #footer-6 .footer-info, #footer-7 .footer-info { margin-top: 10px; margin-bottom: 0; }
  #footer-10 .footer-info { margin-bottom: 25px; padding-bottom: 35px; }
  #footer-8 .footer-links, #footer-10 .footer-links { margin-bottom: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 36px; }
  #footer-1 .footer-logo, #footer-5 .footer-logo, #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 24px; }
  #footer-10 img.footer-logo { max-height: 36px; }

  .footer h6 { font-size: 1.153846rem; margin-bottom: 18px; } /* 18px */

  .footer-mail-link { font-size: 1.06410rem; } /* 16.6px */
  .footer-mail-link span { top: 3px; right: 4px; }
  #footer-1 .footer-info .footer-mail-link { margin-top: 5px; }

  #footer-8 .foo-links li { display: block!important; padding-left: 0; }
  #footer-10 .foo-links li { padding: 0 8px; }

  .foo-links.ico-10 li span { top: 1px; right: 3px; }
  .foo-links li a span.ext-link { left: 2px; top: 0.5px; }

  .footer-stores-badge .store { margin-right: 6px; }

  .footer-form { margin-right: 5%; }
  #footer-9 .footer-form { margin-bottom: 0; }
  #footer-9 .footer-form h6 { padding-right: 5%; }
  .footer-form .form-control { font-size: 1.0625rem; }
  .footer-form .form-control, .footer-form .btn { height: 30px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.1rem; line-height: 26px!important; }

  .bottom-footer-socials { margin-top: 10px; }
  .bottom-footer-socials li { padding-left: 12px; }
  .bottom-footer-socials li:first-child { padding-left: 0; }

  .bottom-footer-list { margin-top: 3px; } 
  #footer-4 .bottom-footer-list li, #footer-9 .bottom-footer-list li { padding-left: 8px; }
  #footer-4 .bottom-footer-list li span, #footer-9 .bottom-footer-list li span { margin-right: 1px; }

  .footer-list-divider { top: 0; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { font-size: 1.125rem; line-height: 1.6666; padding: 0 3%; margin-top: 12px; }
  .page-hero-section h1 { line-height: 1.25; margin-bottom: 20px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 
  
  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 38px; }
  .login-page-logo img { max-height: 36px; }

  .register-form-title { margin-bottom: 25px; }
  .register-form-title h3 { font-size: 1.60256rem; }

  .separator-line, .register-page-form p.input-header, .form-data span, .register-page-form .btn-google { font-size: 1rem; }
  #signup .form-data span { font-size: 0.935rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 0; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .separator-line { margin: 20px 0; }

  .reset-form-title { margin-bottom: 16px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 15px; }

  .reset-page-wrapper { margin: 0 10px; }
  .reset-page-wrapper form {  padding: 35px 10px 10px; }
  #login .register-page-form, #signup .register-page-form { padding: 35px 20px; }
  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 15px 10px; }

  .reset-page-wrapper .form-control, 
  .register-page-form .form-control { height: 50px; font-size: 1.0625rem; padding: 5px 12px; margin-bottom: 20px; }
  
  #login .register-page-form .btn.submit { height: 50px; font-size: 1.0625rem; margin-top: 12px; }
  #signup .register-page-form .btn.submit { height: 50px; font-size: 1.0625rem; margin-top: 20px; }
  .reset-page-wrapper .btn.submit { height: 50px; font-size: 1.0625rem; }
  .btn-google img { width: 18px; height: 18px; top: -2px; right: 5px; }

  .btn-show-pass { top: 16px; }

  /* PAGE 404 */
  .page-404-txt { padding-top: 100px; padding-bottom: 60px; }
  .page-404-txt h2 { margin-bottom: 15px; } 
  .page-404-txt h6 { padding: 0 2%; line-height: 1.6; margin-bottom: 22px; }
  .page-404-img { padding: 0 6%; margin-bottom: 35px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 330px; margin: auto auto; }
  #modal-2 .modal-dialog { width: 310px; margin: auto auto; }
  #modal-3 .modal-dialog.modal-xl { width: 320px; margin: auto auto; }

  #modal-1.modal .btn-close { right: 10px!important; top: 10px!important; }

  #modal-2 .modal-body-content { padding: 22px 30px 5px; }
  #modal-3 .modal-body-content { padding: 30px 10px 20px; }

  .modal-body .newsletter-form { margin-top: 15px; }
  .modal .form-control, .modal .newsletter-form .btn { height: 48px; font-size: 1rem; }

  #modal-3 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }
  #modal-3 .request-form .form-control {  margin: 0 0 20px 0; }
  #modal-3 .request-form .btn { height: 48px; font-size: 1.0625rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 320.98px) { 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 87.5%; } 

  #stlChanger { display: none; }
  .ts-0 { padding-top: 80px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  .py-50 { padding-top: 30px; padding-bottom: 30px; }
  .py-60 { padding-top: 40px; padding-bottom: 40px; }
  .py-70 { padding-top: 40px; padding-bottom: 40px; }
  .py-80 { padding-top: 40px; padding-bottom: 40px; }
  .py-90 { padding-top: 50px; padding-bottom: 50px; }
  .py-100 { padding-top: 60px; padding-bottom: 60px; }

  .pt-50 { padding-top: 30px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 40px; }
  .pt-80 { padding-top: 40px; }
  .pt-90 { padding-top: 50px; }
  .pt-100 { padding-top: 60px; }

  .pb-50 { padding-bottom: 30px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 40px; }
  .pb-80 { padding-bottom: 40px; }
  .pb-90 { padding-bottom: 50px; }
  .pb-100 { padding-bottom: 60px; }

  .mb-40 { margin-bottom: 20px; }
  .mb-50 { margin-bottom: 30px; }
  .mb-60 { margin-bottom: 40px; }

  /* Backgroud Shape */
  .shape--01:after { height: 90%; top: 6.5%; }
  .shape--02:after { width: 140%; height: 90%; top: 6.5%; left: -20%; }
  .shape--03:after { width: 140%; height: 129%; top: -70px; left: -20%; }
  .shape--04:after { height: 92%; top: 0; }
  .shape--05:after { height: 94%; }
  .shape--06:after { height: 92%; }
  .shape--07:after { height: 80%; top: 15%; }

  ol.digit-list p { margin-bottom: 5px; }

  .font--jakarta h1, .font--jakarta h2 { line-height: 1.35; }
  .font--jakarta h3, .font--jakarta h4, .font--jakarta h5, .font--jakarta h6 { line-height: 1.4; }

  /* Font Size */
  .s-15, .s-16 { font-size: 1rem; }                                    /* 14px */
  .s-17 { font-size: 1.0625rem; }                                      /* 14.875px */
  .s-18, .s-19, .s-20, .s-21, .s-22, .s-23 { font-size: 1.285714rem; } /* 18px */
  .s-24, .s-26, .s-28 { font-size: 1.35714rem; }                       /* 19px */
  .s-30, .s-32, .s-34 { font-size: 1.5rem; }                           /* 21px */
  .s-36, .s-38, .s-40 { font-size: 1.57142rem; }                       /* 22px */  
  .s-42, .s-43, .s-44, .s-45, .s-46, .s-47 { font-size: 1.714285rem; } /* 24px */ 
  .s-48 { font-size: 1.85714rem; }                                     /* 26px */  
  .s-50, .s-52, .s-54, .s-56, .s-58, .s-60 { font-size: 1.92857rem; }  /* 27px */ 
  .s-62 { font-size: 2rem; }                                           /* 28px */   
  .s-64, .s-66, .s-68 { font-size: 2.07142rem; }                       /* 29px */
  .s-68 { font-size: 2.14285rem; }                                     /* 30px */

  /* Paragraphs */
  p.p-md, p.p-lg { font-size: 1.0625rem; }
  p.p-xl { font-size: 1.125rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.0625rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Button */
  .btn { font-size: 1rem; }
  .btn.btn-sm { font-size: 1rem; padding: 0.7rem 1.5rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.391175rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-left: 5px; margin-right: 5px; margin-bottom: 15px; }

  /* Button Text */
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.15rem; line-height: 0.45rem!important;top: 3.5px; }
  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 1.85rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.125rem; top: -8px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.125rem; top: -8px; margin-left: 0; }
  .btn.ico-left span { margin-right: 2px; }
  .btn.ico-right span { margin-left: 2px; }

  /* OS Button */
  .os-btn { width: 250px; min-width: 250px; padding: 14px 15px 10px; }
  .os-btn-ico { margin-right: 14px; }
  .os-btn-txt p { margin-top: 4px; }

  .btn-os.mb-15 { margin-bottom: 12px; }
  .btn-os.mr-15 { margin-right: 0; margin-bottom: 12px; }

  /* Store Badges */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 12px; }

  /* Watch Video Link */
  .watch-video { margin-top: 18px; }
  .watch-video-link { margin-right: 8px; }
  p.video-txt-lg { font-size: 1.0625rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 100px; height: 100px; top: -30px; left: calc(50% - 55px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -30px; left: calc(50% - 55px); }
  .ico-55 .shape-ico svg { width: 105px; height: 105px; top: -30px; left: calc(50% - 50px); }
  .ico-60 .shape-ico svg { width: 110px; height: 110px; top: -30px; left: calc(50% - 55px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 88px; height: 88px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after, 
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after, 
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 88px!important; }

  /* Vector Square Icons */
  .ico-square { width: 80px; height: 80px; }
  .ico-square [class*="flaticon-"]:before, 
  .ico-square [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 80px!important; }

  /* Section ID */
  .section-id.rounded-id { padding: 7px 20px; font-size: 0.8rem; margin-bottom: 25px; }

  /* Section Title */
  .section-title.mb-60 { margin-bottom: 32px; }
  .section-title.mb-70, .section-title.mb-80 { margin-bottom: 35px; }

  .section-title h2 { padding: 0 1%; }

  .section-title p.p-md, .section-title p.p-lg { margin-top: 12px; }
  .section-title p.p-xl { font-size: 1.125rem; margin-top: 12px; }
  .section-title p.s-20, .section-title p.s-21 { font-size: 1.285714rem; margin-top: 10px; }  /* 18px */
  .section-title p.p-md, .section-title p.p-lg { padding: 0; }

  .section-title .btns-group.mt-35, .section-title .stores-badge.mt-30 { margin-top: 20px; }
  .section-title .btns-group .btn { margin-left: 10px; margin-right: 10px; }  
  .section-title .btns-group .btn:first-child { margin-bottom: 12px; }

  /*------------------------------------------*/
  /*  PRELOAD ANIMATION    
  /*------------------------------------------*/

  #loading-center { height: 80px; width: 80px; margin-top: -40px; margin-left: -40px; }
  .loader { width: 80px; height: 80px; }
  .loader::after { border: 40px solid; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 24px; padding-left: 15px; }
  .blog-header .wsmobileheader .smllogo { margin-top: 30px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 32px; }
  .blog-header .smllogo img { max-height: 26px; }

  .wsmenu > .wsmenu-list { width: 250px; margin-right: -250px; }
  .wsactive .wsmobileheader { margin-right: 250px; }
  .overlapblackbg { width: calc(100% - 250px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 15px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 20px; height: 20px; font-size: 0.785rem; line-height: 18px; top: -1px; margin-left: 5px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu.icons-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.icons-menu.w-75 { padding: 6px 8px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 12px 9px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 42px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  .icons-menu-txt span { font-size: 0.885rem; }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { top: -25%; opacity: .16; }

  .hero-section h2 { margin-bottom: 13px; }
  .hero-section h2.s-48, .hero-section h2.s-50 { margin-bottom: 20px; }
  .hero-section p.p-md, .hero-section p.p-lg, .hero-section p.p-xl,
  .hero-section p.s-20, .hero-section p.s-22, .hero-section p.s-24 { margin-bottom: 23px; }
  .hero-section p.s-21 { font-size: 1.285714rem; margin-bottom: 22px; }
  .hero-section .btn.mt-15, .hero-section .btns-group.mt-15 { margin-top: 8px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 15px; }
  .hero-section .advantages li { display: block!important; margin-top: 3px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO LOGO */
  .hero-logo { margin-bottom: 28px; }
  .hero-logo img { max-height: 28px; }

  .hero-square-logo { margin-bottom: 10px; }
  .hero-square-logo img { max-height: 58px; }

  /* HERO APP LOGO */
  .hero-app-logo { margin-bottom: 25px; }
  .hero-app-logo img { max-height: 85px; }
  #hero-25 .hero-app-logo img { max-height: 85px; }

  /* HERO DIGITS */
  .hero-digits { margin-top: -5px; }
  #hero-9 .hero-digits { margin-top: 3px; }

  .hero-digits h2.statistic-number { font-size: 2.2rem; }
  .hero-digits h2.statistic-number span { font-size: 2.15rem; }

  /* HERO QUICK FORM */
  .hero-section .quick-form.mt-45, .hero-section .quick-form.mt-35 { margin-top: 0; }
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.15rem; height: 52px; }
  .hero-section .quick-form.form-half .form-control { height: 52px; font-size: 1.15rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 52px; font-size: 1.125rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -10px 0; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 50px; font-size: 1.0625rem; padding: 0 14px; }
  #hero-10-form .btn, #hero-22-form .btn { height: 50px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-1 */
  #hero-1, #hero-2 { padding-top: 70px; padding-bottom: 70px; }
  .hero-1-txt { padding: 0 3%; }
  .hero-1-img, .hero-2-img { margin-top: 30px; }

  /* HERO-3 */
  #hero-3 { padding-top: 70px; }
  .hero-3-txt { padding: 0 2%; }
  #hero-3:after { height: 8%; }
  .hero-3-img { margin-top: 30px; }

  /* HERO-4 */
  #hero-4 { padding-top: 70px; }
  #hero-4:after { height: 7%; }

  .hero-4-txt h2 { padding: 0 3%; }
  .font--inter .hero-4-txt h2 { padding: 0 2.4%; }
  .hero-4-txt p { padding: 0 5px; }
  .hero-4-img { margin-top: 30px; }

  /* HERO-5 */
  #hero-5 { padding-top: 70px; margin-bottom: 50px; }
  .hero-5-txt h2 { margin-bottom: 12px; }
  .hero-5-txt p.p-lg { margin-bottom: 20px; }
  .hero-5-img { margin-top: 35px; }

  /* HERO-6 */
  #hero-6 { padding-top: 0; }
  #hero-6 .container { max-width: 100%; }
  .hero-6-wrapper { padding: 65px 15px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-6-txt p { padding: 0 3%; }
  .hero-6-img { margin: 30px 1% 0; }

  /* HERO-7 */
  #hero-7 { padding-top: 70px; padding-bottom: 70px; }
  .hero-7-txt h2 { padding: 0 3%; }
  #hero-7 .quick-form { margin-left: 3%; margin-right: 3%; }
  .hero-7-img { margin-top: 30px; }

  /* HERO-8 */
  #hero-8 { padding-top: 70px; }
  .hero-8-txt { padding: 0 4%; }
  .hero-8-txt .hero-logo { display: none; }
  .hero-8-txt .hero-digits { padding: 0; }

  #hero-8-form { padding: 26px 12px; margin: 30px 2% 0; border-radius: 10px; }
  #hero-8-form h4 { margin-bottom: 12px; }
  #hero-8-form p { font-size: 1rem; margin-bottom: 15px; }
  #hero-8-form p.p-sm { font-size: 0.9375rem; padding: 0!important; }

  /* HERO-9 */
  #hero-9 { padding-top: 70px; }
  .hero-9-txt h2 { padding: 0 3%; line-height: 1.45; }
  .hero-9-txt h2 img { max-height: 48px; margin: 0 2px; top: -6px; }
  .hero-9-txt p.s-20 { font-size: 1rem; margin-top: 10px; }

  #hero-9 .quick-form { margin-left: 2%; margin-right: 2%; }
  .hero-9-img { margin: 30px 0 0; }

  /* HERO-10 */
  #hero-10 { padding-top: 70px; }
  #hero-10:after { height: 7%; }
  .hero-10-txt h2 { padding: 0 3%; }
  .hero-10-txt h4 { padding: 0 2%; margin-bottom: 23px; }
  .hero-10-img { margin-top: 30px; }

  /* HERO-11 */
   #hero-11 { padding-top: 70px; }
  .hero-11-txt p { padding: 0 5%; }
  .hero-11-img { margin: 30px 3% -50px; }

  /* HERO-12 */
  #hero-12 { padding-top: 70px; }
  #hero-12:after { height: 6%; }
  .hero-12-txt h2 { padding: 0 3%; }
  .font--inter .hero-12-txt h2 { padding: 0 2.4%; }
  .hero-12-txt p { padding: 0 5px; }
  .hero-12-img { margin-top: 30px; }

  /* HERO-13 */
  #hero-13 { padding-top: 70px; padding-bottom: 70px; } 
  .hero-13-txt h2 { padding: 0 3%; }
  .hero-13-img { margin: 30px 1% 0; }

  /* HERO-14 */
  #hero-14 { padding-top: 70px; margin-bottom: 20px; }
  .hero-14-txt h2.s-62 { font-size: 1.92857rem; margin-bottom: 15px; }
  .hero-14-txt p.s-21 { padding: 0%; margin-bottom: 25px; }
  #hero-14 .quick-form { margin-left: 4%; margin-right: 4%; }
  .hero-14-img { margin-top: 35px; margin-bottom: -20px; }
  .hero-14-txt .btn-txt { margin-top: 25px; }

  /* HERO-15 */
  #hero-15 { padding-top: 70px; }
  .hero-15-img { margin: 30px 1% 0; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; margin-bottom: 30px; }
  .hero-16-txt h2 { padding: 0 4%; }
  .hero-16-img { margin: 30px 0 -30px; }

  /* HERO-17 */
  #hero-17 { padding-top: 70px; }
  .hero-17-txt p { padding: 0 2%; }
  #hero-17 .quick-form { margin-left: 2%; margin-right: 2%; }
  #hero-17 #brands-1 { padding-top: 30px; } 

  /* HERO-18 */
  #hero-18 { padding-top: 70px; padding-bottom: 60px; }
  .hero-18-txt p { padding: 0 3%; }
  #hero-18 .quick-form { margin: 20px 2% 0; }
  .hero-18-img { margin-top: 30px; }

  /* HERO-19 */
  #hero-19 { padding-top: 70px; }
  .hero-19-txt h2 { padding: 0 3%; }

  /* HERO-20 */
  #hero-20 { padding-top: 70px; }
  .hero-20-txt { padding: 0 3%; }
  .hero-20-txt .hero-logo { display: none; }
  .hero-section .hero-20-txt h2.s-48 { margin-bottom: 18px; }

  /* HERO-21 */
  #hero-21 { padding-top: 70px; margin-bottom: 35px; }
  #hero-21 .container { max-width: 100%; }
  .hero-21-txt { margin-bottom: 40px; }
  .hero-21-txt h2 { margin-bottom: 18px; }

  .hero-21-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-21 .hero-overlay { padding: 40px 20px 0; }
  .hero-21-img { margin-bottom: -35px; }
  .hero-21-img .video-btn { top: calc(50% - 2px); }

  /* HERO-22 */
  #hero-22 { padding-top: 70px; }
  #hero-22-form { text-align: center; padding: 30px 15px 20px; margin: 0 1%; }
  #hero-22 .hero-logo { display: none; }
  #hero-22-form h4 { font-size: 1.57142rem; padding: 0; margin-bottom: 12px; }
  #hero-22-form p { font-size: 1.1rem; padding: 0 5%; margin-bottom: 15px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; margin-bottom: 20px; }
  .hero-23-txt { padding: 0 2%; }
  .hero-23-img { margin-top: 12px; margin-bottom: -20px; }

  /* HERO-24 */
  #hero-24 { padding-top: 70px; padding-bottom: 70px; }
  .hero-24-txt p.p-xl { padding: 0 2%; }
  #hero-24 .quick-form { margin-left: 2%; margin-right: 2%; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; padding-bottom: 70px; }
  .hero-25-txt p.p-lg { margin-bottom: 20px; }
  .hero-25-img { margin: 30px 0 0; }

  /* HERO-26 */
  #hero-26 { padding-top: 70px; }
  .hero-26-txt { padding: 0 5%; } 
  .hero-26-img { margin-top: 30px; }

  /* HERO-27 */
  #hero-27 .hero-overlay { padding-top: 70px; }
  .hero-27-txt h2 { font-size: 1.85rem; margin-bottom: 15px!important; }
  .hero-27-txt p.s-20, .hero-27-txt p.s-21 { font-size: 1.0625rem; }
  .hero-27-txt p.s-20 { margin-bottom: 20px!important; }
  .hero-27-txt .btns-group.mt-15 { margin-top: 0!important; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-2-title { margin-bottom: 30px; }
  .a2-txt { padding: 10px 20px 25px; }

  #a3-1 { margin-bottom: 20px; }

  .a2-txt-quote { position: relative; margin-bottom: -35px; }
  .a2-txt-quote.ico-40 [class*="flaticon-"]:before, .a2-txt-quote.ico-40 [class*="flaticon-"]:after { font-size: 4.25rem; }

  .a2-txt-author { margin-top: 10px; }
  .a2-txt-author { font-size: 1.125rem; }
  .a2-txt-author span { font-size: 0.975rem; margin-top: 1px; }

  #about-1 .txt-block h3.mb-30, .about-2-title h2.mb-30 { margin-bottom: 15px; }

  #about-4 .txt-block h2 { padding: 0 5%; }
  #about-4 .txt-block p { margin-top: 12px; }
  #a4-2 { margin: 30px 2% 0; }

  /* FEATURES */
  #features-14 .container { max-width: 100%; }
  .fbox-14-wrapper { padding: 60px 30px; margin: 0 -15px; border-radius: 0 0!important; } 

  #features-12.py-100.shape--bg:after, #features-13.py-100.shape--bg:after { height: 69%; top: 31%; }
  #features-12.pt-100.shape--bg:after, #features-13.pt-100.shape--bg:after { height: 65%; top: 32%; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5, .row-cols-md-2 .fb-3, .fbox-5.fb-3, 
  .fbox-5.fb-5, .rows-2 .fbox-7.fb-1, .rows-2 .fbox-7.fb-2, .rows-2 .fbox-7.fb-3, .rows-2 .fbox-9.fb-1, .rows-2 .fbox-9.fb-2, 
  .rows-2 .fbox-9.fb-3 { margin-bottom: 30px; }

  .rows-3 .fb-3.fbox-11-lst { margin-bottom: 30px; }

  .fbox-5.fb-2, .fbox-5.fb-4, .fbox-5.fb-6 { margin-bottom: 0; }

  .row-cols-md-3 .fbox-4, .row-cols-md-2 .fbox-4, .fbox-5, .fbox-8.bg--white-100 { padding: 35px 25px; } 
  .fbox-7, .fbox-9 { padding: 35px 25px 30px; }
  .row-cols-md-2 .fbox-7 { padding: 35px 35px 30px; }
  .fbox-8.fb-1 { padding: 0 25px 35px; }
  .fbox-8.fb-3 { padding: 40px 25px 0; }
  .fbox-12, .fbox-13 { padding: 30px 25px; } 
  #fb-12-1, #fb-12-2, #fb-12-3, #fb-13-1, #fb-13-2, #fb-13-3 { margin-bottom: 30px; }
  #fb-12-1, #fb-13-1 { margin-top: 50px; }
  #features-12 .txt-block, #features-13 .txt-block { margin-bottom: 30px; }

  .fbox-14 { padding: 30px 25px 25px; } 
  .fbox-14.fb-1, .fbox-14.fb-2 { margin-bottom: 25px; }

  .fbox-img.h-135 img, .fbox-img.h-140 img, .fbox-img.h-145 img, .fbox-img.h-150 img, .fbox-img.h-155 img, 
  .fbox-img.h-160 img, .fbox-img.h-165 img, .fbox-img.h-170 img, .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 160px; } 

  .fbox-2 .fbox-img.h-135 img, .fbox-2 .fbox-img.h-140 img, .fbox-2 .fbox-img.h-145 img, .fbox-2 .fbox-img.h-150 img, 
  .fbox-2 .fbox-img.h-155 img, .fbox-2 .fbox-img.h-160 img, .fbox-2 .fbox-img.h-165 img, .fbox-2 .fbox-img.h-170 img, 
  .fbox-2 .fbox-img.h-175 img, .fbox-2 .fbox-img.h-180 img { max-height: 160px; }

  .fbox-3 .fbox-img.h-135 img, .fbox-3 .fbox-img.h-140 img, .fbox-3 .fbox-img.h-145 img, .fbox-3 .fbox-img.h-150 img, 
  .fbox-3 .fbox-img.h-155 img, .fbox-3 .fbox-img.h-160 img, .fbox-3 .fbox-img.h-165 img, .fbox-3 .fbox-img.h-170 img, 
  .fbox-3 .fbox-img.h-175 img, .fbox-3 .fbox-img.h-180 img { max-height: 160px; }

  .fbox-4 .fbox-img.h-135 img, .fbox-4.fbox-img.h-140 img, .fbox-4 .fbox-img.h-145 img, .fbox-4 .fbox-img.h-150 img, 
  .fbox-4 .fbox-img.h-155 img, .fbox-4 .fbox-img.h-160 img, .fbox-4 .fbox-img.h-165 img, .fbox-4 .fbox-img.h-170 img, 
  .fbox-4 .fbox-img.h-175 img { max-height: 145px; }

  .fbox-1 .fbox-img, .row-cols-md-2 .fbox-1 .fbox-img, .fbox-4 .fbox-img, .row-cols-md-2 .fbox-4 .fbox-img { margin-bottom: 25px; }

  .fbox-2 .fbox-img.h-135, .fbox-2 .fbox-img.h-140, .fbox-2 .fbox-img.h-145, 
  .fbox-2 .fbox-img.h-150, .fbox-2 .fbox-img.h-155, .fbox-2 .fbox-img.h-160, 
  .fbox-2 .fbox-img.h-165, .fbox-2 .fbox-img.h-170, .fbox-2 .fbox-img.h-175, 
  .fbox-2 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 45px; }

  .fbox-3 .fbox-img.h-135, .fbox-3 .fbox-img.h-140, .fbox-3 .fbox-img.h-145, 
  .fbox-3 .fbox-img.h-150, .fbox-3 .fbox-img.h-155, .fbox-3 .fbox-img.h-160, 
  .fbox-3 .fbox-img.h-165, .fbox-3 .fbox-img.h-170, .fbox-3 .fbox-img.h-175, 
  .fbox-3 .fbox-img.h-180 { padding-top: 35px; margin: 0 0 25px; }

  .fbox-5.fb-1 .fbox-5-img, .fbox-5.fb-2 .fbox-5-img, .fbox-5.fb-3 .fbox-5-img, 
  .fbox-5.fb-4 .fbox-5-img, .fbox-5.fb-5 .fbox-5-img, .fbox-5.fb-6 .fbox-5-img { margin: 0 3% 25px; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-ico.ico-square { margin-bottom: 18px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, 
  .fbox-wrapper .ico-60 [class*="flaticon-"]:after,
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:before, 
  .fbox-10 .fbox-title.ico-35 [class*="flaticon-"]:after { font-size: 3.6rem; }

  .features-section h6.s-18, .features-section h6.s-20, .features-section h6.s-22 { font-size: 1.35714rem; }  /* 19px */
  .features-section h6, .fbox-txt h5, .rows-2 .fbox-11 h6, .fbox-wrapper .row-cols-md-2 h6 { margin-bottom: 12px; }

  .fbox-10 .fbox-title { margin: 0 auto; }
  .fbox-10 .fbox-title span { float: none; line-height: 1!important; margin-right: 0; }
  .fbox-10 .fbox-title h6 { display: block; line-height: 1!important; margin-top: 10px; margin-bottom: 15px; }
  .fbox-14 h5 { padding: 0 5%; margin-bottom: 12px; }

  .row-cols-md-3 .fbox-1 p, .row-cols-md-3 .fbox-2 p, 
  .row-cols-md-3 .fbox-3 p, .row-cols-md-3 .fbox-6 p, .fbox-10 .fbox-txt p { padding: 0 3%; }

  .row-cols-md-2 .fbox-1 p, .row-cols-md-2 .fbox-2 p, .row-cols-md-2 .fbox-3 p, .row-cols-md-2 .fbox-6 p { padding: 0 8%; }
  .row-cols-md-2 .fbox-4 p, .fbox-5 .fbox-txt p, .rows-2 .fbox-11 p { padding: 0 5%; }
  .row-cols-lg-3 .fbox-10 .fbox-txt p { padding: 0; }
  .rows-3 .fbox-11 p { padding: 0 4%; }

  /* CONTENT */
  .ct-06 { padding-bottom: 60px; }
  .ct-10 .section-overlay { margin-bottom: 40px; }
  .ct-top { margin-bottom: 60px; }

  .ct-13 .txt-block, .ct-13 .img-block { margin-bottom: 30px; }
  .ct-13 .txt-block.ml-block, .ct-13 .img-block.ml-block { margin-bottom: 0; }

  /* Content Box Wrapper */
  .content-section.ws-wrapper { margin: 0 -15px; }
  .bc-1-wrapper .section-overlay, .bc-4-wrapper .section-overlay { padding: 60px 18px; }
  .bc-2-wrapper .section-overlay, .bc-3-wrapper .section-overlay { padding: 60px 14px; }
  .bc-5-wrapper .section-overlay { padding: 60px 14px 0; }
  .bc-6-wrapper .section-overlay { padding: 60px 14px; } 
  .bc-6 { padding: 20px 15px 25px; }
  .bc-6-top { margin-bottom: 30px; }
  .cbox-6-wrapper { padding: 28px 18px; }

  .bc-1-wrapper, .bc-2-wrapper, .bc-3-wrapper, .bc-4-wrapper, .bc-5-wrapper, .bc-6-wrapper { border-radius: 0 0!important; } 

  .bc-5-wrapper .section-title p { padding: 0 3%; }

  .bc-2-txt h2, .bc-3-txt h2, .bc-4-txt h2, .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .txt-block h5 { margin-bottom: 16px; }
  h5.h5-title { margin-top: 16px; margin-bottom: 16px; }
  .cbox-2-txt h5, .cbox-3-txt h5, .bc-6-txt h5 { margin-bottom: 12px; }
  
  .txt-block p, .txt-block p.p-md, .txt-block p.p-lg { margin-bottom: 0.85714rem; }
  .txt-block p.s-20, .txt-block p.s-21 { font-size: 1.14285rem; }
  .cbox-5-fea p, .txt-block p.video-txt-sm, .txt-block p.btn-txt, .txt-block p.btns-group-txt,
  .txt-block p.compatibility { margin-bottom: 0; }
  .txt-block p.video-txt-lg { margin-bottom: 6px; }

  .txt-block .btn { margin-top: 16px; } 
  .txt-block .stores-badge.mt-15, .ws-wrapper .stores-badge.mt-15 { margin-top: 8px; } 
  .txt-block .btns-group .btn.btn--transparent { margin-top: 0; } 
  .bc-6-link.mt-15 { margin-top: 12px; }
  .txt-block-tra-link.mt-25 { margin-top: 16px; }

  /* Content Box */
  .txt-box { margin-bottom: 15px; } 
  .right-column .cbox-5 { margin-bottom: 40px; }
  .cbox-5-content { padding: 25px; margin: 0 1%; }
  .cbox-5-content .divider { margin-top: 22px; margin-bottom: 22px; }

  .txt-block .cbox-5-title h2 { font-size: 2.45rem; margin-bottom: 10px; }
  .cbox-5-title .statistic-number sup { font-size: 2.05rem; top: -5px; right: 6px; }

  .cbox-5-fea { padding: 15px 20px; margin-top: 20px; }
  .txt-block .cbox-5-fea h4 { font-size: 1.625rem; }
  .cbox-5-txt .btn { margin-top: 10px; }

  #cb-6-1, #cb-6-2, #cb-6-3 { margin-bottom: 20px; }

  /* Content Box Icon */
  .cbox-1.ico-10 span { right: 5px; }
  .cbox-1.ico-15 span { top: 0.5px; right: 5px; }
  .cbox-1.ico-15 [class*="flaticon-"]:before, .cbox-1.ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

  .cbox-2 .ico-wrap { margin-right: 1rem; }
  .cbox-2-ico { width: 2.15rem; height: 2.15rem; font-size: 1.0625rem; line-height: 1.85rem; }
  .ct-03 .cbox-2-ico { width: 1.65rem; height: 1.65rem; font-size: 1rem; line-height: 1.4rem; }
  .cbox-2-line { height: calc(100% - 70px); }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; }
  .cbox-3 .cbox-3-ico { top: 0; margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3rem; }

  .cbox-4 { text-align: center; }
  .box-title h6, .box-title h5 { display: block; line-height: 1!important; margin-bottom: 15px; }

  .img-block-txt .box-title h6, 
  .img-block-txt .box-title h5 { display: inline-block; line-height: 2.5rem!important; margin-bottom: 20px; }

  .box-title span { display: block; position: relative; top: 0; margin-right: 0; margin-bottom: 10px; }
  .img-block-txt .box-title span { display: inline-block; top: 10px; margin-right: 10px; margin-bottom: 0; }
  .box-title span[class*="flaticon-"]:before, .box-title span[class*="flaticon-"]:after { font-size: 3rem; }

  .img-block-txt .box-title span[class*="flaticon-"]:before, 
  .img-block-txt .box-title span[class*="flaticon-"]:after { font-size: 2.5rem; }

  /* Content Box Text */ 
  .cbox-2-txt, .cbox-3-txt, .cbox-4-txt { margin-bottom: 25px; }
  .ct-03 .cbox-2-txt { margin-bottom: 20px; }

  /* Content Box Typography */
  .cbox-1-txt p { margin-bottom: 5px; }
  .cbox-6 h6 { margin-bottom: 10px; }

  /* Accordion */
  .txt-block .accordion-1 .accordion-item { padding: 18px 20px; }
  .txt-block .accordion-2 .accordion-item { padding: 10px 0 20px; }

  .txt-block .accordion-1 .accordion-item .accordion-thumb:before { font-size: 0.95rem; top: -1px; }
  .txt-block .accordion-2 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-2 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: 7px; }

  .txt-block .accordion-1 .accordion-item.is-active { padding: 25px 20px 9px 20px; }
  .txt-block .accordion-2 .accordion-item.is-active { padding: 15px 0 20px; }

  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 22px; }
  .txt-block .accordion-2 .accordion-thumb h5 { padding-left: 10px; padding-right: 0; }

  .accordion-2 .accordion-thumb span[class*="flaticon-"]:before,
  .accordion-2 .accordion-thumb span[class*="flaticon-"]:after { font-size: 1.75rem; top: 6px; }

  /* Tabs */
  .tabs-1 li { font-size: 1.125rem; } 
  #tabs-1 .tab-content .img-block { margin-bottom: 30px; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 26px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2rem;line-height: 2rem!important; margin-right: 3px; 
  }

  /* Content Box Shapes */
  .cbox-5-shape-1, .cbox-5-shape-2 { display: none; }

  /* Content Images */
  .img-block.img-block-bkg img { padding: 30px 15px; }
  .img-block-hidden, .bc-5-img.bc-5-dashboard.img-block-hidden { margin-bottom: -30px; }
  .ct-10 .img-block { margin-bottom: -40px; }
  .ct-01 .img-block, .ct-02 .img-block, .ct-03 .img-block.right-column, .ct-03 .img-block.left-column { margin: 0 2% 30px; }
  .ct-01 .img-block.j-img, .ct-02 .img-block.j-img { margin-left: 0%; margin-right: 0; }
  .ct-04 .img-block, .ct-05 .img-block, .ct-06 .img-block, .ct-07 .img-block- { margin-bottom: 30px; }
  .ct-06 .img-block, .ct-07 .img-block { margin-top: -80px; margin-bottom: 40px; }

  .img-block-hidden .video-btn, .bc-5-img.bc-5-dashboard.img-block-hidden .video-btn { top: 50%; }
  .bc-5-img.bc-5-tablet.img-block-hidden .video-btn { top: calc(50% - 20px); }
  .img-block-txt, .img-block-btn { margin-top: 30px; }

  .bc-1-wrapper .img-block, .bc-2-wrapper .img-block, .bc-2-img, .bc-3-img, .bc-4-img { margin-bottom: 30px; }
  .bc-5-img.bc-5-tablet.img-block-hidden { margin-bottom: -50px; }
  .bc-6-img { padding: 25px 15px 0; margin-bottom: 20px; }
  .ct-11 .img-block, .ct-12 .img-block { margin-bottom: 30px; padding: 40px 5%; }

  /* STATISTIC */
  .sb-rounded .statistic-block { text-align: center; padding: 25px; }
  #statistic-5 .statistic-digit { padding-bottom: 18px; margin: 0 12% 18px; }
  #sb-1-1, #sb-1-2, #sb-3-1, #sb-3-2, #sb-3-3 { margin-bottom: 30px; }
  #sb-2-1, #sb-2-2, #sb-2-3, #sb-2-4 { width: 50%; }
  #sb-2-3, #sb-2-4 { padding-right: 10px; }
  #sb-4-1, #sb-4-2, #sb-4-3 { margin-bottom: 25px; }
  #sb-5-1, #sb-5-2 { margin-bottom: 30px; }

  .statistic-1-wrapper .statistic-block-digit { margin-bottom: 12px; }

  h2.statistic-number { font-size: 2.125rem; line-height: 1; letter-spacing: -0.5px; }
  .txt-block-stat h2.statistic-number { font-size: 2rem; }
  #statistic-1 h2.statistic-number { letter-spacing: -0.5px; }
  #sb-2-1 h2.statistic-number, #sb-2-2 h2.statistic-number { margin-bottom: 12px; }
  #statistic-5 .statistic-digit h2 { font-size: 2.125rem; letter-spacing: -0.5px; }

  #sb-2-3 .statistic-block p.p-sm, #sb-2-4 .statistic-block p.p-sm { margin-top: 12px; }
  .txt-block-stat .statistic-block p { font-size: 0.95rem; }
  #statistic-5 .statistic-txt p { padding: 0 8%; }

  /* PROJECTS */
  #pt-1-1, #pt-1-2, #pt-1-3, .inner-page-hero #pt-1-3, .inner-page-hero #pt-1-4, .inner-page-hero #pt-1-5 { margin-bottom: 35px; }
  .project-details h5 { margin-bottom: 20px; }
  .more-projects { margin-top: 40px; }

  /* PROJECT DETAILS */
  .single-project .project-title { margin-bottom: 35px; }
  .project-title h2 { padding-right: 0; padding-bottom: 25px; margin-bottom: 25px; }
  .project-data p { margin-bottom: 5px; }

  .project-priview-img.mb-50 { margin-bottom: 30px; }
  .project-inner-img .top-img { margin-bottom: 30px; }
  .project-inner-img.mt-50 { margin-top: 30px; }
  .project-txt h5.mt-35.mb-35 { margin-top: 20px; margin-bottom: 20px; }
  .project-txt h5.mt-50.mb-35 { margin-top: 30px; margin-bottom: 20px; }

  .project-description .more-projects { margin-top: 40px; }
  .project-description .more-projects span { top: 1px; left: 4px; }
  .project-description .more-projects.ico-25 [class*="flaticon-"]:before, 
  .project-description .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title h5 { font-size: 1.125rem; padding: 0 15%; }
  .rbox-1-img { margin-bottom: 15px; }
  .rbox-1-img img { max-height: 30px; }
  .rbox-2-txt img { max-height: 28px; }
  p.rbox-2-txt { line-height: 28px; margin-top: 8px; }

  .rbox-1 .star-rating { padding: 5px 16px; }
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }

  .rbox-1 .star-rating span { top: 2px; }
  .rbox-1 .ico-10 [class*="flaticon-"]:before, .rbox-1 .ico-10 [class*="flaticon-"]:after { font-size: 0.9rem; } 
  .rbox-1 .ico-15 [class*="flaticon-"]:before, .rbox-1 .ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; } 
  .rbox-2 .ico-25 [class*="flaticon-"]:before, .rbox-2 .ico-25 [class*="flaticon-"]:after { font-size: 1.225rem; } 

  /* TESTIMONIALS */
  #reviews-4 .txt-block, #reviews-5 .txt-block { text-align: center; }
  #reviews-4 .txt-block h2, #reviews-5 .txt-block h2 { font-size: 1.82142rem; padding: 0 2%; }  /* 25.5px */

  .review-1 { padding: 30px 30px 24px; }
  .review-2 { padding: 35px 15px 24px; }
  .review-3, .review-4, .review-5 { padding: 35px 25px 24px; }

  .review-txt { margin-top: -40px; }
  .review-txt h6 { margin-bottom: 10px; }
  .review-3 .review-txt p { font-size: 1rem; }  

  .review-1 .author-data, .review-2 .author-data, 
  .review-3 .author-data, .review-4 .author-data, .review-5 .author-data { margin-top: 20px; }

  .review-avatar img, .owl-item .review-avatar img { width: 45px; height: 45px; }
  .review-2 .review-avatar img, .review-3 .review-avatar img, .review-5 .review-avatar img { width: 45px; height: 45px; }

  .review-author { padding: 0 0 0 12px; }
  .review-1 .review-author, .review-2 .review-author, .review-3 .review-author, .review-5 .review-author { margin-top: 2px; }

  .review-author h6 { margin-bottom: 8px; }
  .review-1 .review-author h6, .review-2 .review-author h6, .review-3 .review-author h6, 
  .review-4 .review-author h6, .review-5 .review-author h6 { font-size: 1.225rem; }

  /* PRESS */
  #press-01, #press-02, #press-03, #press-04, #press-05, #press-06, #press-07 { margin-bottom: 25px; }
  .press-logo img { max-height: 70px; }
  .press-txt { padding: 0 1%; margin-top: 8px; }

  /* BRANDS */
  #brands-3:after { height: 85%; }

  .brands-title { padding: 0 3%; } 
  .brands-title.mb-35, .brands-title.mb-40 { margin-bottom: 20px; }
  .brands-title.mb-50 { margin-bottom: 25px; }

  .brands-title h5.s-19 { font-size: 1.21428rem; }
  .brands-title h4, .brands-title h3, .brands-title h2 { margin-bottom: 8px; }
  .brands-title p.p-md, .brands-title p.p-lg { font-size: 1rem; }

  .brands-3-wrapper, .brands-3-wrapper.brands-3-static { padding: 25px 15px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-2-4, #brand-3-1, #brand-3-2, #brand-4-1, #brand-4-2, #brand-4-3, 
  #brand-4-4, #brand-4-5, #brand-4-6, #brand-4-7, #brand-4-8, #brands-4 .brand-logo.mb-40 { margin-bottom: 25px; }
  #brand-3-5 { display: none; }

  #brands-1 .brands-carousel-6 .brand-logo, #brands-1 .brands-carousel-5 .brand-logo { padding: 0 15px; }   
  #brands-1 .justify-content-center .brand-logo, #brands-4 .brand-logo { padding: 0 15px; }   
  #brands-2 .brand-logo, #brands-3 .brand-logo, #brands-3 .brands-3-static .brand-logo { padding: 0 11px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-2 .container { max-width: 100%; }
  .integrations-2-wrapper { padding: 60px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .in_tool_logo.ico-65 img { width: 55px; height: 55px; }
  .in_tool h6 { font-size: 1.125rem; }

  #integrations-1 .in_tool-logo.ico-60 img { width: 48px; height: 48px; }
  .integrations-1-wrapper .in_tool { padding: 15px 20px; }
  .integrations-1-wrapper .in_tool.mb-30, .integrations-1-wrapper .it-7, .integrations-1-wrapper .it-8 { margin-bottom: 20px; }
  .integrations-1-wrapper .in_tool-txt h6 { font-size: 1.25rem; line-height: 1; margin-top: 5px; }
  .integrations-1-wrapper .in_tool-txt p { font-size: 1.0625rem; margin-top: 4px; }

  .integrations-section .more-btn.mt-60, .integrations-section .more-btn.mt-70 { margin-top: 30px; }
 
  /* PRICING */
  .p-table { margin-bottom: 30px; }
  .pricing-1-table, .text-center .pricing-1-table { padding: 35px 30px 30px; }
  .pricing-2-table { padding: 35px 30px; }
  .pricing-3-table { padding: 30px 30px 35px; }
  .pricing-4-table { padding: 25px 30px; }

  .pricing-table-header h5, .pricing-table-header h4 { font-size: 1.4285714rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9rem; }
  .p-table p.btn-txt { margin: 10px 0 0 0; }

  /* Price */
  .pricing-3-table .price.mt-25 { margin-top: 20px; }

  .price span { font-size: 2.85rem; letter-spacing: -1.5px; } 
  .pricing-2-table.highlight .price span { font-size: 2.6rem; } 
  .pricing-3-table .price span { font-size: 4.285714rem; letter-spacing: -3px; } 
  .pricing-4-table .price span { font-size: 2.85rem; } 

  .price sup { font-size: 2.4rem; top: -2px; }
  .pricing-3-table .price sup { font-size: 2.5rem; top: -16px; }
  .pricing-4-table .price sup { font-size: 2.4rem; top: -2px; }

  .pricing-3-table .price sup.coins { font-size: 2.5rem; top: -19px; letter-spacing: -2px; }

  .price sup.validity { font-size: 1.285714rem; }
  .pricing-3-table .price sup.validity { font-size: 1.285714rem; top: 0; margin-top: 20px; margin-bottom: 35px; }
  .pricing-4-table .price sup.validity { font-size: 1.285714rem; left: 4px; top: 0; }

  .text-center .price p, .text-center .pricing-2-table .price p { padding: 0; }
  .pricing-4-table .price p { margin-top: 15px; margin-bottom: 10px; }

  .pricing-1-table .pt-btn, .pricing-2-table .pt-btn, .pricing-3-table .pt-btn, 
  .pricing-4-table .pt-btn,  .pricing-5-table .pt-btn, .pricing-6-table .pt-btn { margin-top: 20px; }

  .pricing-2-table hr { margin-top: 20px; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 12px; }
  .pricing-features li, .pricing-4-table .pricing-features li { padding: 9px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 20px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  #pricing-4 .ext-toggle-btn { margin: 0 auto 25px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }

  .comp-table-rouded { padding: 10px 10px 0; }
  .table>:not(caption)>*>* { padding-top: 9px; padding-bottom: 9px; }
  .comp-table .table-responsive thead th { font-size: 0.9rem; }
  .comp-table .table-responsive tbody th { font-size: 0.815rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.85rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last { margin-bottom: 40px; }
  .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 14px; }

  /* TEAM */
  #team-1.pb-40, #team-2.pb-40 { padding-bottom: 25px; }
  .team-member.mb-40, .team-member.mb-50, .team-member.mb-60 { margin-bottom: 35px; }

  .team-member-photo { margin-bottom: 22px; }
  #team-1 .team-member-data, #team-2 .team-member-data { margin-left: 10px; }

  .team-member-data h6.s-20 { font-size: 1.35714rem; margin-bottom: 6px; }   /* 19px */
  .team-member-data p, .team-member-data p.p-md, .team-member-data p.p-lg { font-size: 1.15rem; }

  ul.tm-social, #team-1 ul.tm-social { top: 0; right: 10px; }
  .tm-social.ico-20 [class*="flaticon-"]:before, .tm-social.ico-20 [class*="flaticon-"]:after { font-size: 1.375rem; }

  /* CAREERS */
  #careers-1 { padding-bottom: 30px; }
  .role-box { text-align: center; padding: 25px 30px; }
  .role-box-txt span, #careers-1 .role-box span { margin-bottom: 10px; }
  #careers-1 .role-box h6 { line-height: 1.4; margin-bottom: 4px; }
  .role-box-txt h6 { line-height: 1.4; margin-bottom: 5px; }
  .role-box-txt p { margin-bottom: 14px; }

  /* CAREER ROLE */
  #role-page .inner-page-title { margin-bottom: 20px; }
  #role-page .inner-page-title span { margin-bottom: 20px; }
  #role-page .inner-page-title p { padding: 0; }
  .role-info hr, #role-page .cbox-6-wrapper { margin: 25px 0; }
  .txt-block.role-info .btn { margin-top: 25px; }

  /* BANNER */
  #banner-12, #banner-13 { margin-top: 0; margin-bottom: 25px; }
  #banner-9.py-70 { padding-top: 60px; padding-bottom: 60px; }

  #banner-1 .container, #banner-2 .container, #banner-3 .container,
  #banner-11 .container, #banner-12 .container, #banner-13 .container { max-width: 100%; }
  .banner-1-wrapper, .banner-2-wrapper, .banner-3-wrapper,
  .banner-11-wrapper, .banner-12-wrapper, .banner-13-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .banner-1-wrapper .banner-overlay, .banner-2-wrapper .banner-overlay, 
  .banner-3-wrapper .banner-overlay { padding: 55px 15px 65px; }
  .banner-1-wrapper .banner-overlay.sm-title { padding: 50px 20px 60px; }

  #banner-5 .banner-overlay.pt-80.pb-90, #banner-7 .banner-overlay.pt-80.pb-90, #banner-6 .banner-overlay, 
  #banner-8 .banner-overlay, #banner-14 .banner-overlay, #banner-15 .banner-overlay { padding-top: 50px; padding-bottom: 60px; }

  #banner-10 .banner-overlay { padding-top: 60px; padding-bottom: 60px; }
  .banner-11-wrapper .banner-overlay { overflow: hidden; padding: 50px 25px 0; border-radius: 0 0!important; } 
  .banner-12-wrapper .banner-overlay{ overflow: visible; padding: 50px 25px 0; border-radius: 0 0!important; } 
  .banner-13-wrapper .banner-overlay { overflow: visible; padding: 50px 25px 60px; border-radius: 0 0!important; } 
  .banner-16-wrapper .banner-overlay { padding: 30px 10%; } 
  
  .banner-5-txt {padding: 0; }
  .banner-8-txt {padding: 0 5%; }
  .banner-12-txt, .banner-13-txt { margin-top: 0; padding-right: 0; }

  .banner-section .section-id { margin-bottom: 18px; }
  #banner-15.banner-section .section-id { margin-bottom: 22px; }

  .banner-section h2, .banner-section h3 { font-size: 1.82142rem!important; }
  .banner-1-txt h2 { margin-bottom: 12px; }
  .banner-2-txt h2, .banner-10-txt h2 { margin-bottom: 25px; }
  .banner-4-txt h2 { padding: 0 6%; margin-bottom: 22px; }
  .banner-7-txt h2 { margin-bottom: 22px; }
  .banner-10-txt h6 { margin-bottom: 5px; }
  .banner-10-txt h2 { font-size: 3.25rem!important; margin-bottom: 18px; }
  .banner-11-txt h2, .banner-12-txt h2, .banner-13-txt h2 { margin-bottom: 14px; }
  .banner-15-txt h2 { margin-bottom: 25px; }
  .banner-16-txt h4 { padding: 0 5%; margin-bottom: 10px; }
  
  .banner-section p.p-lg, .banner-section p.p-xl { font-size: 1.142857rem; margin-bottom: 20px; }
  .sm-title .banner-1-txt p.p-lg, .sm-title .banner-1-txt p.p-xl,
  .banner-6-txt p.p-lg, .banner-6-txt p.p-xl { margin-top: 12px; margin-bottom: 20px!important; }
  .banner-3-txt p.p-lg, .banner-3-txt p.p-xl { margin-top: 10px; margin-bottom: 18px; }
  .banner-5-txt p.p-lg, .banner-5-txt p.p-xl { margin-top: 12px; }
  .banner-5-txt p.s-21, .banner-5-txt p.s-22 { margin-top: 11px;margin-bottom: 18px; }
  .banner-9-txt p.p-lg, .banner-9-txt p.p-xl { margin-top: 10px; margin-bottom: 20px!important; }
  .banner-11-txt p { padding-right: 0; }
  .banner-14-txt p.p-lg { padding: 0 5%; margin-top: 18px; margin-bottom: 18px; }

  .banner-5-txt .btns-group.mt-35 { margin-top: 18px; }
  .banner-8-buttons { margin-top: 15px; padding: 0 4%; }
  .banner-15-txt .download-links { margin-top: 15px; }
  .banner-section .btns-group .btn:first-child { margin-right: 0; margin-left: 0; margin-bottom: 12px; }
  .banner-16-txt .txt-block-tra-link.mt-15 { margin-top: 10px; }

  .banner-9-img { margin: 35px 2% 0; }
  .banner-10-img { margin: 35px 5% 0; }
  .banner-11-img { margin: 30px 2% -20px; }
  .banner-12-img { transform: rotate(0deg); margin: 30px 0 -40px; }

  /* DOWNLOAD */
  #download-1 { padding-bottom: 30px; }
  .version-release { margin-bottom: 30px; }
  .release-data h5 { margin-bottom: 30px!important; }

  .release-data { padding-bottom: 20px; margin-bottom: 20px; } 
  span.version-data { font-size: 1.5rem; margin-right: 5px; }
  span.release-date span { font-size: 1.05rem; margin-left: 5px; }
  .release-download { margin-top: 15px; }

  /* FAQs */
  #faqs-1, #faqs-4 { padding-bottom: 40px; }

  /* FAQs Section Title */
  #faqs-1 .section-title { margin-bottom: 19px; }
  #faqs-2 .inner-page-title { margin-bottom: 24px; }
  #faqs-3 .section-title { margin-bottom: 35px; }
  #faqs-4 .inner-page-title { margin-bottom: 35px; }

  #faqs-3 .section-title .contact-link { display: block; }

  /* FAQs Accordion */
  .faqs-section .accordion-thumb { padding: 16px 0 20px; }
  #faqs-2.faqs-section .accordion-thumb { padding: 16px 0 21px; }

  .faqs-section .accordion-item .accordion-thumb:after, 
  .faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 20px; right: 2px; }
  #faqs-2.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-2.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 17px; right: 2px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-2 .accordion-panel { padding: 25px 0 10px 0; } 
  #faqs-2 .accordion-panel-item.mb-35, #faqs-3 .question.mb-35 { margin-bottom: 20px; }

  .faqs-section .accordion-thumb h5 { line-height: 1.35; padding-right: 8%; }
  .question h5, .accordion-panel-item h5 { line-height: 1.35; margin-bottom: 15px; }

  /* FAQs Tabs */
  .faqs-section .tabs-1 { margin: 0 5px 10px; }
  .faqs-section .tabs-1 li { min-width: 100%; padding: 20px 0; margin: 0 0 15px; }

  /* More Questions Link */
  #faqs-2 .more-questions { margin: 40px auto 0; }
  #faqs-3 .more-questions.mt-40 { margin-top: 15px; }
  .more-questions-txt { padding: 14px 20px; }
  .more-questions-txt p { font-size: 1.1rem; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay, #newsletter-5 .newsletter-overlay { padding-top: 50px; padding-bottom: 40px; }  
  #newsletter-2 .container { max-width: 100%; }
  #newsletter-2 .newsletter-overlay { padding: 50px 8% 40px; margin: 0 -15px; border-radius: 0 0!important; }
  #newsletter-3, #newsletter-4 .newsletter-overlay { padding-top: 50px; padding-bottom: 50px; }

  #newsletter-4 .newsletter-txt, #newsletter-5 .newsletter-txt { padding: 0; }

  .newsletter-txt h2, #newsletter-4 .newsletter-txt h2 { margin-bottom: 25px; }
  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.642857rem; padding: 0; margin-bottom: 25px; }

  #newsletter-3 .newsletter-txt p { padding: 0 2%; }
  #newsletter-4 .newsletter-txt p { font-size: 1rem; padding: 0 2%; margin-bottom: 0; }
  #newsletter-5 .newsletter-txt p { margin-top: 15px; }

  #newsletter-1 .newsletter-form, #newsletter-2 .newsletter-form, 
  #newsletter-3 .newsletter-form, #newsletter-4 .newsletter-form { padding: 0 3%; }
  #newsletter-5 .newsletter-form { margin-top: 25px; padding: 0 3%; }

  .newsletter-section .form-control, #newsletter-1.newsletter-section .form-control, 
  #newsletter-5.newsletter-section .form-control { height: 46px; font-size: 1.0625rem; }
  .newsletter-section .btn, #newsletter-1 .btn, #newsletter-5.newsletter-section .btn { height: 46px; font-size: 1.0625rem; }

  /* BLOG */
  #bp-1-1, #bp-1-2 { margin-bottom: 30px; }
  .blog-post-img.mb-35 { margin-bottom: 25px; }
  .post-tag { font-size: 0.825rem; margin-bottom: 14px; }

  .blog-post-txt h6, .blog-post-txt h4.mb-20, .blog-post-txt h3 { margin-bottom: 10px; }
  .blog-post-meta.mt-20, .blog-post-meta.mt-30 { margin-top: 14px; }

  /* Blog Listing */
  .wide-post { margin-bottom: 30px; }
  .wide-post h3.s-36, .wide-post h3.s-38, .wide-post h3.s-40, .square-post h4.s-34 { font-size: 1.285714rem; }
  .wide-post .blog-post-img { margin-bottom: 25px; }

  .square-post { margin-top: 10px; margin-bottom: 40px; }
  .square-post .blog-post-txt { padding: 40px 20px 35px; }

  .posts-category { margin-top: 10px; margin-bottom: 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category .more-projects.ico-20 [class*="flaticon-"]:after { font-size: 1rem; } 

  /* Single Blog Post */
  .single-post-title h3, .single-post-title h2 { padding: 0 5%; margin-bottom: 20px; }
  .single-post-title .blog-post-meta.mt-35 { margin-top: 22px; }
  #single-post .post-meta-list .meta-list-divider { display: none!important; }
  #single-post .post-meta-list li { display: block!important; margin-top: 10px; }
  .post-inner-img { margin-top: 25px; margin-bottom: 25px; }

  .single-post-txt h5, .single-post-txt h4 { margin-top: 15px; margin-bottom: 18px; }

  .single-post-txt .blockquote p { font-size: 1.0625rem; margin-bottom: 25px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; line-height: 1.3; }

  .post-share-list { margin-top: 30px; padding-bottom: 40px; }

  .post-author { display: block!important; padding: 50px 0; }
  .post-author-avatar { display: block!important; text-align: left; margin-right: 0; margin-bottom: 20px; }
  .post-author-avatar img { width: 70px; height: 70px; }
  .post-author-txt h5 { margin-bottom: 10px; }

  .author-follow-btn { padding: 5px 12px; top: 130px; right: 10px; }

  .post-comments { padding-top: 50px; }
  .post-comments h5 { margin-bottom: 40px; }
  .comment-body { margin-left: 15px; }
  .post-comments img { width: 37px; height: 37px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 0.95rem; } 
  .btn-reply.ico-20 [class*="flaticon-"]:before, .btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 0.95rem; }

  .post-comments #leave-comment h5 { margin-top: 50px; }

  .comment-form p { margin-bottom: 10px; }

  .comment-form { margin-top: 50px; }
  .comment-form .btn { margin-top: 0; }

  /* CONTACTS */
  #contacts-1 .section-title p { padding: 0 3%; }
  #contacts-1 .contact-form .contact-form-notice p { font-size: 0.875rem; margin-top: 30px; }

  #contacts-1 .contact-form .col-md-12 span { line-height: 1.4; margin-bottom: 12px; }
  .contact-form .form-control, .contact-form .form-select { height: 50px; }
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  .footer hr { margin-top: 5px; margin-bottom: 30px; }

  #footer-10.pt-50 { padding-top: 0; }

  .footer .col-sm-4, .footer .col-sm-6, .footer .col-sm-8 { width: 50%!important; float: left; }
  #footer-3.footer .col-sm-6.col-md-3 { width: 100%!important; float: none; }

  .footer-info, #footer-2 .footer-info, .footer-links, .footer-stores-badge { margin-bottom: 35px; }
  #footer-6 .footer-info, #footer-7 .footer-info { margin-top: 10px; margin-bottom: 0; }
  #footer-10 .footer-info { margin-bottom: 25px; padding-bottom: 35px; }
  #footer-8 .footer-links, #footer-10 .footer-links { margin-bottom: 10px; }

  img.footer-logo, img.footer-logo-dark { max-height: 34px; }
  #footer-1 .footer-logo, #footer-5 .footer-logo, #footer-6 .footer-logo, #footer-7 .footer-logo { margin-bottom: 22px; }
  #footer-10 img.footer-logo { max-height: 34px; }

  .footer h6 { font-size: 1.1785714rem; margin-bottom: 18px; } /* 16.5px */

  .footer-mail-link { font-size: 1.0714285rem; } /* 15px */
  .footer-mail-link span { top: 3px; right: 4px; }

  #footer-8 .foo-links li { display: block!important; padding-left: 0; }
  #footer-10 .foo-links li { padding: 0 8px; }

  .foo-links.ico-10 li span { top: 1px; right: 3px; }
  .foo-links li a span.ext-link { left: 3px; top: 0; }

  .footer-stores-badge .store { display: block!important; text-align: left; margin-bottom: 15px; margin-right: 0; }

  .footer-form { margin-right: 6%; }
  #footer-9 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.1rem; }
  .footer-form .form-control, .footer-form .btn { height: 28px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.125rem; line-height: 22px!important; }

  .bottom-footer-socials { margin-top: 10px; }
  .bottom-footer-socials li { padding-left: 12px; }
  .bottom-footer-socials li:first-child { padding-left: 0; }

  .bottom-footer-list { margin-top: 3px; } 
  #footer-4 .bottom-footer-list li, #footer-9 .bottom-footer-list li { padding-left: 8px; }
  #footer-4 .bottom-footer-list li span, #footer-9 .bottom-footer-list li span { margin-right: 1px; }

  .footer-list-divider { top: 0; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero, .page-hero-section-overlay { padding-top: 60px; }

  .inner-page-title { margin-bottom: 40px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { font-size: 1.125rem; line-height: 1.6666; padding: 0 3%; margin-top: 12px; }
  .page-hero-section h1 { line-height: 1.25; margin-bottom: 20px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* SIGN IN / SIGN UP */
  #login, #signup, #reset-password { padding-top: 70px; padding-bottom: 70px; }
  .login-page-logo { margin-bottom: 38px; }
  .login-page-logo img { max-height: 36px; }

  .register-form-title { margin-bottom: 25px; }
  .register-form-title h3 { font-size: 1.57142rem; margin-bottom: 8px; }

  .separator-line, .register-page-form p.input-header, .form-data span, .register-page-form .btn-google { font-size: 1rem; }
  #signup .form-data span { font-size: 0.95rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 0; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .separator-line { margin: 20px 0; }

  .reset-form-title { margin-bottom: 16px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 15px; }

  .reset-page-wrapper { margin: 0 10px; }
  .reset-page-wrapper form {  padding: 35px 10px 10px; }
  #login .register-page-form, #signup .register-page-form { padding: 35px 20px; }
  #login.login-1 .register-page-form, #signup.signup-1 .register-page-form { padding: 5px; }

  .reset-page-wrapper .form-control, 
  .register-page-form .form-control { height: 50px; font-size: 1.0625rem; padding: 5px 12px; margin-bottom: 20px; }
  
  #login .register-page-form .btn.submit { height: 50px; font-size: 1.0625rem; margin-top: 12px; }
  #signup .register-page-form .btn.submit { height: 50px; font-size: 1.1rem; margin-top: 20px; }
  .reset-page-wrapper .btn.submit { height: 50px; font-size: 1.0625rem; }
  .btn-google img { width: 18px; height: 18px; top: -2px; right: 5px; }

  .btn-show-pass { top: 16px; }

  /* PAGE 404 */
  .page-404-txt { padding-top: 80px; padding-bottom: 50px; }
  .page-404-txt h2 { margin-bottom: 12px; } 
  .page-404-txt h6 { padding: 0; line-height: 1.6; margin-bottom: 20px; }
  .page-404-img { padding: 0 8%; margin-bottom: 30px; }

  /* MODAL */
  #modal-1 .modal-dialog { max-width: 280px; margin: auto auto; }
  #modal-2 .modal-dialog { width: 300px; margin: auto auto; }
  #modal-3 .modal-dialog.modal-xl { width: 290px; margin: auto auto; }

  #modal-1.modal .btn-close { right: 10px!important; top: 10px!important; }

  #modal-2 .modal-body-content { padding: 22px 25px 5px; }
  #modal-3 .modal-body-content { padding: 25px 10px 15px; }

  .modal-body .newsletter-form { margin-top: 15px; }
  .modal .form-control, .modal .newsletter-form .btn { height: 46px; font-size: 1.0625rem; }

  #modal-3 .request-form-title p { margin-top: 12px; margin-bottom: 16px; }
  #modal-3 .request-form .form-control {  margin: 0 0 20px 0; }
  #modal-3 .request-form .btn { height: 46px; font-size: 1rem; }



}
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/flaticon.css");
@import url("./assets/css/menu.css");
@import url("./assets/css/dropdown-effects/fade-down.css");
@import url("./assets/css/lunar.css");
@import url("./assets/css/animate.css");
@import url("./assets/css/blue-theme.css");
@import url("./assets/css/responsive.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.6.2/css/all.min.css");


.swiper-wrapper {
    width: 100vw !important;
}


.switcher-theme {
   margin-top: 15px;
    margin-left: 30px;
}
.switcher-theme #hide-checkbox {
    opacity: 0;
    height: 0;
    width: 0;
}
.switcher-theme .toggle {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 75px;
    height: 37px;
    background: #211042;
    border-radius: 50px;
    transition: 500ms;
    overflow: hidden;
}
.switcher-theme .toggle-button {
    position: absolute;
    display: inline-block;
    top: 3px;
    left: 3px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #faeaf1;
    overflow: hidden;
    box-shadow: 0 0 35px 4px rgb(255, 255, 255);
    transition: all 500ms ease-out;
}
.switcher-theme .crater {
    position: absolute;
    display: inline-block;
    background: #faeaf1;
    border-radius: 50%;
    transition: 500ms;
}
.switcher-theme .crater-1 {
    background: #fffff9;
    width: 32px;
    height: 32px;
    left: 4px;
    bottom: 4px;
}
.switcher-theme .crater-2 {
    width: 8px;
    height: 8px;
    top: -3px;
    left: 18px;
}
.switcher-theme .crater-3 {
    width: 7px;
    height: 7px;
    top: 7px;
    right: -1px;
}
.switcher-theme .crater-4 {
    width: 4px;
    height: 4px;
    top: 10px;
    left: 11px;
}
.switcher-theme .crater-5 {
    width: 15px;
    height: 15px;
    top: 40px;
    left: 48px;
}
.switcher-theme .crater-6 {
    width: 10px;
    height: 10px;
    top: 48px;
    left: 20px;
}
.switcher-theme .crater-7 {
    width: 12px;
    height: 12px;
    bottom: 5px;
    left: 35px;
}
.switcher-theme .star {
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    box-shadow: 1px 0 2px 2px rgb(255, 255, 255);
}
.switcher-theme .star-1 {
    width: 2px;
    height: 2px;
    right: 42px;
    bottom: 18px;
}
.switcher-theme .star-2 {
    width: 3px;
    height: 3px;
    right: 32px;
    top: 4px;
}
.switcher-theme .star-3 {
    width: 2px;
    height: 2px;
    right: 27px;
    bottom: 7px;
}
.switcher-theme .star-4 {
    width: 1px;
    height: 1px;
    right: 18px;
    bottom: 22px;
}
.switcher-theme .star-5 {
    width: 1px;
    height: 1px;
    right: 4px;
    bottom: 15px;
}
.switcher-theme .star-6, .switcher-theme .star-7, .switcher-theme .star-8 {
    width: 4px;
    height: 1px;
    border-radius: 2px;
    transform: rotate(-45deg);
    box-shadow: 5px 0px 4px 1px #fff;
    animation-name: travel;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}
.switcher-theme .star-6 {
    right: 13px;
    bottom: 13px;
    animation-delay: -2s;
}
.switcher-theme .star-7 {
    right: 22px;
    bottom: 28px;
}
.switcher-theme .star-8 {
    right: 40px;
    top: 4px;
    animation-delay: -4s;
}
@keyframes travel {
    0% {
        transform: rotate(-45deg) translateX(70px);
    }
    50% {
        transform: rotate(-45deg) translateX(-20px);
        box-shadow: 5px 0px 6px 1px #fff;
    }
    100% {
        transform: rotate(-45deg) translateX(-30px);
        width: 2px;
        height: 2px;
        opacity: 0;
        box-shadow: none;
    }
}
.switcher-theme #hide-checkbox:checked + .toggle {
    background: #24d7f7;
}
.switcher-theme #hide-checkbox:checked + .toggle .toggle-button {
    background: yellow;
    transform: translateX(38px);
    box-shadow: 0 0 35px 5px rgb(255, 255, 255);
}
.switcher-theme #hide-checkbox:checked + .toggle .toggle-button .crater {
    transform: rotate(-45deg) translateX(70px);
}
.switcher-theme #hide-checkbox:checked + .toggle .star {
    animation: move 2s infinite;
    transform: none;
    box-shadow: none;
}

.switcher-theme #hide-checkbox:checked + .toggle .star-1 {
    width: 40px;
    height: 10px;
    border-radius: 10px;
    background: #fff;
    right: 20px;
    top: 25px;
    box-shadow: none;
}
.switcher-theme #hide-checkbox:checked + .toggle .star-2 {
    width: 12px;
    height: 12px;
    background: #fff;
    right: 26px;
    top: 23px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}
.switcher-theme #hide-checkbox:checked + .toggle .star-3 {
    width: 16px;
    height: 16px;
    background: #fff;
    right: 35px;
    top: 19px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}
.switcher-theme #hide-checkbox:checked + .toggle .star-4 {
    width: 14px;
    height: 14px;
    background: #fff;
    right: 46px;
    top: 21px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}
.switcher-theme #hide-checkbox:checked + .toggle .star-5 {
    width: 60px;
    height: 15px;
    border-radius: 15px;
    background: #fff;
    right: 30px;
    bottom: 20px;
    box-shadow: none;
}
.switcher-theme #hide-checkbox:checked + .toggle .star-6 {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    right: 38px;
    bottom: 20px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}
.switcher-theme #hide-checkbox:checked + .toggle .star-7 {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    right: 52px;
    bottom: 20px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}
.switcher-theme #hide-checkbox:checked + .toggle .star-8 {
    width: 21px;
    height: 21px;
    background: #fff;
    border-radius: 50%;
    right: 70px;
    top: 59px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

@keyframes move {
    0% {
        transform: none;
    }
    25% {
        transform: translateX(2px);
    }
    100% {
        transform: translateX(-2px);
    }
}
.switcher-theme p {
    text-align: center;
    letter-spacing: 15px;
    background: #34495e;
    color: #fff;
}
.switcher-theme p.morning {
    background: #e67e22;
}

.nl-simple.active{
    border-bottom: 3px solid #004AAD;
    transition: all ease-in-out .5s;
}

.lang-switcher{
    position: fixed;
    right: 0;
    width: 50px;
    height: 65px;
    background: #fff;
    top: 200px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,.4);
    z-index: 999;
}

.lang-switcher-item {
    font-size: 20px;
    color: #000;
    transition: all ease-in-out.5s;
    cursor: pointer !important;
    font-weight: bold;
    z-index: 1000;
}

.lang-switcher-item span.active{
    color: #0a53be;
}

.portfolio-detail-img{
    max-height : 470px;
    max-width :100%;
    overflow : auto;
   overflow-y: hidden;
}

.portfolio-detail-img img {
    transform: translateY(0px);
    transition: all ease-in-out .5s;
}

.portfolio-detail-img img:hover {
    transform: translateY(calc(-100% + 300px));
}